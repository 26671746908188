<script>
import { AxiosAPI } from '@/axios';
import { useMainStore, useEventStore } from '../../../stores/index';
import ProgressBarDocs from './ProgressBar.vue';
export default {
  name: 'DashboardDefault',
  components: { ProgressBarDocs },
  setup () {
    const StoreAll = useMainStore();
    const Events = useEventStore();
    StoreAll.Token();
    return {
      Store: StoreAll,
      Events
    };
  },
  data () {
    return {
      LoadingPreview: false,
      expandedRows: {},
      tableKeys: [],
      DataUsers: [],
      Signers: [],
      tableNoFormat: [],
      checked: false,
      loading: false,
      LeaderFalse: false
    };
  },
  mounted () {
    if (this.Store.SignersData.Signers !== undefined) {
      this.tableKeys = this.Store.SignersData.tableKeys;
      this.Signers = this.Store.SignersData.Signers;
      this.DataUsers = this.Store.SignersData.DataUsers;
    }

    if (this.Store.SignersData.Signers === undefined) {
      this.GetServidoresSolo();
    }
  },
  computed: {
  },
  watch: {
    DataUsers: function (newValue) {
      const Signers = [];
      this.tableKeys.forEach(element => {
        element.children.forEach(users => {
          newValue.forEach(values => {
            if (users.value === values) {
              Signers.push(users);
            }
          });
        });
      });
      this.Signers = Signers;
    }
  },
  methods: {
    async nextProgress () {
      this.LoadingPreview = true;
      const requiredSigners = [];
      const signers = [];
      this.Signers.forEach(element => {
        if (element.signer === true) {
          requiredSigners.push(element.toSendID);
        }
        if (element.signer === false) {
          signers.push(element.toSendID);
        }
      });

      this.Store.documentData.required_signers = requiredSigners;
      this.Store.documentData.signers = signers;
      this.Store.SignersData.Signers = this.Signers;
      this.Store.SignersData.Signers = this.Signers;
      this.Store.SignersData.DataUsers = this.DataUsers;
      const data = {
        title: 'Documento teste',
        subject: this.Store.documentData.subject,
        body: this.Store.documentData.body,
        document_type_id: this.Store.documentData.typeDoc,
        to_users: this.Store.documentData.to_users,
        to_departments: this.Store.documentData.to_departments,
        user_id: this.Store.documentData.user_id,
        department_id: this.Store.documentData.department_id
      };
      await AxiosAPI.post('/api/documents/preview/', data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.Store.accessToken
        }
      })
        .then((resp) => {
          this.Store.pdf64 = resp.data.file;
          this.LoadingPreview = false;
        });
      this.Store.progress += 1;
    },
    backProgress () {
      this.Store.progress -= 1;
    },
    async getUserNow () {
      await AxiosAPI.get('/api/departments/group/', {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.Store.accessToken
        }
      })
        .then((resp) => {
          const myUserData = [];
          const myUserValue = [];
          let leader = [];
          const Signers = [];
          resp.data.departments.forEach(element => {
            element.users.forEach(users => {
              if (users.id === this.Store.user.id) {
                if (users.cpf === this.Store.user.cpf) {
                  if (this.Store.user.is_leader === true) {
                    if (myUserData.some(item => item.cpf === users.cpf)) {
                      this.LeaderFalse = false;
                    } else {
                      myUserData.push(users);
                      myUserValue.push(users.value);
                    }
                  }
                  if (this.Store.user.is_leader === false) {
                    leader = this.Store.user.leader;
                    this.tableKeys.forEach(element => {
                      element.children.forEach(liderValue => {
                        if (liderValue.cpf === leader.cpf) {
                          if (myUserData.some(item => item.cpf === users.cpf)) {
                            this.LeaderFalse = true;
                          } else {
                            myUserData.push(users);
                            myUserValue.push(users.value, liderValue.value);
                          }
                        }
                      });
                    });
                  }
                  if (this.Store.user.is_leader === null) {
                    console.log('is_leader === null');
                  }
                }
              }
            });
          });
          this.DataUsers = myUserValue;

          this.tableKeys.forEach(element => {
            element.children.forEach(users => {
              myUserValue.forEach(values => {
                if (users.value === values) {
                  if (users.cpf === this.Store.user.cpf) {
                    if (this.Store.user.is_leader === true) {
                      users.signer = true;
                      Signers.push(users);
                    }
                    if (this.Store.user.is_leader === false) {
                      leader = this.Store.user.leader;
                      this.tableKeys.forEach(element => {
                        element.children.forEach(liderValue => {
                          if (liderValue.cpf === leader.cpf) {
                            liderValue.signer = true;
                            users.signer = true;
                            Signers.push(liderValue, users);
                          }
                        });
                      });
                    }
                    if (this.Store.user.is_leader === null) {
                      console.log('is_leader === null');
                    }
                  }
                }
              });
            });
          });
          this.Signers = Signers;
        });
    },

    async GetServidoresSolo () {
      this.DataUsers = [];
      this.Signers = [];
      await AxiosAPI.get('/api/departments/group/', {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.Store.accessToken
        }
      })
        .then((resp) => {
          const data = [];
          for (let i = 0; i < resp.data.departments.length; i++) {
            const obj = resp.data.departments[i];

            obj.label = obj.title;
            delete obj.title;

            obj.key = obj.value;
            delete obj.value;

            obj.children = obj.users;
            delete obj.users;
            for (let i = 0; i < obj.children.length; i++) {
              const childrens = obj.children[i];
              childrens.toSendID = childrens.id;
              delete childrens.id;
              childrens.id = childrens.value;

              childrens.label = childrens.name;
              delete childrens.name;

              childrens.signer = false;

              childrens.send = false;
            }
            data.push(obj);
          }
          this.tableKeys = data;
          this.Store.SignersData.tableKeys = this.tableKeys;
          this.getUserNow();
        });
    },
    toggleSigner (row) {
      row.signer = row.signer !== true;
    },
    toggleInbox (row) {
      row.inbox = row.inbox !== true;
    },
    removeLine (values) {
      this.DataUsers = this.DataUsers.filter(item => item !== values.value);
    }
  }
};
</script>
<style>
.drop-zone--over {
  background-color: #d4edf7;
}
.form-check-input:checked{
  border-color: #F1860B !important;
  background-color: #F1860B !important;
  padding-bottom: 18px;
}
.p-datatable-header{
    background-color: #E9ECEF !important;
  }
</style>

<template>
  <div class="container">
    <ProgressBarDocs />
    <div v-if="LoadingPreview" class="row d-flex justify-content-center align-items-center mt-5">
      <ProgressBar class="mt-5" mode="indeterminate" style="height: 6px;width:50%"></ProgressBar>
      <span class="d-flex justify-content-center align-items-center font-docs">carregando, previsualização...</span>
    </div>
    <div v-if="!LoadingPreview" class="w-100 mt-3">
      <div class="d-flex justify-content-between align-items-center w-100">
        <h4 class="font-docs text-docs-black"><b>Assinaturas</b></h4>
        </div>
    </div>
    <div v-if="!LoadingPreview">
      <hr>
      <div>
        <div class="mt-4 ml-2">
           <span> Assinante(s) <span style="color: red;">*</span></span>
        </div>
       <treeselects v-model="DataUsers" :disable-branch-nodes="true" :flat="true" placeholder="Busque por nome ou nome do departamento"  :multiple="true" :options="tableKeys" ></treeselects>
            <DataTable :value="Signers" :responsive="true" :loading='loading'>
              <Column header='Servidor' :sortable="true" class='text-sm font-weight-bold text-wrap font-docs-recived-header' headerStyle='width: 10rem; font-size: 16px !important'>
                <template #body="slotProps">
                  <div v-if="!this.Store.mobile" class="d-flex">
                    <div>
                      <img :src="this.Store.host + slotProps.data.path_image"
                        style="widows: 40px; height: 40px; border-radius: 50%;" />
                    </div>
                    <div class="d-grid ml-2">
                      <span class="font-docs font-weight-bold"> {{ slotProps.data.label }} </span>
                      <span class="font-docs font-weight-bold"> {{ slotProps.data.email  }} </span>
                    </div>
                  </div>
                  <div v-if="this.Store.mobile">
                    <div  class="d-flex justify-content-end">
                      <img :src="this.Store.host + slotProps.data.path_image"
                                  style="widows: 40px; height: 40px; border-radius: 50%;" />
                    </div>
                    <div class="d-grid ml-2 text-end">
                      <span class="font-docs font-weight-bold"> {{ slotProps.data.label }} </span>
                      <span class="font-docs font-weight-bold"> {{ slotProps.data.email  }} </span>
                    </div>
                  </div>
                </template>
              </Column>
              <Column field='role' header='Cargo' :sortable="true" class='text-sm font-weight-bold text-wrap font-docs-recived-header' headerStyle='width: 10rem; font-size: 16px !important'>
              </Column>
              <Column field='departments' header='Setor' :sortable="true" class='text-sm font-weight-bold text-wrap font-docs-recived-header' headerStyle='width: 10rem; font-size: 16px !important'>
                <template #body="slotProps">
                  <span v-if="slotProps.data.departments.length <= 0" class="font-docs font-weight-bold">Departamento não definido</span>
                  <span v-if="slotProps.data.departments.length >= 1" class="font-docs font-weight-bold">{{slotProps.data.departments[0].title}}</span>
                </template>
              </Column>
               <Column header='Assinatura obrigatória' class='text-sm font-weight-bold text-wrap font-docs-recived-header' headerStyle='width: 10rem; font-size: 16px !important'>
                <template #body="slotProps">
                  <div class="form-check form-switch">
                    <input class="form-check-input" type="checkbox" role="switch" :id="'switch-' + slotProps.data.signer" v-model="slotProps.data.signer" @click="toggleSigner(slotProps.data)" :disabled="slotProps.data.profile === 'Leitor'">
                  </div>
                </template>
              </Column>
             <Column class='text-sm font-weight-bold text-wrap font-docs' headerStyle='width: 2rem; font-size: 16px !important;'>
                <template #body="slotProps">
                  <div class="d-flex justify-content-end">
                  <font-awesome-icon :icon="['fas', 'trash-can']" style="cursor: pointer;font-size: 16px;" @click="removeLine(slotProps.data)"/>
                  </div>
                </template>
              </Column>
            </DataTable>
      </div>
      <div class="d-flex justify-content-end">
          <button class="btn mt-3 font-docs Color-Docs" style="border: 1px solid #F1860B" @click="backProgress()">Voltar</button>
          <button class="btn mt-3 ml-3 font-docs text-white background-Docs" @click="nextProgress()">Avançar</button>
      </div>
      </div>
</div>
</template>
