<script>
import VuePdfEmbed from 'vue-pdf-embed';
import { AxiosAPI } from '@/axios';
import { useMainStore } from '../../../stores/index';
export default {
  name: 'ViewPdf',
  components: {
    VuePdfEmbed
  },
  setup () {
    const StoreAll = useMainStore();
    return {
      Store: StoreAll
    };
  },
  data () {
    return {
      pdfonly: ''
    };
  },
  watch: {
    'Store.pdfNow': function (newValue) {
      this.convertToBase64();
    }
  },
  mounted () {
  },
  methods: {
    async convertToBase64 () {
      const ur = JSON.stringify({ link: this.Store.pdfNow });
      await AxiosAPI.post('/api/base64', ur, {
        headers: {
          'Content-Type': 'application/json',
          authorization: 'Bearer ' + this.Store.accessToken
        }
      }).then((response) => {
        this.pdfonly = response.data.link;
      });
    }
  }
};
</script>
<template class="teste">
<div id="app">
  <div class="app-content" style="height: 500px; overflow: overlay">
    <vue-pdf-embed
      ref="pdfRef"
      :height="500"
      :source="`data:application/pdf;base64,`+this.Store.pdf64"
    />
  </div>
</div>

</template>
<style>
.vue-pdf-embed > div {
  margin-bottom: 8px;
  box-shadow: 0 2px 8px 4px rgba(0, 0, 0, 0.1);
}
.teste{
    width: 500px !important;
    height: 500px !important;
}
.app-header {
  padding: 16px;
  box-shadow: 0 2px 8px 4px rgba(0, 0, 0, 0.1);
  background-color: #555;
  color: #ddd;
}
</style>
