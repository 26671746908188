<script>
import { useMainStore, useEventStore } from '../../../stores/index';
export default {
  name: 'DashboardDefault',
  components: {},
  setup () {
    const StoreAll = useMainStore();
    const Events = useEventStore();
    StoreAll.Token();
    return {
      Store: StoreAll,
      Events
    };
  },
  data () {
    return {
    };
  },
  mounted () {
  },
  watch: {
  },
  methods: {
    backtostep (value) {
      this.Store.progress = value;
    }
  }
};
</script>
<style>
.progress-display-mobile{
    display: none;
}
.progress-display{
    display: flex;
}
.time-line-create{
    height: 2px;
    margin-left: 1rem;
    margin-right: 1rem;
    width: 186px;
}

.border-time-line-create-on{
    width: 32px;
    height: 32px;
    border: 2px solid #F1860B;
    border-radius: 50%;
}

.border-time-line-create-off{
    width: 32px;
    height: 32px;
    border: 2px solid #A1AEBE;
    border-radius: 50%;
}

.border-time-line-create-end{
    width: 32px;
    height: 32px;
    background-color: #F1860B;
    border: 2px solid #F1860B;
    border-radius: 50%;
}

.text-time-line-create-on{
    color: #F1860B;
}
.text-time-line-create-off{
    color: #465668;
}
.margin-relative{
    margin-left: 1rem;

}
@media screen and (max-width: 1650px) {
.time-line-create{
    width: 145px;
}
}

@media screen and (max-width: 1450px) {
.time-line-create{
    width: 105px;
}
}

@media screen and (max-width: 1280px) {
    .time-line-create{
    width: 95px;
}
.margin-relative{
    margin-left: 0;

}
}

@media screen and (max-width: 1150px) {
    .time-line-create{
    width: 80px;
}
}

@media screen and (max-width: 1000px) {
    .progress-display-mobile{
        display: block;
    }
    .progress-display{
        display: none;
    }
    .no-padding{
        padding: 0 !important;
    }
}
</style>
<template>
<div>
    <div class="container no-padding"><h4 class="font-docs text-docs-black mb-4"><b>Criar documento</b></h4></div>

    <div class="progress-display-mobile">
        <div class="progress" style="border-radius: 4px; height: 16px;">
            <div class="progress-bar background-Docs" role="progressbar" style="border-radius: 4px; height: 16px;" :class="{'w-0': this.Store.progress == 1, 'w-20': this.Store.progress == 2, 'w-40': this.Store.progress == 3, 'w-60': this.Store.progress == 4, 'w-80': this.Store.progress == 5, 'w-100': this.Store.progress == 6}" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
        </div>
        <div class="d-flex justify-content-between">
            <span v-if="this.Store.progress == 1" class="text-time-line-create-off font-docs mt-2">Escolha o modelo</span>
            <span v-if="this.Store.progress == 2" class="text-time-line-create-off font-docs mt-2">Dados do documento</span>
            <span v-if="this.Store.progress == 3" class="text-time-line-create-off font-docs mt-2">Anexos</span>
            <span v-if="this.Store.progress == 4" class="text-time-line-create-off font-docs mt-2">Envio</span>
            <span v-if="this.Store.progress == 5" class="text-time-line-create-off font-docs mt-2">Assinantes</span>
            <span v-if="this.Store.progress == 6" class="text-time-line-create-off font-docs mt-2">Finalizar</span>

            <span v-if="this.Store.progress == 1" class="text-time-line-create-off font-docs mt-2">1/6</span>
            <span v-if="this.Store.progress == 2" class="text-time-line-create-off font-docs mt-2">2/6</span>
            <span v-if="this.Store.progress == 3" class="text-time-line-create-off font-docs mt-2">3/6</span>
            <span v-if="this.Store.progress == 4" class="text-time-line-create-off font-docs mt-2">4/6</span>
            <span v-if="this.Store.progress == 5" class="text-time-line-create-off font-docs mt-2">5/6</span>
            <span v-if="this.Store.progress == 6" class="text-time-line-create-off font-docs mt-2">6/6</span>
        </div>
    </div>

    <div class="progress-display">
        <div class="w-100 d-flex justify-content-center">
            <div class="d-flex d-flex justify-content-center">
                <div>
                    <div class="d-flex align-items-center">
                        <div :class="{ 'border-time-line-create-on': this.Store.progress == 1, 'border-time-line-create-end': this.Store.progress > 1}"
                            class="d-flex align-items-center justify-content-center"
                            :style="{ cursor: this.Store.progress > 1 ? 'pointer' : 'default' }"
                            @click="this.Store.progress > 1 ? backtostep(1) : null">
                            <span v-if="this.Store.progress == 1" :class="{ 'text-time-line-create-on': this.Store.progress >= 1 }">1</span>
                            <font-awesome-icon v-if="this.Store.progress > 1" class="text-white" :icon="['fas', 'check']" />
                        </div>
                        <img v-if="this.Store.progress == 1" class="time-line-create" src="../../../assets/img/TimeLineDoc.png">
                        <img v-if="this.Store.progress > 1" class="time-line-create" src="../../../assets/img/line_down-progress.png">
                    </div>
                    <span :class="{ 'text-time-line-create-on': this.Store.progress >= 1 }" class="font-docs" style="position: relative;right: 3rem;">Escolha o modelo</span>
                </div>
                <div>

                <div class="d-flex align-items-center">
                    <div :class="{ 'border-time-line-create-on': this.Store.progress == 2, 'border-time-line-create-off': this.Store.progress < 2, 'border-time-line-create-end': this.Store.progress > 2 }"
                         class="d-flex align-items-center justify-content-center"
                         :style="{ cursor: this.Store.progress > 2 ? 'pointer' : 'default' }"
                         @click="this.Store.progress > 2 ? backtostep(2) : null">
                        <span v-if="this.Store.progress <= 2" :class="{ 'text-time-line-create-on': this.Store.progress >= 2 }">2</span>
                        <font-awesome-icon v-if="this.Store.progress > 2" class="text-white" :icon="['fas', 'check']" />
                    </div>
                    <img v-if="this.Store.progress <= 2" class="time-line-create" src="../../../assets/img/TimeLineDoc.png">
                    <img v-if="this.Store.progress > 2" class="time-line-create" src="../../../assets/img/line_down-progress.png">
                </div>
                    <span :class="{ 'text-time-line-create-on': this.Store.progress >= 2, 'text-time-line-create-off': this.Store.progress < 2 }" class="font-docs" style="right: 4rem;position: relative;">Dados do documento</span>
                </div>

                <div>
                    <div class="d-flex align-items-center">
                        <div :class="{ 'border-time-line-create-on': this.Store.progress == 3, 'border-time-line-create-off': this.Store.progress < 3, 'border-time-line-create-end': this.Store.progress > 3 }"
                             class="d-flex align-items-center justify-content-center"
                             :style="{ cursor: this.Store.progress > 3 ? 'pointer' : 'default' }"
                            @click="this.Store.progress > 3 ? backtostep(3) : null">
                            <span v-if="this.Store.progress <= 3" :class="{ 'text-time-line-create-on': this.Store.progress >= 3 }">3</span>
                            <font-awesome-icon v-if="this.Store.progress > 3" class="text-white" :icon="['fas', 'check']" />
                        </div>
                        <img v-if="this.Store.progress <= 3" class="time-line-create" src="../../../assets/img/TimeLineDoc.png">
                        <img v-if="this.Store.progress > 3" class="time-line-create" src="../../../assets/img/line_down-progress.png">
                    </div>
                        <span :class="{ 'text-time-line-create-on': this.Store.progress >= 3, 'text-time-line-create-off': this.Store.progress < 3 }" class="font-docs" style="position: relative;right: 0.65rem;">Anexos</span>
                </div>

                <div>
                    <div class="d-flex align-items-center">
                        <div :class="{ 'border-time-line-create-on': this.Store.progress == 4, 'border-time-line-create-off': this.Store.progress < 4, 'border-time-line-create-end': this.Store.progress > 4 }"
                             class="d-flex align-items-center justify-content-center"
                             :style="{ cursor: this.Store.progress > 4 ? 'pointer' : 'default' }"
                             @click="this.Store.progress > 4 ? backtostep(4) : null">
                            <span v-if="this.Store.progress <= 4" :class="{ 'text-time-line-create-on': this.Store.progress >= 4 }">4</span>
                            <font-awesome-icon v-if="this.Store.progress > 4" class="text-white" :icon="['fas', 'check']" />
                        </div>
                        <img v-if="this.Store.progress <= 4" class="time-line-create" src="../../../assets/img/TimeLineDoc.png">
                        <img v-if="this.Store.progress > 4" class="time-line-create" src="../../../assets/img/line_down-progress.png">
                    </div>
                        <span :class="{ 'text-time-line-create-on': this.Store.progress >= 4, 'text-time-line-create-off': this.Store.progress < 4 }" class="font-docs" style="position: relative;right: 0.20rem;">Envio</span>
                </div>

                <div>
                    <div class="d-flex align-items-center">
                        <div :class="{ 'border-time-line-create-on': this.Store.progress == 5, 'border-time-line-create-off': this.Store.progress < 5, 'border-time-line-create-end': this.Store.progress > 5 }"
                             class="d-flex align-items-center justify-content-center"
                             :style="{ cursor: this.Store.progress > 5 ? 'pointer' : 'default' }"
                            @click="this.Store.progress > 5 ? backtostep(5) : null">
                            <span v-if="this.Store.progress <= 5" :class="{ 'text-time-line-create-on': this.Store.progress >= 5 }">5</span>
                            <font-awesome-icon v-if="this.Store.progress > 5" class="text-white" :icon="['fas', 'check']" />
                        </div>
                        <img v-if="this.Store.progress <= 5" class="time-line-create" src="../../../assets/img/TimeLineDoc.png">
                        <img v-if="this.Store.progress > 5" class="time-line-create" src="../../../assets/img/line_down-progress.png">
                    </div>
                        <span :class="{ 'text-time-line-create-on': this.Store.progress >= 5, 'text-time-line-create-off': this.Store.progress < 5 }" class="font-docs" style="position: relative;right: 0.95rem;">Assinante</span>
                </div>

                <div>
                    <div class="d-flex align-items-center">
                        <div :class="{ 'border-time-line-create-on': this.Store.progress == 6, 'border-time-line-create-off': this.Store.progress < 6 }"
                         class="d-flex align-items-center justify-content-center"
                         :style="{ cursor: this.Store.progress > 6 ? 'pointer' : 'default' }"
                         @click="this.Store.progress > 6 ? backtostep(6) : null">
                            <span :class="{ 'text-time-line-create-on': this.Store.progress === 6 }">6</span>
                        </div>
                    </div>
                    <span :class="{ 'text-time-line-create-on': this.Store.progress === 6, 'text-time-line-create-off': this.Store.progress < 6 }" class="font-docs" style="position: relative;right: 0.75rem;">Finalizar</span>
                </div>
            </div>
        </div>
    </div>
</div>
</template>
