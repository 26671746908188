<script>
import { useMainStore, useEventStore } from '../../../stores/index';
export default {
  name: 'NewChatGroup',
  setup () {
    const StoreAll = useMainStore();
    const event = useEventStore();
    return {
      Store: StoreAll,
      event
    };
  },
  data () {
    return {
      chatnowDataUser: [],
      usersChat: [],
      groupName: '',
      selectUsers: true,
      selectName: false
    };
  },
  computed: {
    groupedNames () {
      const sortedPeople = [...this.Store.users]
        .filter(person => person.name !== this.Store.user.name)
        .sort((a, b) => a.name.localeCompare(b.name));

      return sortedPeople.reduce((acc, person) => {
        const firstLetter = person.name.charAt(0).toUpperCase();
        if (!acc[firstLetter]) {
          acc[firstLetter] = [];
        }
        acc[firstLetter].push(person);
        return acc;
      }, {});
    }
  },
  mounted () {
  },
  methods: {
    selectGroupName () {
      this.selectUsers = false;
      this.selectName = true;
    },
    StartChatGroupWith () {
      let data = [];
      data = [{ tittle: this.groupName }, this.usersChat];
      this.$emit('start-chat-group', data);
    }
  }
};
</script>
<style>
.chat-select{
  background-color: #3c3d3b;
  color: white;
  padding: 5px;
  margin: 0 5px 5px 0;
  width: 500px;
}
</style>
<template>
    <div class="chat-scroll">
        <div v-if="selectUsers">
            <div class="d-flex justify-content-start align-items-center chat-select">
            <div>
                <span class="font-docs ml-2" style="font-size:18px">Selecione os participantes (mínimo 2)</span>
            </div>
            <div>
            </div>
            </div>

            <div v-for="(users, letter) in groupedNames" :key="letter" style="padding: 0rem 0.5rem;">
                <h5 class="text-black">{{ letter }}</h5>
                <div v-for="data in users" :key="data.id" class="d-flex align-items-center mb-3">
                    <img :src="Store.host + data.path_image" class="mr-2" style="widows: 40px; height: 40px; border-radius: 50%;">
                    <span class="font-docs" style="font-size:18px">{{ data.name }}</span>
                    <input type="checkbox" class="ml-2" v-model="usersChat" :value="data">
                </div>
            </div>

            <a class="px-3 py-2 fixed-plugin-button text-dark position-fixed background-Docs" style="right:85px" :style="{ cursor: usersChat.length <= 1 ? 'auto' : 'pointer', opacity: usersChat.length <= 1 ? '50%' : '100%' }"
            :disabled="usersChat.length <= 1"  @click="selectGroupName">
                    <i class="fa fa-check" style="color: white;" aria-hidden="true"></i>
                    <span class="font-docs"></span>
            </a>
        </div>

        <div v-if="selectName">
        <div class="d-flex justify-content-start align-items-center chat-select">
          <div>
            <span class="font-docs ml-2" style="font-size:18px">Digite o nome do grupo</span>
          </div>
          <div>
          </div>
        </div>

        <div class="d-flex justify-content-center">
            <input type="text" class="form-control" placeholder="Grupo de trabalho" v-model="groupName" style="width: 400px; height: 50px; border-radius: 10px; margin: 0 5px 5px 0;">
        </div>

        <span class="font-docs ml-2"> Selecionados </span>
        <div v-for="users in usersChat" :key="users" class="mt-1 mb-2" style="padding: 0rem 0.5rem;">
            <img :src="Store.host + users.path_image" class="mr-2" style="widows: 40px; height: 40px; border-radius: 50%;">
            <span class="font-docs" style="font-size:18px">{{ users.name }}</span>
        </div>

        <a class="px-3 py-2 fixed-plugin-button text-dark position-fixed background-Docs" style="right:85px" :style="{ cursor: groupName.length <= 1 ? 'auto' : 'pointer', opacity: groupName.length <= 1 ? '50%' : '100%' }"
         :disabled="usersChat.length <= 1"  @click="StartChatGroupWith">
                <i class="fa fa-check" style="color: white;" aria-hidden="true"></i>
                <span class="font-docs"></span>
        </a>
    </div>
    </div>
</template>
