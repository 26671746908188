<script>
import ArgonInput from '@/components/ArgonInput.vue';
import ArgonButton from '@/components/ArgonButton.vue';
import { useMainStore } from '../../../stores/index';
import { mapMutations } from 'vuex';
import { AxiosAPI } from '@/axios';
const body = document.getElementsByTagName('body')[0];

export default {
  name: 'LoginUser',
  components: {
    ArgonInput,
    ArgonButton
  },
  setup () {
    const StoreAll = useMainStore();
    return {
      StoreAll
    };
  },
  data () {
    return {
      user: '',
      password: '',
      rememberUser: false,
      incorrectAuth: false,
      companyIMG: ''
    };
  },
  created () {
    this.$store.state.hideConfigButton = true;
    this.toggleDefaultLayout();
    body.classList.remove('bg-gray-100');
  },
  beforeUnmount () {
    this.$store.state.hideConfigButton = false;
    this.toggleDefaultLayout();
    body.classList.add('bg-gray-100');
  },
  mounted () {
    if (localStorage.getItem('email') !== null) {
      document.getElementById('user').value = localStorage.getItem('email');
      document.getElementById('password').value = localStorage.getItem('password');
      this.rememberUser = true;
    }
    this.GetImg();
  },
  methods: {
    ...mapMutations(['toggleDefaultLayout']),

    GetImg () {
      AxiosAPI
        .get('api/cityhalls', {
        }).then((response) => {
          console.log(response.data.cityhalls[0].image);
          this.companyIMG = this.StoreAll.host + response.data.cityhalls[0].image;
        });
    },
    Login () {
      const emailLogin = document.getElementById('user').value;
      const Pass = document.getElementById('password').value;
      if (this.rememberUser) {
        localStorage.setItem('email', emailLogin);
        localStorage.setItem('password', Pass);
      }
      if (!this.rememberUser) {
        if (localStorage.getItem('email') !== null) {
          localStorage.removeItem('email');
          this.rememberUser = false;
        }
        if (localStorage.getItem('password') !== null) {
          localStorage.removeItem('password');
          this.rememberUser = false;
        }
      }
      const StoreAll = useMainStore();
      StoreAll.Login({
        email: emailLogin,
        password: Pass
      }).then(() => {
        this.$router.push({
          name: 'Home'
        });
      })
        .catch(() => {
          this.incorrectAuth = true;
          document.getElementById('loginbtn').classList.toggle('active');
        });
    }
  }

};
</script>
<template>
  <div>
    <div class="container top-0 position-sticky z-index-sticky">
    </div>
    <main class="mt-0 main-content">
      <section>
        <div class="page-header min-vh-100">
          <div class="container">
            <div class="row">
              <div
                class="col-foot-login d-lg-flex pe-0 justify-content-center flex-column footer-login"
              >
                <div
                  class="position-relative h-100 px-7 d-flex flex-column justify-content-center overflow-hidden"
                >
                  <span class="mask" style="background-color:#000000"></span>
                 <div class="dis-none">
                  <img class="mx-auto text-center display-img" src="../../../assets/img/OBJECTS.png">
                  </div>
                  <div class="Social-details">
                    <img src="../../../assets/img/login_brand.png" class="navbar-brand-img" alt="main_logo" />
                    <h5
                      class="mt-3 text-white text-left font-weight-bolder position-relative font-docs"
                    >
                    </h5>
                    <span class="text-white position-relative font-docs fonts-size">
                      Uma nova forma de gerenciar seus documentos.
                    </span>
                    <div class="d-flex btn-background mt-4">
                      <a href="#">
                        <img class="mx-auto text-center" style="object-fit: cover; z-index:1" src="../../../assets/img/Facebook_btn.png">
                      </a>
                      <a href="#">
                        <img class="mx-auto text-center" style="object-fit: cover; z-index:1" src="../../../assets/img/Instagram_btn.png">
                      </a>
                      <a href="#">
                        <img class="mx-auto text-center" style="object-fit: cover; z-index:1" src="../../../assets/img/Network_btn.png">
                      </a>
                      </div>
                  </div>
                </div>
              </div>
              <div class="mx-auto col-xl-6 col-lg-6 col-lg-6 d-flex flex-column mx-lg-0 position-absolute end-0 login-box">
              <div class="d-flex justify-content-center">
                <img class="img-low" :src="companyIMG" style="object-fit: contain;"> <!-- VARIAVEL PARA RECEBIMENTO POR LINK DE BRASÃO DE PREFEITURA -->
              </div>
              <div class="d-flex justify-content-center">
                <div class="card card-plain login-inputs-box">
                  <div>
                  <div class="pb-0 card-header text-start No-pad">
                    <h4 class="font-weight-bolder font-docs">Acessar</h4>
                  </div>
                  <div class="card-body No-pad">
                    <form role="form" @submit.prevent="Login">
                      <div class="mb-3">
                        <label class="label-docs">E-mail <span style="color: red;">*</span></label>
                        <argon-input
                          id="user"
                          v-model="user"
                          type="text"
                          placeholder="seuemail@mail.com"
                          name="user"
                          size="lg"
                        />
                      </div>
                      <div class="mb-3">
                        <label class="label-docs">Senha <span style="color: red;">*</span></label>
                        <argon-input
                          id="password"
                          v-model="password"
                          type="password"
                          placeholder="*******"
                          name="password"
                          size="lg"
                        />
                      </div>
                      <div class="d-flex justify-content-between" style="width: 100%;">
                        <div>
                          <input type="checkbox" class="mr-2" v-model="rememberUser">
                          <span style="font-size:16px">Lembrar usuário</span>
                        </div>
                        <div>
                          <router-link :to='{ name: "Esqueci minha senha"}' exact>
                            <a href="#" class="Color-Docs" style="font-size:16px;">Esqueci minha senha</a>
                          </router-link>
                        </div>
                      </div>
                      <div class="text-center">
                        <argon-button
                          class="mt-4 text-white d-flex justify-content-center align-items-center background-Docs"
                          variant="gradient"
                          color="#F1860B"
                          full-width
                          size="lg"
                          style="font-size:16px !important; font-weight: 400;height: 38px; padding:6px 12px 6px 12px; gap:8px;"
                          >Acessar</argon-button
                        >
                      </div>
                      <div v-if="incorrectAuth">
                      <label
                      ><strong class="color6"
                      >Login e/ou senha incorretos!</strong
                      ><br/><br/></label
                        >
                      </div>

                      <p class="mx-auto mb-4 text-sm text-start label-docs mt-5" style="color: #6C757D; font-size: 16px !important;">
                        <b>Não tem cadastro?</b> <br/>
                        Entre em contato com o gestor do seu departamento para solicitar.
                    </p>
                    </form>
                  </div>
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
      </section>
    </main>
    </div>
  </template>
