<script>
import { AxiosAPI } from '@/axios';
import { useMainStore } from '../../stores/index';
export default {
  name: 'RecivedDocs',
  components: {},
  setup () {
    const StoreAll = useMainStore();
    StoreAll.Token();
    return {
      store: StoreAll
    };
  },
  data () {
    return {
      Documents: [],
      display_document: 1,
      loading: false,
      optionspag: [],
      page: 1,
      componenteHeight: '93vh',
      sentPage: 1
    };
  },
  mounted () {
    this.Get_docs();
    this.checkComponentHeight();
    window.addEventListener('resize', this.checkComponentHeight);
  },
  beforeUnmount () {
    window.removeEventListener('resize', this.checkComponentHeight);
  },
  methods: {
    checkComponentHeight () {
      const componente = document.getElementById('Recived');
      const alturaViewport = window.innerHeight;

      if (componente.clientHeight < 0.93 * alturaViewport) {
        this.componenteHeight = '88vh';
      } else {
        this.componenteHeight = componente.clientHeight < 0.93 * alturaViewport + 'vh';
      }
    },
    Get_docs () {
      this.loading = true;
      this.Documents = [];

      if (this.$route.params.id === '1') {
        this.display_document = 1;
        AxiosAPI
          .get('/api/documents/received?page=' + this.page, {
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + this.$pinia.state.value.main.accessToken
            }
          }).then((response) => {
            const SignersBlock = [];
            const DocSignersRequired = [];
            const NoSignerRequired = [];
            const DocSignersRequiredOff = [];
            let result = [];
            this.optionspag = response.data.documents;
            response.data.documents.data.forEach(doc => {
              doc.signers.forEach(signer => {
                if (signer.required === true) {
                  SignersBlock.push(signer);
                  if (signer.clicksign_status === '0') {
                    if (!DocSignersRequired.some(existingDoc => existingDoc.id === doc.id)) {
                      DocSignersRequired.push(doc);
                    }
                  } else {
                    if (!DocSignersRequiredOff.some(existingDoc => existingDoc.id === doc.id)) {
                      DocSignersRequiredOff.push(doc);
                    }
                  }
                } else {
                  if (!NoSignerRequired.some(existingDoc => existingDoc.id === doc.id)) {
                    NoSignerRequired.push(doc);
                  }
                }
              });
            });

            if (DocSignersRequired.length >= 1) {
              const blockDocumentIds = new Set(DocSignersRequired.map(signer => signer.id));
              this.Documents = NoSignerRequired.filter(doc => !blockDocumentIds.has(doc.id));
              result = NoSignerRequired.filter(doc => !blockDocumentIds.has(doc.id));
            };

            if (DocSignersRequiredOff.length >= 1) {
              this.Documents = DocSignersRequiredOff;
              result.push(...DocSignersRequiredOff);
            };

            if (NoSignerRequired.length >= 1) {
              this.Documents = NoSignerRequired;
              result.push(...NoSignerRequired);
            }

            result = Array.from(new Set(result));
            this.Documents = result;
            this.loading = false;
          });
      } else if (this.$route.params.id === '2') {
        this.display_document = 2;
        AxiosAPI
          .get('/api/documents/sent?page=' + this.sentPage, {
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + this.$pinia.state.value.main.accessToken
            }
          }).then((response) => {
            this.optionspag = response.data.documents;
            this.Documents = response.data.documents.data;
            this.loading = false;
          });
      } else if (this.$route.params.id === '3') {
        this.$router.push('/signer');
      } else {
        this.display_document = 1;
        AxiosAPI
          .get('/api/documents/received?page=' + this.page, {
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + this.$pinia.state.value.main.accessToken
            }
          }).then((response) => {
            const SignersBlock = [];
            const DocSignersRequired = [];
            const NoSignerRequired = [];
            const DocSignersRequiredOff = [];
            let result = [];
            this.optionspag = response.data.documents;
            response.data.documents.data.forEach(doc => {
              doc.signers.forEach(signer => {
                if (signer.required === true) {
                  SignersBlock.push(signer);
                  if (signer.clicksign_status === '0') {
                    if (!DocSignersRequired.some(existingDoc => existingDoc.id === doc.id)) {
                      DocSignersRequired.push(doc);
                    }
                  } else {
                    if (!DocSignersRequiredOff.some(existingDoc => existingDoc.id === doc.id)) {
                      DocSignersRequiredOff.push(doc);
                    }
                  }
                } else {
                  if (!NoSignerRequired.some(existingDoc => existingDoc.id === doc.id)) {
                    NoSignerRequired.push(doc);
                  }
                }
              });
            });

            if (DocSignersRequired.length >= 1) {
              const blockDocumentIds = new Set(DocSignersRequired.map(signer => signer.id));
              this.Documents = NoSignerRequired.filter(doc => !blockDocumentIds.has(doc.id));
              result = NoSignerRequired.filter(doc => !blockDocumentIds.has(doc.id));
            };

            if (DocSignersRequiredOff.length >= 1) {
              this.Documents = DocSignersRequiredOff;
              result.push(...DocSignersRequiredOff);
            };

            if (NoSignerRequired.length >= 1) {
              this.Documents = NoSignerRequired;
              result.push(...NoSignerRequired);
            }

            result = Array.from(new Set(result));
            this.Documents = result;
            this.loading = false;
          });
      }
    },

    onPageChange (event) {
      if (this.display_document === 1) {
        this.page = event.page + 1;
        this.display_doc(1);
      }

      if (this.display_document === 2) {
        this.sentPage = event.page + 1;
        this.display_doc(2);
      }
    },

    async View (value) {
      await AxiosAPI
        .post('/api/documents/' + value.id + '/view', {}, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + this.$pinia.state.value.main.accessToken
          }
        }).then((response) => {
          console.log(response);
          this.store.Notify_received();
        });
    },

    View_user (event) {
      this.$refs.Modal_View_Sevidores.openModal(event);
    },

    Add_Servidores () {
      this.$refs.Modal_Add_Sevidores.openModal();
    },

    display_doc (value) {
      this.loading = true;
      this.Documents = [];

      if (value === 1) {
        this.display_document = 1;

        AxiosAPI
          .get('/api/documents/received?page=' + this.page, {
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + this.$pinia.state.value.main.accessToken
            }
          }).then((response) => {
            const SignersBlock = [];
            const DocSignersRequired = [];
            const NoSignerRequired = [];
            const DocSignersRequiredOff = [];
            let result = [];
            this.optionspag = response.data.documents;
            response.data.documents.data.forEach(doc => {
              doc.signers.forEach(signer => {
                if (signer.required === true) {
                  SignersBlock.push(signer);
                  if (signer.clicksign_status === '0') {
                    if (!DocSignersRequired.some(existingDoc => existingDoc.id === doc.id)) {
                      DocSignersRequired.push(doc);
                    }
                  } else {
                    if (!DocSignersRequiredOff.some(existingDoc => existingDoc.id === doc.id)) {
                      DocSignersRequiredOff.push(doc);
                    }
                  }
                } else {
                  if (!NoSignerRequired.some(existingDoc => existingDoc.id === doc.id)) {
                    NoSignerRequired.push(doc);
                  }
                }
              });
            });

            if (DocSignersRequired.length >= 1) {
              const blockDocumentIds = new Set(DocSignersRequired.map(signer => signer.id));
              this.Documents = NoSignerRequired.filter(doc => !blockDocumentIds.has(doc.id));
              result = NoSignerRequired.filter(doc => !blockDocumentIds.has(doc.id));
            };

            if (DocSignersRequiredOff.length >= 1) {
              this.Documents = DocSignersRequiredOff;
              result.push(...DocSignersRequiredOff);
            };

            if (NoSignerRequired.length >= 1) {
              this.Documents = NoSignerRequired;
              result.push(...NoSignerRequired);
            }

            result = Array.from(new Set(result));
            this.Documents = result;
            this.loading = false;
          });
      } else if (value === 2) {
        this.display_document = 2;
        AxiosAPI
          .get('/api/documents/sent?page=' + this.sentPage, {
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + this.$pinia.state.value.main.accessToken
            }
          }).then((response) => {
            this.optionspag = response.data.documents;
            this.Documents = response.data.documents.data;
            this.loading = false;
          });
      }
    }
  }
};
</script>
<style>
.bg_select {
  background-color: white;
}
@media (max-width: 800px) {
.response-filters{
  overflow: overlay;
  font-size: 13px;
}
}
</style>
<template>
  <div class='py-4 container-fluid' id="Recived" :style="{ minHeight: componenteHeight }">
    <!-- <div class='row'>
      <div class='col-lg-12'>
        <div class='row'>
          <div class='d-flex justify-content-center'>
            <div class='col-12'>
              <div class='card'>
                <div class='d-flex text-center text-bold response-filters' style='background-color: rgb(243, 242, 242);'>
                  <div class='pl-2 pr-2 d-flex' style='align-items: center; cursor: pointer;'
                    :class='{"bg_select": display_document == 1}' @click='display_doc(1)'>
                    <span>RECEBIDOS</span>
                    <i v-if='display_document == 1' class='fa fa-caret-down ml-2' aria-hidden='true'></i>
                  </div>
                  <div class='pl-2 pr-2 d-flex' style='align-items: center; cursor: pointer;'
                    :class='{"bg_select": display_document == 2 }' @click='display_doc(2)'>
                    <span>ENVIADOS</span>
                    <i v-if='display_document == 2' class='fa fa-caret-down ml-2' aria-hidden='true'></i>
                  </div>
              </div>
                <div class='table-responsive' style='box-shadow: 2px 5px 18px 5px #dbd1d1;'>
                  <DataTable :value='Documents' :responsive="true" :rows='10'
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    :loading='loading'>
                    <Column header='' headerStyle='width: 5rem; background-color: rgb(243 242 242);color:#979595'>
                      <template #body>
                        <i class='fa fa-file' style='color: #978f8f;opacity: 0.9;' aria-hidden='true'></i>
                      </template>
                    </Column>
                    <Column field='protocol' header='Protocolo' class='text-sm font-weight-bold text-wrap'
                      headerStyle='width: 10rem;background-color: rgb(243 242 242);color:#979595'></Column>
                    <Column field='subject' header='Assunto' class='text-sm font-weight-bold text-wrap'
                      headerStyle='background-color: rgb(243 242 242);color:#979595'>
                      <template #body='slotProps'>
                        {{ slotProps.data.subject }} - Despacho {{ slotProps.data.dispatch }}
                      </template>
                    </Column>
                    <Column field='status' header='Status' class='text-sm font-weight-bold text-wrap'
                      headerStyle='width: 15rem; background-color: rgb(243 242 242);color:#979595'>
                      <template #body='slotProps'>

                        <div v-if='slotProps.data.status === "1"'>
                          <Tag
                            style='border: 1px solid rgb(247 0 103);background-color: rgba(247, 0, 103, 0.1);color: rgb(247 0 103);'>
                            <div class='flex align-items-center gap-2'>
                              <i class='fa fa-angle-double-right mr-2' aria-hidden='true'></i>
                              <span class='text-base text-uppercase'>Aguardando movimentação</span>
                            </div>
                          </Tag>
                        </div>

                        <div v-if='slotProps.data.status === "0"'>
                          <Tag
                            style='border: 1px solid rgb(247, 114, 0);background-color: rgb(247, 114, 0, 0.1);color: rgb(247, 114, 0);'>
                            <div class='flex align-items-center gap-2'>
                              <i class='fa fa-circle-o-notch fa-spin fa-1x mr-2'></i>
                              <span class='text-base text-uppercase'>Em movimentação</span>
                            </div>
                          </Tag>
                        </div>
                      </template>
                    </Column>
                    <Column v-if='display_document === 2'
                      header='Assinatura' class='text-sm font-weight-bold text-wrap'
                      headerStyle='width: 10rem;background-color: rgb(243 242 242);color:#979595'>
                      <template #body='slotProps'>
                        <div v-for="signers in slotProps.data.signers" :key="signers">
                        <div v-if='signers.clicksign_status === "0"' class="mb-2">
                          <Tag
                            style='border: 1px solid rgb(0 65 247);background-color: rgba(0, 65, 247, 0.1);color: rgb(0 65 247);'>
                            <div class='flex align-items-center gap-2'>
                              <i class='fa fa-circle-o-notch fa-spin fa-1x mr-2'></i>
                              <span class='text-base text-uppercase'>{{ signers.user.name }}</span>
                            </div>
                          </Tag>
                        </div>
                        <div v-else class="mb-2">
                          <Tag
                            style='border: 1px solid rgb(9 167 6);background-color: rgba(9,167, 6, 0.1);color: rgb(9 167 6);'>
                            <div class='flex align-items-center gap-2'>
                              <i class='fa fa-check mr-2' aria-hidden='true'></i>
                              <span class='text-base text-uppercase'>{{ signers.user.name }}</span>
                            </div>
                          </Tag>
                        </div>
                      </div>
                      </template>
                    </Column>
                    <Column v-if='display_document === 1' header='Visualizado' class='text-sm text-wrap font-weight-bold'
                      headerStyle='width: 10rem; background-color: rgb(243 242 242);color:#979595'>
                      <template #body='slotProps'>
                        <i v-if="slotProps.data.viewed === false" class="fa fa-times-circle-o text-danger" aria-hidden="true"  style="font-size: 20px;"></i>
                        <i v-else class="fa fa-check-circle-o text-success" aria-hidden="true" style="font-size: 20px;"></i>
                        </template>
                    </Column>
                    <Column header='Ações' class='text-sm text-wrap font-weight-bold'
                      headerStyle='width: 10rem; background-color: rgb(243 242 242);color:#979595'>
                      <template #body='slotProps'>
                        <router-link v-if='display_document === 1'
                          :to='{ name: "Documento_recived", params: { id: slotProps.data.protocol }}'>
                          <a v-if="slotProps.data.viewed === false" class='font-weight-bold'
                            style='cursor: pointer;font-size: 0.9rem;color: #0776d6;'
                            @click="View(slotProps.data);">Visualizar</a>
                            <a v-else class='font-weight-bold'
                            style='cursor: pointer;font-size: 0.9rem;color: #0776d6;'>Visualizar</a>
                        </router-link>
                        <router-link v-else :to='{ name: "Documento", params: { id: slotProps.data.protocol }}'>
                          <a class='font-weight-bold'
                            style='cursor: pointer;font-size: 0.9rem;color: #0776d6;'>Visualizar</a>
                        </router-link>
                      </template>
                    </Column>
                  </DataTable>
                  <Paginator :rows="optionspag.per_page" :totalRecords="optionspag.total" :first="optionspag.from" :pageLinkSize="3" @page="onPageChange"></Paginator>
                  <div v-if='display_document === 2' class="ml-1 mb-2">
                    <h6>Legenda</h6>
                    <div>
                      <Tag
                            style='border: 1px solid rgb(0 65 247);background-color: rgba(0, 65, 247, 0.1);color: rgb(0 65 247);'>
                            <div class='flex align-items-center gap-2'>
                              <i class='fa fa-circle-o-notch fa-spin fa-1x mr-2'></i>
                              <span class='text-base text-uppercase'>Pendente</span>
                            </div>
                          </Tag>

                          <Tag
                            class="ml-2" style='border: 1px solid rgb(9 167 6);background-color: rgba(9,167, 6, 0.1);color: rgb(9 167 6);'>
                            <div class='flex align-items-center gap-2'>
                              <i class='fa fa-check mr-2' aria-hidden='true'></i>
                              <span class='text-base text-uppercase'>Assinado</span>
                            </div>
                          </Tag>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
  </div>
</template>
