<script>
import { useMainStore } from '../../../stores/index';
import { AxiosAPI } from '@/axios';
import ModalTask from './ModalTask.vue';
export default {
  name: 'KabanDocs',
  components: { ModalTask },
  setup () {
    const StoreAll = useMainStore();
    return {
      Store: StoreAll
    };
  },
  data () {
    return {
      days: {
        Segunda: [],
        Terça: [],
        Quarta: [],
        Quinta: [],
        Sexta: []
      },
      weekend: {
        Sábado: [],
        Domingo: []
      },
      allDays: {
        Segunda: [],
        Terça: [],
        Quarta: [],
        Quinta: [],
        Sexta: [],
        Sábado: [],
        Domingo: []
      },
      metas: {},
      newTask: '',
      draggedTask: null,
      draggedFrom: null,
      sourceDay: null,
      selectDayModel: [],
      TaskName: '',
      taskInputsVisibility: {},
      loading: false,
      NewOrdem: []
    };
  },
  watch: {

  },
  mounted () {
    this.getTask();
  },
  methods: {
    getTask () {
      AxiosAPI.get('/api/tasks/', {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.Store.accessToken
        }
      }).then((resp) => {
        const adicionarOrdem = (tarefas) => {
          return tarefas.map((tarefa, index) => ({
            ...tarefa,
            ordem: index
          }));
        };

        this.days = {
          Segunda: adicionarOrdem(resp.data.tasks.monday.sort((a, b) => a.ordem - b.ordem)),
          Terça: adicionarOrdem(resp.data.tasks.tuesday.sort((a, b) => a.ordem - b.ordem)),
          Quarta: adicionarOrdem(resp.data.tasks.wednesday.sort((a, b) => a.ordem - b.ordem)),
          Quinta: adicionarOrdem(resp.data.tasks.thursday.sort((a, b) => a.ordem - b.ordem)),
          Sexta: adicionarOrdem(resp.data.tasks.friday.sort((a, b) => a.ordem - b.ordem))
        };

        this.weekend = {
          Sábado: adicionarOrdem(resp.data.tasks.saturday.sort((a, b) => a.ordem - b.ordem)),
          Domingo: adicionarOrdem(resp.data.tasks.sunday.sort((a, b) => a.ordem - b.ordem))
        };

        this.metas = {
          metas: adicionarOrdem(resp.data.tasks.goals.sort((a, b) => a.ordem - b.ordem))
        };

        this.allDays = {
          ...this.days,
          ...this.weekend,
          ...this.metas
        };
      });
    },

    selectDay (value) {
      this.$refs.ModalTask.openCreateTask(value);
    },

    async addTask (value) {
      this.loading = true;
      let dataValor = 0;
      if (value === 'Segunda') { dataValor = '1'; };
      if (value === 'Terça') { dataValor = '2'; };
      if (value === 'Quarta') { dataValor = '3'; };
      if (value === 'Quinta') { dataValor = '4'; };
      if (value === 'Sexta') { dataValor = '5'; };
      if (value === 'Sábado') { dataValor = '6'; };
      if (value === 'Domingo') { dataValor = '7'; };
      if (value === 'metas') { dataValor = '8'; };
      await AxiosAPI
        .post('/api/tasks/',
          {
            weekday: dataValor,
            title: this.TaskName,
            users: [this.Store.user.id]
          }, {
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + this.Store.accessToken
            }
          }).then(() => {
          this.TaskName = '';
          this.taskInputsVisibility = {};
          this.loading = false;
          this.getTask();
        });
    },

    toggleTaskInput (day) {
      this.TaskName = '';
      this.taskInputsVisibility = {
        ...this.taskInputsVisibility,
        [day]: !this.taskInputsVisibility[day]
      };
    },

    onDragStart (task, day) {
      this.draggedTask = task;
      this.sourceDay = day;
    },

    onDragOverTask (targetTask) {
      if (this.draggedTask.id === targetTask.id) return;
      const dayTasks = this.allDays[this.sourceDay];

      const draggedIndex = dayTasks.findIndex((t) => t.id === this.draggedTask.id);
      const targetIndex = dayTasks.findIndex((t) => t.id === targetTask.id);

      dayTasks.splice(draggedIndex, 1);
      dayTasks.splice(targetIndex, 0, this.draggedTask);

      this.NewOrdem = dayTasks;

      this.syncDays();
    },

    onDragOver (event) {
      event.preventDefault();
    },

    onDrop (targetDay, taskDrop) {
      const dayMap = {
        Segunda: '1',
        Terça: '2',
        Quarta: '3',
        Quinta: '4',
        Sexta: '5',
        Sábado: '6',
        Domingo: '7',
        metas: '8'
      };
      const dataValor = dayMap[targetDay] || '0';

      if (dataValor !== '0' && this.draggedTask.weekday !== dataValor) {
        if (!this.draggedTask || !this.sourceDay) return;

        this.allDays[this.sourceDay] = this.allDays[this.sourceDay].filter(
          (t) => t.id !== this.draggedTask.id
        );

        this.allDays[targetDay].push(this.draggedTask);

        this.syncAroundDays();

        const oldUsers = this.draggedTask.users.map(user => user.id);

        AxiosAPI.put(`/api/tasks/${this.draggedTask.id}`, {
          weekday: dataValor,
          title: this.draggedTask.title,
          author_id: this.draggedTask.authors[0].id,
          order: 1,
          users: oldUsers,
          labels: this.draggedTask.labels
        }, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.Store.accessToken}`
          }
        }).then(() => {
          this.getTask();
        }).catch((error) => {
          console.error('Erro ao atualizar tarefa:', error);
          this.allDays[this.sourceDay].push(this.draggedTask);
        });

        this.draggedTask = null;
        this.sourceDay = null;
      }

      if (taskDrop) {
        const adicionarOrdem = (tarefas) => {
          return tarefas.map((tarefa, index) => ({
            ...tarefa,
            ordem: index
          }));
        };
        const dayTasks = adicionarOrdem(this.NewOrdem);

        const ordem = [];
        dayTasks.forEach(ordemDays => {
          ordem.push(ordemDays.id);
        });
        AxiosAPI.patch('/api/tasks/change-order', {
          weekday: dayTasks[0].weekday,
          tasks: ordem
        }, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.Store.accessToken}`
          }
        }).then((resp) => {
          console.log('Ordem da tarefa atualizada com sucesso', resp);
          this.getTask();
        }).catch((error) => {
          console.error('Erro ao atualizar tarefa:', error);
          this.allDays[this.sourceDay].push(this.draggedTask);
        });
      }
    },

    updateTaskOrder (day) {
      const ordem = [];
      day.forEach(ordemDays => {
        ordem.push(ordemDays.id);
      });

      AxiosAPI.patch('api/tasks/change-order ', {
        weekday: day[0].weekday,
        tasks: ordem
      }, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.Store.accessToken}`
        }
      })
        .then(() => {
          this.getTask();
          console.log('Ordem da tarefa atualizada com sucesso');
        })
        .catch((error) => {
          console.error('Erro ao atualizar ordem da tarefa:', error);
        });
    },
    syncAroundDays () {
      this.days = {
        Segunda: [...this.allDays.Segunda],
        Terça: [...this.allDays.Terça],
        Quarta: [...this.allDays.Quarta],
        Quinta: [...this.allDays.Quinta],
        Sexta: [...this.allDays.Sexta]
      };
      this.weekend = {
        Sábado: [...this.allDays.Sábado],
        Domingo: [...this.allDays.Domingo]
      };
      this.metas = {
        metas: [...this.allDays.metas]
      };
    },

    syncDays () {
      this.days = {
        Segunda: [...this.allDays.Segunda],
        Terça: [...this.allDays.Terça],
        Quarta: [...this.allDays.Quarta],
        Quinta: [...this.allDays.Quinta],
        Sexta: [...this.allDays.Sexta]
      };
      this.weekend = {
        Sábado: [...this.allDays.Sábado],
        Domingo: [...this.allDays.Domingo]
      };
      this.metas = {
        metas: [...this.allDays.metas]
      };
    },

    handleTaskCreated () {
      this.getTask();
    }
  }
};
</script>
<style scoped>
.kaban{
  display: grid;
  grid-template-columns: repeat(1, 1fr) 1fr;
  width: 100%;
  overflow: auto;
}
.kaban-collum{
  display: flex;
  flex-direction: column;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 16px;
  width: 100%;
  min-width: 200px;
  min-height: 275px;
  background-color: #f9f9f9;
}
.kaban-task-list{
  min-height: 150px;
  border-top: 1px solid black;
  flex-grow: 1;
  overflow-y: auto;
  margin-bottom: 16px;
}
.kaban-footer{
  position: relative;
  top: 150px;
}

.days .weekend .metas{
  border: 1px solid black;
  border-radius: 8px;
  padding: 10px;
  text-align: center;
}

.days{
  display: grid;
  grid-template-columns: repeat(5, 1fr) 1fr;
  gap: 20px;
}

.task {
  background-color: #0e62d7;
  width: 160px;
  height: 75px;
  border-radius: 5px;
  margin: 3px;
  display: grid;
  justify-content: center;
  align-items: center;
}

.add-task {
  margin-top: auto;
  padding: 8px 0;
  cursor: pointer;
  background-color: #e0e0e0;
  border-radius: 8px;
  transition: background-color 0.3s;
}

.add-task:hover{
  background-color: #797979;
}

.add-task-weekend:hover{
  background-color: #797979;
}

.add-task-meta:hover{
  background-color: #797979;
}
.add-task-weekend{
  top: 0px;
  cursor: pointer;
}

.add-task-meta {
  top: 0px;
  cursor: pointer;
}
.metas .kaban-collum {
  width: 100%;
}
.metas{
  grid-column: 1 / -1;
}
.move-enter-active, .move-leave-active {
  transition: transform 0.3s;
}
.move-enter, .move-leave-to {
  transform: translateY(30px);
  opacity: 0;
}

@media screen and (max-width: 1366px) {
  .kaban{
    width: 80%;
  }
}

@media screen and (max-width: 1290px) {
  .kaban{
    width: 70%;
  }
}

@media screen and (max-width: 1150px) {
  .kaban{
    width: 50%;
  }
}

@media screen and (max-width: 850px) {
  .kaban{
    width: 30%;
  }
}

@media screen and (max-width: 500px) {
  .kaban{
    width: 100%;
  }
}
</style>
<template>
<div>
    <ModalTask ref="ModalTask"  @taskDescription="handleTaskCreated"/>
    <div class="kaban">
        <div class="days">
            <div v-for="(tasks, day) in days" :key="day"
            class="kaban-collum"
            @dragover.prevent="onDragOver"
            @drop="onDrop(day, false)"
            >
            <h3 class="font-docs text-center"><b>{{ day }}</b></h3>
            <div class="kaban-task-list">
              <transition-group name="move" tag="div">
                <div
                  v-for="(task, index) in tasks"
                  :key="index"
                  class="task background-Docs font-docs"
                  style="cursor: pointer;"
                  @click="selectDay(task)"
                  @dragstart="onDragStart(task, day)"
                  @dragover.prevent="onDragOverTask(task)"
                  @drop="onDrop(task, true)"
                  @dragleave="onDragLeaveTask"
                  draggable="true"
                >
                  {{ task.title }}
                  <div class="d-flex justify-content-end">
                  <img v-for="user in task.users.slice(0, 2)"
                      :key="user.id"
                      :src="this.Store.host + user.path_image"
                      v-tooltip.top="user.name"
                      style="height: 25px; border-radius: 50%; position: relative;">

                  <div v-if="task.users.length > 2"
                      class="d-flex align-items-center justify-content-center"
                      style="width:25px; height: 25px; border:1px solid #3D8BFD; border-radius: 50%; background-color: #E7F1FF; position: relative;">
                    <span class="font-docs" style="color: #3D8BFD;"><b>+ {{task.users.length - 2 }}</b></span>
                  </div>
                    </div>
                </div>
              </transition-group>
                <div v-if="taskInputsVisibility[day]">
                <div class="task-input-content">
                    <input
                    type="text"
                    v-model="TaskName"
                    class="form-control"
                    placeholder="Nome da tarefa"
                    >
                </div>
                <div class="d-flex justify-content-between align-items-center mt-1">
                    <div>
                        <button v-if="!loading" class="btn background-Docs" style="font-size: 0.75rem; margin-bottom: 0px" @click="addTask(day)" :disabled="loading">Adicionar tarefa</button>
                        <button v-if="loading" class="btn background-Docs" style="font-size: 0.35rem; width: 100%; min-width: 130px;" :disabled="loading">
                            <i class="fa fa-circle-o-notch fa-spin fa-3x fa-fw"></i>
                        </button>
                    </div>
                    <div class="task-input">
                    <button
                      id="close"
                      type="button text-black"
                      class="close"
                      @click="taskInputsVisibility = {}"
                      >
                          <span aria-hidden="true">&times;</span>
                      </button>
                </div>
                </div>
                </div>
            </div>
            <div class="add-task d-flex justify-content-center align-items-center">
                <i class="fa fa-plus mr-2" aria-hidden="true"></i>
                <span class="font-docs" @click="toggleTaskInput(day)">Adicionar tarefa</span>
            </div>
            </div>
        </div>

        <div class="weekend">
            <div v-for="(tasks, day) in weekend" :key="day"
            class="kaban-collum"
            @dragover.prevent="onDragOver"
            @drop="onDrop(day, false)"
            >
                <h3 class="font-docs text-center"><b>{{ day }}</b></h3>
                <div class="kaban-task-list">
                <div
                    v-for="(task, index) in tasks"
                    :key="index"
                    class="task background-Docs font-docs"
                    @dragstart="onDragStart(task, day)"
                    @dragover.prevent="onDragOverTask(task)"
                    @drop="onDrop(task, true)"
                    @dragleave="onDragLeaveTask"
                    draggable="true"
                    style="width: 95%; cursor: pointer;"
                >
                {{ task.title }}
                <div class="d-flex justify-content-end">
                  <img v-for="user in task.users.slice(0, 2)"
                      :key="user.id"
                      :src="this.Store.host + user.path_image"
                      v-tooltip.top="user.name"
                      style="height: 25px; border-radius: 50%; position: relative;">

                  <div v-if="task.users.length > 2"
                      class="d-flex align-items-center justify-content-center"
                      style="width:25px; height: 25px; border:1px solid #3D8BFD; border-radius: 50%; background-color: #E7F1FF; position: relative;">
                    <span class="font-docs" style="color: #3D8BFD;"><b>+ {{task.users.length - 2 }}</b></span>
                  </div>
                    </div>
                </div>
                <div v-if="taskInputsVisibility[day]">
                <div class="task-input-content">
                    <input
                    type="text"
                    v-model="TaskName"
                    class="form-control"
                    placeholder="Nome da tarefa"
                    >
                </div>
                <div class="d-flex justify-content-between align-items-center mt-1">
                    <div>
                        <button v-if="!loading" class="btn background-Docs" style="font-size: 0.75rem; margin-bottom: 0px" @click="addTask(day)" :disabled="loading">Adicionar tarefa</button>
                        <button v-if="loading" class="btn background-Docs" style="font-size: 0.35rem; width: 100%; min-width: 130px;" :disabled="loading">
                            <i class="fa fa-circle-o-notch fa-spin fa-3x fa-fw"></i>
                        </button>
                    </div>
                    <div class="task-input">
                    <button
                      id="close"
                      type="button text-black"
                      class="close"
                      @click="taskInputsVisibility = {}"
                      >
                          <span aria-hidden="true">&times;</span>
                      </button>
                </div>
                </div>
                </div>
                </div>
                <div class="add-task d-flex justify-content-center align-items-center">
                <i class="fa fa-plus mr-2" aria-hidden="true"></i>
                <span class="font-docs" @click="toggleTaskInput(day)">Adicionar tarefa</span>
                </div>
            </div>
        </div>

        <div class="metas">
            <div v-for="(tasks, day) in metas" :key="day"
            class="kaban-collum"
            @dragover.prevent="onDragOver"
            @drop="onDrop(day, false)"
            >
                <h3 class="font-docs text-center"><b>Metas do mês</b></h3>
                <div class="kaban-task-list">
                  <div
                    v-for="(task, index) in tasks"
                    :key="index"
                    class="task background-Docs font-docs"
                    @dragstart="onDragStart(task, day)"
                    @dragover.prevent="onDragOverTask(task)"
                    @drop="onDrop(task, true)"
                    @dragleave="onDragLeaveTask"
                    draggable="true"
                    style="width: 95%; cursor: pointer;"
                >
                {{ task.title }}
                <div class="d-flex justify-content-end">
                  <img v-for="user in task.users.slice(0, 2)"
                      :key="user.id"
                      :src="this.Store.host + user.path_image"
                      v-tooltip.top="user.name"
                      style="height: 25px; border-radius: 50%; position: relative;">

                  <div v-if="task.users.length > 2"
                      class="d-flex align-items-center justify-content-center"
                      style="width:25px; height: 25px; border:1px solid #3D8BFD; border-radius: 50%; background-color: #E7F1FF; position: relative;">
                    <span class="font-docs" style="color: #3D8BFD;"><b>+ {{task.users.length - 2 }}</b></span>
                  </div>
                    </div>
                </div>
                <div v-if="taskInputsVisibility[day]">
                <div class="task-input-content">
                    <input
                    type="text"
                    v-model="TaskName"
                    class="form-control"
                    placeholder="Nome da tarefa"
                    >
                </div>
                <div class="d-flex justify-content-between align-items-center mt-1">
                    <div>
                        <button v-if="!loading" class="btn background-Docs" style="font-size: 0.75rem; margin-bottom: 0px" @click="addTask(day)" :disabled="loading">Adicionar tarefa</button>
                        <button v-if="loading" class="btn background-Docs" style="font-size: 0.35rem; width: 100%; min-width: 130px;" :disabled="loading">
                            <i class="fa fa-circle-o-notch fa-spin fa-3x fa-fw"></i>
                        </button>
                    </div>
                    <div class="task-input">
                    <button
                      id="close"
                      type="button text-black"
                      class="close"
                      @click="taskInputsVisibility = {}"
                      >
                          <span aria-hidden="true">&times;</span>
                      </button>
                </div>
                </div>
                </div>
                </div>
                <div class="add-task d-flex justify-content-center align-items-center">
                <i class="fa fa-plus mr-2" aria-hidden="true"></i>
                <span class="font-docs" @click="toggleTaskInput(day)">Adicionar meta</span>
                </div>
            </div>
        </div>
    </div>
</div>
</template>
