<template>
    <div>
      <ModalCreateDepartament ref="ModalCreateDepartament"></ModalCreateDepartament>
      <ModalViewDepartament ref="ModalViewDepartament"></ModalViewDepartament>
      <EditDepartament ref="EditDepartament"></EditDepartament>
    </div>
    <div class="py-4 container-fluid">
      <div class="row">
        <div class="col-lg-12">
          <div class="row">

            <div class="d-flex justify-content-center">

              <div class="col-12">
                <div class="card">
                  <div class="table-responsive" style="box-shadow: 2px 5px 18px 5px #dbd1d1;">

                    <TreeTable :value="Departamentos" :filters="filters" :paginator="true" :rows="10" filterMode="lenient">
                      <template v-if="this.Store.role === 1 || this.Store.role === 2 || this.Store.role === 3" #header>
                        <div class="d-flex justify-content-end">
                          <button style="background-color: #1733d3;" type="button" class="mb-2 btn text-white"
                            @click="Create_departament()">
                            <i class="fa fa-plus mr-2" aria-hidden="true"></i> Criar departamento
                          </button>
                        </div>

                        <div class="d-flex justify-content-between">
                          <span class="p-input-icon-left" style="width: 100% !important;">
                            <i class="pi pi-search" />
                            <InputText v-model="filters['global']" placeholder="Pesquise pelo departamento"
                              style="width: 100% !important;" />
                          </span>
                        </div>
                      </template>
                      <Column field="title" header="Departamentos" :expander="true">
                        <template #body="slotProps">
                          <span class="mr-2">{{ slotProps.node.title }}</span>
                            <a href="#" style="font-size: 12px;margin-right: .5em; color: #1733d3;" @click="Create_departament(slotProps.node)">+ Sub Departamento</a>
                        </template>
                      </Column>
                      <Column field="users.length" header="Usuários" headerStyle="width: 8em; text-align: center" bodyStyle="text-align: center"></Column>
                      <Column headerStyle="width: 20em" bodyStyle="text-align: center">
                        <template #body="slotProps">
                            <Button type="button" class="btn text-white" style="margin-right: .5em; background-color: #fb7240;" @click="EditDepartament(slotProps.node)">
                              <font-awesome-icon class="mr-1" icon="fa-solid fa-pen-to-square" />
                              Editar
                            </Button>
                            <Button type="button" class="btn text-white" style="background-color: rgb(23 152 211);" @click="View_departament(slotProps.node)">
                              <font-awesome-icon class="mr-1" icon="fa-solid fa-eye" />
                              Visualizar
                            </Button>
                        </template>
                    </Column>
                  </TreeTable>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </template>
<script>
import { AxiosAPI } from '@/axios';
import { useMainStore } from '../../stores/index';
import ModalCreateDepartament from '../../components/documents/Modals/Modal_Create_Departament.vue';
import ModalViewDepartament from '../../components/documents/Modals/Modal_View_Departament.vue';
import EditDepartament from '../../components/documents/Modals/EditDepartaments.vue';
export default {
  name: 'DashboardDefault',
  props: {
    data: Array
  },
  components: {
    ModalCreateDepartament,
    ModalViewDepartament,
    EditDepartament
  },
  setup () {
    const Store = useMainStore();
    Store.Token();
    return {
      Store
    };
  },
  data () {
    return {
      filters: {},
      Departamentos: []
    };
  },
  mounted () {
    this.Get_departaments();

    this.emitter.on('create_departament', () => {
      this.Get_departaments();
    });
  },

  methods: {
    Get_departaments () {
      AxiosAPI.get('/api/departments/',
        {
          headers: {
            Authorization: 'Bearer ' + this.Store.accessToken
          }
        })
        .then((resp) => {
          this.Departamentos = resp.data.departments;
          console.log(this.Departamentos);
          this.adicionarChaves(this.Departamentos, 0);
        });
    },

    adicionarChaves (data, prefixoChave = '') {
      data.forEach((item, indice) => {
        const chave = prefixoChave + indice;
        item.key = chave;
        item.data = item;

        if (item.children) {
          this.adicionarChaves(item.children, chave + '-');
        }
      });
    },

    teste (value) {
      console.log(value);
    },

    Create_departament (event) {
      this.$refs.ModalCreateDepartament.openModal(event);
    },

    EditDepartament (event) {
      this.$refs.EditDepartament.openModal(event);
    },

    View_departament (event) {
      this.$refs.ModalViewDepartament.openModal(event);
    }
  }
};
</script>
