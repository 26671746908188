<script>
import { AxiosAPI } from '@/axios';
import ModalBigger from './ModalBigger.vue';
import { useMainStore } from '../../../stores/index';
export default {
  name: 'DashboardDefault',
  props: {
    data: Array
  },
  components: { ModalBigger },
  setup () {
    const Store = useMainStore();
    Store.Token();
    return {
      Store
    };
  },
  data () {
    return {
      filters: {},
      Departamentos: [],
      selectedKey: [],
      MyAdds: []
    };
  },
  mounted () {
  },

  methods: {
    openModal () {
      this.Departamentos = [];
      this.Get_departaments();
      this.MyAdds = this.Store.DepartamentosAdd;
      this.$refs.viewDocument.openModal();
    },

    onNodeSelect (value) {
      this.MyAdds.push(value);
      this.Store.DepartamentosAdd = this.MyAdds;
    },

    onNodeUnselect (value) {
      this.MyAdds.forEach((element, index) => {
        if (value.id === element.id) {
          this.MyAdds.splice(index, 1);
        }
      });

      this.Store.DepartamentosAdd.forEach((element, index) => {
        if (value.id === element.id) {
          this.Store.DepartamentosAdd.splice(index, 1);
        }
      });
    },

    Get_departaments () {
      AxiosAPI.get('/api/departments/',
        {
          headers: {
            Authorization: 'Bearer ' + this.Store.accessToken
          }
        })
        .then((resp) => {
          this.Departamentos = resp.data.departments;

          this.Store.DepartamentosAdd.forEach(element => {
            resp.data.departments.forEach((responseArray, index) => {
              if (responseArray.id === element.id) {
                const treeTable = this.$refs.treeTable;
                treeTable.selectionKeys[index] = true;
              }
            });
          });

          this.adicionarChaves(this.Departamentos, 0);
        });
    },

    adicionarChaves (data, prefixoChave = '') {
      data.forEach((item, indice) => {
        const chave = prefixoChave + indice;
        item.key = chave;
        item.data = item;

        if (item.children) {
          this.adicionarChaves(item.children, chave + '-');
        }
      });
    }
  }
};
</script>
<template>
    <ModalBigger ref="viewDocument" style="z-index: 1000;">
        <template #header>
            <div class="d-flex justify-content-between text-dark text-bold" style="width: 100%;">
                    <div class="pt-2">
                        <div>
                            <span>Adicionar a departamentos</span>
                        </div>
                    </div>
                    <div>
                        <button type="button" class="close" @click="$refs.viewDocument.closeModal()">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                </div>
        </template>
        <template #body>
            <div>
                <div class="d-flex justify-content-center">
                    <span> Apenas clique ou toque em cima do departamento para selecionar</span>
                </div>
                <TreeTable ref="treeTable" v-model:selectionKeys="selectedKey" :value="Departamentos" :filters="filters" :paginator="true" :rows="10"
                :metaKeySelection=false selectionMode="multiple" filterMode="lenient" @nodeSelect="onNodeSelect" @nodeUnselect="onNodeUnselect">
                    <Column field="title" header="Departamentos" :expander="true">
                        <template #body="slotProps">
                            <span class="mr-2">{{ slotProps.node.title }}</span>
                        </template>
                    </Column>
                </TreeTable>
                <div class="d-flex justify-content-center">
                    <span> Selecionados </span>
                    <div v-for='departamendosADD in MyAdds' :key='departamendosADD.id'  class="d-flex justify-content-center">
                        <Tag class="d-flex ml-2" style='border: 1px solid rgb(6 70 167);background-color: rgb(6 48 167 / 10%);color: rgb(6 54 167);'>
                            <div class='flex align-items-center gap-2'>
                                <font-awesome-icon class="mr-1" icon="fa fa-building" />
                                <span class='text-base text-uppercase'>{{ departamendosADD.title}}</span>
                            </div>
                        </Tag>
                    </div>
                </div>
            </div>
        </template>
        <template #footer></template>
    </ModalBigger>
</template>
