let pathURL = '';
const url = window.location.href;
const startIndex = url.indexOf(url) + 8;
const result = url.substring(startIndex);
let sysyemNow = '';

pathURL = result;
const parts = pathURL.split('/');
sysyemNow = parts[1];
if (sysyemNow === '') {
  sysyemNow = 'xxxx';
}
export default sysyemNow;
