<script>
import { useMainStore, useEventStore } from '../../../stores/index';
import TypeDocument from './TypeDocument.vue';
import DataDocument from './DataDocument.vue';
import AttachmentDocument from './AttachmentDocument.vue';
import UserSendDocument from './UserSendDocument.vue';
import PreviewSend from './PreviewSend.vue';
import SignersDocument from './SignersDocument.vue';
export default {
  name: 'DashboardDefault',
  components: { TypeDocument, DataDocument, AttachmentDocument, UserSendDocument, PreviewSend, SignersDocument },
  setup () {
    const StoreAll = useMainStore();
    const Events = useEventStore();
    StoreAll.Token();
    return {
      Store: StoreAll,
      Events
    };
  },
  data () {
    return {
      componenteHeight: '93vh'
    };
  },
  mounted () {
    this.checkComponentHeight();
    window.addEventListener('resize', this.checkComponentHeight);
  },
  beforeUnmount () {
    window.removeEventListener('resize', this.checkComponentHeight);
  },
  watch: {
  },
  methods: {
    checkComponentHeight () {
      const componente = document.getElementById('sendTo');
      const alturaViewport = window.innerHeight;

      if (componente.clientHeight < 0.93 * alturaViewport) {
        this.componenteHeight = '88vh';
      } else {
        this.componenteHeight = componente.clientHeight < 0.93 * alturaViewport + 'vh'; // Adaptar ao tamanho adicional
      }
    }
  }
};
</script>
<template>
  <div class='py-4 container-fluid'>
    <div class='row'>
      <div class='col-lg-12'>
        <div class='row'>
          <div class='d-flex justify-content-center'>
            <div class='col-12' id="sendTo" :style="{ minHeight: componenteHeight }">
                <TypeDocument v-if="this.Store.progress === 1" />
                <DataDocument v-if="this.Store.progress === 2" />
                <AttachmentDocument v-if="this.Store.progress === 3" />
                <UserSendDocument v-if="this.Store.progress === 4"  />
                <SignersDocument v-if="this.Store.progress === 5"  />
                <PreviewSend v-if="this.Store.progress === 6"  />
            </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>
