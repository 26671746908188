/* import Cookie from 'js-cookie'; */
import { AxiosAPI } from '@/axios';
/* import routerpath from '../router/routerpath'; */
/* import router from '../router'; */
export default {

  auth (to, next) {
    const token = to.params.token;
    validateToken(token);
    async function validateToken (token) {
      try {
        const resp = await AxiosAPI.get('/api/users/link/is-valid?' + token);
        console.log(resp, 'Token');
        if (resp.status === 200) {
          next(true);
        }
      } catch (error) {
        console.log(error, 'Token inválido');
        next(false);
      };
    };
  }
};
