<script>
import { AxiosAPI } from '@/axios';
import { useMainStore, useEventStore } from '../../stores/index';
import ModalCreateDepartament from '../../components/documents/Modals/Modal_Create_Departament.vue';
import ModalViewDepartament from '../../components/documents/Modals/Modal_View_Departament.vue';
import EditDepartament from '../../components/documents/Modals/EditDepartaments.vue';
import Breadcrumb from '../../components/documents/patchName.vue';
export default {
  name: 'DashboardDefault',
  props: {
    data: Array
  },
  components: {
    ModalCreateDepartament,
    ModalViewDepartament,
    EditDepartament,
    Breadcrumb
  },
  setup () {
    const StoreAll = useMainStore();
    const event = useEventStore();
    StoreAll.Token();
    return {
      Store: StoreAll,
      event
    };
  },
  data () {
    return {
      loading: true,
      Departamentos: [],
      componenteHeight: '93vh',
      search: ''
    };
  },
  mounted () {
    this.Get_departaments();

    this.emitter.on('create_departament', () => {
      this.Get_departaments();
    });

    this.emitter.on('create_departaments', () => {
      this.search = '';
      this.Get_departaments();
    });
    this.checkComponentHeight();
    window.addEventListener('resize', this.checkComponentHeight);
  },
  beforeUnmount () {
    window.removeEventListener('resize', this.checkComponentHeight);
  },

  methods: {
    checkComponentHeight () {
      const componente = document.getElementById('Setores');
      const alturaViewport = window.innerHeight;

      if (componente.clientHeight < 0.93 * alturaViewport) {
        this.componenteHeight = '92.5vh';
      } else {
        this.componenteHeight = componente.clientHeight < 0.93 * alturaViewport + 'vh';
      }
    },
    Get_departaments () {
      this.loading = true;
      const params = {
        search: this.search
      };
      AxiosAPI.get('/api/departments',
        {
          params,
          headers: {
            Authorization: 'Bearer ' + this.Store.accessToken
          }
        })
        .then((resp) => {
          this.Departamentos = resp.data.departments;
          this.adicionarChaves(this.Departamentos, 0);
          this.loading = false;
        });
      this.Store.Get_users();
    },

    adicionarChaves (data, prefixoChave = '') {
      data.forEach((item, indice) => {
        const chave = prefixoChave + indice;
        item.key = chave;
        item.data = item;

        if (item.children) {
          this.adicionarChaves(item.children, chave + '-');
        }
      });
    },

    Create_departament (event) {
      this.$refs.ModalCreateDepartament.openModal(event);
    },

    EditDepartament (event) {
      this.$refs.EditDepartament.openModal(event);
    },

    View_departament (event) {
      this.$refs.ModalViewDepartament.openModal(event);
    }
  }
};
</script>
<style>
</style>
<template>
  <div class="py-4 container-fluid" id="Setores" :style="{ minHeight: componenteHeight }">
    <ModalCreateDepartament ref="ModalCreateDepartament"></ModalCreateDepartament>
    <ModalViewDepartament ref="ModalViewDepartament"></ModalViewDepartament>
    <EditDepartament ref="EditDepartament"></EditDepartament>
    <Breadcrumb />
    <div class="row">
        <h4 class="font-docs text-docs-black ml-1"><b>Setores</b></h4>
        <div class="d-flex justify-content-end">
                        <div v-if="Store.user.abilities === undefined"></div>
                        <button v-else-if="Store.user.abilities.some(skills => skills.id === 2)" class="btn mt-3 background-Docs" style="border: 1px solid #F1860B"
                          @click="Create_departament()">
                          <i class="fa fa-plus mr-2 text-white" aria-hidden="true"></i>
                          <span class="label-docs text-white">Cadastrar setor</span>
                        </button>
                        <div v-else></div>
                      </div>
    </div>

    <div class="row mt-4 mb-1">
        <span class="font-docs text-docs-black ml-1" style="font-size:18px"><b>Buscar</b></span>
        <form class="form-inline">
            <div class="input-group" style="width:100%;">
              <input type="text" class="form-control" v-model="search" placeholder="Nome por setor, gestor..." aria-label="Username" aria-describedby="basic-addon1">
              <div class="input-group-prepend">
                <span class="input-group-text background-Docs" id="basic-addon1"
                style="border-right-width: 1px;border-right-style: groove;border-left-width: 2px;border-top-right-radius: 5px;border-bottom-right-radius: 5px;cursor:pointer" @click="Get_departaments">
                <i class="fa fa-search text-white" aria-hidden="true"></i></span>
              </div>
            </div>
          </form>
    </div>
    <div v-if="loading" class="row d-flex justify-content-center align-items-center mt-5">
      <ProgressBar class="mt-5" mode="indeterminate" style="height: 6px;width:50%"></ProgressBar>
      <span class="d-flex justify-content-center align-items-center font-docs">carregando...</span>
    </div>
    <div v-if="!loading" class="container">
        <div class="row">
            <div v-for="setores in Departamentos" :key="setores" class="boxCard col-6 col-sm-3 mt-5 background-pags" :style="'border-right: 6px solid ' + setores.assets">
                <router-link :to='{ name: "Setor", params: { setor: setores.id, setorName: setores.title } }' style="text-decoration: none; color:black">
                    <div v-if="!Store.mobile"><span class="font-docs-recived-header">{{ setores.title.substring(0, 25) }} {{setores.title.length > 25 ? '...' : ''}}</span></div>
                    <div v-if="Store.mobile"><span class="font-docs-recived-header">{{ setores.title.substring(0, 25) }} {{setores.title.length > 25 ? '...' : ''}}</span></div>
                    <div><span class="font-docs-recived-header">{{ setores.acronym}}</span></div>
                    <div v-for="Lider in setores.users" :key="Lider">
                        <span v-if="Lider.is_leader" class="label-docs" style="color: #6C757D">Responsável: {{ Lider.name.substring(0, 15) }} {{Lider.name.length > 15 ? '...' : ''}}</span>
                    </div>
                    <div class="d-flex mt-3">
                        <img v-for="servidor in setores.users.slice(0, 1)" :key="servidor" :src="this.Store.host + servidor.path_image" style="height: 40px; border-radius: 50%;">
                        <img v-for="servidor in setores.users.slice(1, 2)" :key="servidor" :src="this.Store.host + servidor.path_image" style="height: 40px; border-radius: 50%; position: relative; right: 10px;">
                        <img v-for="servidor in setores.users.slice(2, 3)" :key="servidor" :src="this.Store.host + servidor.path_image" style="height: 40px; border-radius: 50%; position: relative; right: 20px;">
                        <div v-if="setores.users.length > 3" class="d-flex align-items-center justify-content-center" style="width:40px;height: 40px;border:1px solid #3D8BFD; border-radius: 50%;background-color: #E7F1FF; position: relative; right: 30px;">
                            <span class="font-docs" style="color: #3D8BFD;"><b>+ {{ setores.users.length - 3 }}</b></span>
                        </div>

                    </div>
                </router-link>
                <div v-if="Store.user.abilities === undefined"></div>
                <font-awesome-icon v-else-if="Store.user.abilities.some(skills => skills.id === 2)" :icon="['fas', 'pen']" style="position: absolute; right:10px; top: 0px; cursor:pointer" @click="EditDepartament(setores)"/>
                <div v-else></div>
        </div>
      </div>
    </div>
  </div>

</template>
