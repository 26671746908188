<script>
import SidenavList from './SidenavList.vue';
import { useMainStore } from '../../stores/index';
import image from '@/assets/img/profile-layout-header.jpg';
import { mapState } from 'vuex';

export default {
  name: 'IndexNav',
  components: {
    SidenavList
  },
  setup () {
    const StoreAll = useMainStore();
    StoreAll.Token();
    return {
      Store: StoreAll
    };
  },
  data () {
    return {
      image,
      dynamicHeight: '100vh !important',
      dynamicWidth: ''
    };
  },

  computed: {
    ...mapState(['layout', 'isRTL', 'sidebarType', 'darkMode'])
  },

  methods: {

  }
};
</script>
<template>
  <div>
  <aside v-if="this.Store.isPinned && this.Store.mobile"
    id="sidenav-main"
    style="position: fixed !important;top: 65px;z-index: 999 !important;"
    :class="{ 'side-nav-person-pined': this.Store.isPinned === false}"
    class="sidenav navbar navbar-vertical border-0 overflow-hidden navbar-expand-xs side-nav-person-pined-mobile sidenav-color"
  >
    <sidenav-list />
  </aside>

  <aside v-if="!this.Store.mobile"
    id="sidenav-main"
    style="position: relative !important;"
    :class="{ 'side-nav-person-pined': this.Store.isPinned === false}"
    class="h-100 sidenav navbar navbar-vertical navbar-expand-xs border-0 overflow-hidden side-nav-person sidenav-color"
  >
    <sidenav-list />
  </aside>
  </div>
</template>
