<script>
import { AxiosAPI } from '@/axios';
import { useMainStore, useEventStore } from '../../../stores/index';
import ProgressBarDocs from './ProgressBar.vue';
import Editor from '@tinymce/tinymce-vue';
import Swal from 'sweetalert2';

export default {
  name: 'DashboardDefault',
  components: { Editor, ProgressBarDocs },
  setup () {
    const StoreAll = useMainStore();
    const Events = useEventStore();
    StoreAll.Token();
    return {
      Store: StoreAll,
      Events
    };
  },
  data () {
    return {
      subject: '',
      editorContent: '',
      editorConfig: {
        language: 'pt_BR',
        plugins: 'link image table quickbars',
        toolbar: 'undo redo | formatselect | bold italic | alignleft aligncenter alignright | bullist numlist outdent indent  | advtablerownumbering | quickimage | fontsizeinput'
      },
      typeDoc: [{ id: 0, title: 'Selecione' }],
      typeSelect: 0
    };
  },
  mounted () {
    const typeNone = this.Store.Types;
    if (!typeNone.some(obj => obj.id === 0)) {
      typeNone.push({ id: 0, title: 'Selecione' });
    }
    typeNone.sort((a, b) => a.id - b.id);
    this.typeDoc = typeNone;
    this.typeSelect = this.Store.documentData.typeDoc;

    if (this.Store.documentData.body === '') {
      this.subject = this.Store.documentData.subject;
      this.editorContent = this.Store.user.footer;
    } else {
      this.subject = this.Store.documentData.subject;
      this.editorContent = this.Store.documentData.body;
    }
  },
  watch: {
  },
  methods: {
    nextProgress () {
      if (this.typeSelect === 0) {
        Swal.fire({
          title: 'Nenhum tipo selecionado!',
          text: 'Selecione um tipo de documento para prosseguir!',
          icon: 'info',
          confirmButtonText: 'Ok',
          customClass: {
            title: 'font-docs',
            htmlContainer: 'font-docs',
            confirmButton: 'font-docs'
          }
        });
      }
      if (this.subject === '') {
        Swal.fire({
          title: 'Nenhum titulo informado!',
          text: 'Informe um titulo para o documento prosseguir!',
          icon: 'info',
          confirmButtonText: 'Ok',
          customClass: {
            title: 'font-docs',
            htmlContainer: 'font-docs',
            confirmButton: 'font-docs'
          }
        });
      }
      if (this.editorContent === '') {
        Swal.fire({
          title: 'Nada informado no corpo do documento!',
          text: 'Digite algo no corpo do documento para prosseguir!',
          icon: 'info',
          confirmButtonText: 'Ok',
          customClass: {
            title: 'font-docs',
            htmlContainer: 'font-docs',
            confirmButton: 'font-docs'
          }
        });
      }
      if (this.typeSelect !== 0 && this.subject !== '' && this.editorContent !== '') {
        this.Store.documentData.typeDoc = this.typeSelect;
        this.Store.documentData.subject = this.subject;
        this.Store.documentData.body = this.editorContent;
        const body = [{ body: this.editorContent }];
        const contents = JSON.stringify(body);
        const data = {
          document_type_id: this.typeSelect,
          content: contents,
          is_new_document: true,
          subject: this.subject
        };
        if (this.Store.is_new_document) {
          AxiosAPI
            .post('api/drafts', data, {
              headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + this.Store.accessToken
              }
            }).then(() => {
              this.Store.is_new_document = true;
            });
          this.Store.progress += 1;
        }

        if (!this.Store.is_new_document && this.Store.draftID !== 0) {
          AxiosAPI
            .put('api/drafts/' + this.Store.draftID, data, {
              headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + this.Store.accessToken
              }
            }).then(() => {
              this.Store.is_new_document = false;
            });
          this.Store.progress += 1;
        }

        if (!this.Store.is_new_document && this.Store.draftID === 0) {
          this.Store.progress += 1;
        }
      }
    },

    backProgress () {
      this.Store.progress -= 1;
    }
  }
};
</script>
<style>
.box-model{
  width: 247px !important;
  height: 138px;
  border-radius: 8px;
  padding: 16px;
  gap: 8px;
  cursor: pointer;
}
.box-img{
  width: 56px;
  height: 56px;
  border-radius: 8px;
  padding: 16px;
  background-color: #E9ECEF;
}
.box-img-new{
  width: 56px;
  height: 56px;
  border-radius: 8px;
  padding: 16px;
  background-color: #ffffff;
}
@media screen and (max-width: 1450px) {
.box-model{
  width: 220px !important;
}
}

@media screen and (max-width: 1380px) {

.box-model{
  width: 200px !important;
}
}

@media screen and (max-width: 1200px) {
.box-model{
  width: 220px !important;
}
}

@media screen and (max-width: 1180px) {
  .box-model{
  width: 200px !important;
}
}

@media screen and (max-width: 1050px) {
  .box-model{
  width: 180px !important;
}
}
@media screen and (max-width: 992px) {
  .box-model{
  width: 150px !important;
}
}
@media screen and (max-width: 850px) {
  .box-model{
  width: 150px !important;
}
}
.tox {
  border-color: #CED4DA !important;
}
.tox-tinymce{
  height: 300px !important;
}
</style>
<template>
  <div class="container">
    <ProgressBarDocs />
    <div class="container mt-3">
      <div class="row">
        <h4 class="font-docs text-docs-black ml-1"><b>Dados do documento</b></h4>
      </div>

    <div class='row' style="margin-bottom: 5rem">
        <div class='col-sm-6 mt-1'>
            <label class="label-docs text-docs-black">Tipo</label>
                <select id='choices-category' v-model='typeSelect' class='my_form font-docs' name='choices-category' style="color:#6C757D;font-size: 16px;" :disabled="this.typeSelect !== 0">
                    <option v-for='option in typeDoc' :key='option.title' :value='option.id'>
                        {{ option.title }}
                    </option>
                </select>
            </div>

            <div class='col-sm-6 mt-1'>
                <label class="label-docs text-docs-black">Titulo <span style="color: red;">*</span></label>
                <input class="my_form text-bold" @input="subject = subject.toUpperCase()" v-model="subject" placeholder="Ex: Ofício judicial" type="text">
            </div>
      </div>
      <hr>
      <div v-for="docTitle in typeDoc" :key="docTitle" class="row">
        <h4 v-if="typeSelect === docTitle.id && typeSelect === 0" class="font-docs text-docs-black ml-1 mt-3"><b>Aguardando tipo *</b></h4>
        <h4 v-if="typeSelect === docTitle.id && typeSelect !== 0" class="font-docs text-docs-black ml-1 mt-3"><b>{{ docTitle.title }}</b></h4>
      </div>
      <div class='row mt-2'>
        <span>Textarea <img src="../../../assets/img/info.png" alt=""></span>
        <div>
          <Editor style='height: 100px; border: 2px solid;' v-model='editorContent'
                    api-key='lhbldb066rsly0ci0sul37fjp28wklje4c2nh83d9eyc6w9z' :init='editorConfig' />
      </div>
        </div>
        <div class="d-flex justify-content-end">
          <button class="btn mt-3 font-docs Color-Docs" style="border: 1px solid #F1860B" @click="backProgress()">Voltar</button>
          <button class="btn mt-3 ml-3 font-docs text-white background-Docs" @click="nextProgress()">Avançar</button>
        </div>
      </div>
  </div>

</template>
