<script>
export default {
  name: 'notFound',
  components: {}
};
</script>
<template>
    <div class="vh-100 w-100 d-flex align-items-center justify-content-center" style="background-color:#363534">
        <div>
        <div class="d-flex justify-content-center align-items-center">
            <img src="../../../assets/img/notfound.png">
        </div>
        <div class="d-flex justify-content-center align-items-center">
            <h5 class="font-docs text-white">Desculpe! Empresa/Prefeitura não encontrada.</h5>
        </div>
        <div class="d-flex justify-content-center align-items-center">
            <span class="font-docs text-white">Verifique se foi digitado corretamente 'app.smart2doc.com.br/SUAEMPRESA/'.</span>
        </div>
    </div>
</div>
</template>
