<!-- eslint-disable vue/multi-word-component-names -->
<template>
    <div>
        <ModalAlertModel ref="viewDocument" style="z-index: 1002 !important;">
            <template #header>
                <div class="flex-end">
                    <button type="button" class="close" @click="$refs.viewDocument.closeModal()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            </template>

            <template #body>

                <div>
              <div class="d-flex justify-content-center">
                <spinnerprime/>
              </div>
              <div class="text-center">
                <span class="text-dark"><strong>Aguarde...</strong></span>
              </div>
            </div>

            </template>

            <template #footer>
            </template>
        </ModalAlertModel>

    </div>
</template>

<script>
import ModalAlertModel from './ModalAlertModel.vue';
/* import { AxiosAPI } from "@/axios"; */

export default {
  components: {
    ModalAlertModel
  },

  data () {
    return {

    };
  },

  methods: {
    openModal () {
      this.$refs.viewDocument.openModal();
    },

    closeModal () {
      this.$refs.viewDocument.closeModal();
    },

    showSuccess_carimbo () {
      this.$toast.add({ severity: 'success', summary: 'Carimbo removido', detail: 'Carimbo removido com sucesso', life: 3000 });
    }
  }
};

</script>

<style></style>
