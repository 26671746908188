import axios from 'axios';
import router from './router';
import routerpath from './router/routerpath';

const getAPI = axios.create({
  baseURL: 'https://backend.smart2doc.com.br/' + routerpath,
  timeout: 600000
});
const AxiosAPI = axios.create({
  baseURL: 'https://backend.smart2doc.com.br/' + routerpath,
  timeout: 600000
});

/* interceptors */
getAPI.interceptors.response.use(
  response => {
    return response;
  },
  function (error) {
    if (error.response.status === 401) {
      router.push({ name: 'Login' });
    }

    // return Error object with Promise
    return Promise.reject(error);
  }
);

export { getAPI, AxiosAPI };
