<script>
import { AxiosAPI } from '@/axios';
import { useMainStore, useEventStore } from '../../stores/index';
import ModalAddCity from '../documents/Modals/Modal_Add_city.vue';
export default {
  name: 'DashboardDefault',
  components: { ModalAddCity },
  setup () {
    const StoreAll = useMainStore();
    const event = useEventStore();
    StoreAll.Token();
    return {
      Store: StoreAll,
      event
    };
  },
  data () {
    return {
      data_start: '',
      session_name: '',
      session_id: 0,
      user_name: '',
      vereadores: [],
      results: [],
      quorum: [],
      quorums: [],
      quorum_obj: 0,
      grapic: false,
      hall: [{ title: 'Aguarde' }]
    };
  },
  watch: {
    'event.CreatHall': function (newValue, oldPath) {
      if (newValue === true) {
        this.event.CreatHall = null;
        this.Get_prefecture();
        this.$toast.add({ severity: 'success', summary: 'Prefeitura adicionada', detail: 'A prefeitura foi criada com sucesso', group: 'tl', life: 8000 });
      }
    },

    'event.EditHall': function (newValue, oldPath) {
      if (newValue === true) {
        this.event.EditHall = null;
        this.Get_prefecture();
        this.$toast.add({ severity: 'success', summary: 'Prefeitura editada', detail: 'A prefeitura foi editada com sucesso', group: 'tl', life: 8000 });
      }
    }
  },
  mounted () {
    this.Get_prefecture();
  },
  methods: {
    Get_prefecture () {
      AxiosAPI.get('/api/cityhalls/',
        {
          headers: {
            Authorization: 'Bearer ' + this.Store.accessToken
          }
        })
        .then((resp) => {
          this.hall = resp.data.cityhalls;
        });
    },

    editHall (value) {
      this.$refs.ModalAddCity.openModalEdit(value);
    }
  }
};
</script>
<style>
.close {
  font-size: 2.3rem !important;
}

.photo_ranking {
  width: 163px;
  height: 163px;
}
</style>

<template>
  <div><ModalAddCity ref="ModalAddCity"> </ModalAddCity></div>
  <div v-if='this.Store.role === 1' class='d-flex'
    style='position: absolute;width: 100%;height: 10vh;background-color: #4a66c5;'>
    <span class='text-center text-white mt-3 ml-3 text-lg text-bold'>Lista de prefeituras</span>
  </div>

  <div class='py-4 container-fluid'>
    <div class='row'>
      <div class='col-lg-12'>
        <div class='row'>

          <div v-if='this.Store.role === 1' class='mt-5'>
            <div class='card'>
              <div class='table-responsive' style='box-shadow: 2px 5px 18px 0px #dbd1d1; border-radius: 10px;'>
                <DataTable :value='hall' :responsive="true" :paginator='true' :rows='10'>
                  <Column field='title' header='PREFEITURA' class='text-sm font-weight-bold text-wrap text-uppercase'>
                  </Column>
                  <Column field='mayor_name' header='PREFEITO' class='text-sm font-weight-bold text-wrap text-uppercase'>
                  </Column>
                  <Column field='vice_mayor_name' header='VICE PREFEITO'
                    class='text-sm font-weight-bold text-wrap text-uppercase'></Column>
                  <Column header='STATUS' class='text-sm font-weight-bold text-wrap text-uppercase'>
                    <template #body>
                      <div>
                        <Tag
                          style='border: 1px solid rgb(9 167 6);background-color: rgba(9,167, 6, 0.1);color: rgb(9 167 6);'>
                          <div class='flex align-items-center gap-2'>
                            <span class='text-base text-uppercase'>Ativo</span>
                          </div>
                        </Tag>
                      </div>
                    </template>
                  </Column>
                  <Column header=''
                    class='text-sm font-weight-bold text-wrap text-uppercase TT justify-content-center text-center'>
                    <template #body='slotProps'>
                      <a style='cursor: pointer;font-size: 0.9rem;color: #52a6f1;'
                        @click='editHall(slotProps.data)'>Editar</a>
                    </template>
                  </Column>
                </DataTable>
              </div>
            </div>
          </div>
          <div v-else class='d-flex justify-content-center'>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
