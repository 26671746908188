<script>
import { AxiosAPI } from '@/axios';
import { useMainStore, useEventStore } from '../../stores/index';
import { FilterMatchMode, FilterOperator } from 'primevue/api';
import ModalAddSevidores from '../../components/documents/Modals/Modal_Add_Sevidores.vue';
import Breadcrumb from '../../components/documents/patchName.vue';
import ModalCreateDepartament from '../../components/documents/Modals/ModalSubDepartement.vue';
import EditDepartament from '../../components/documents/Modals/EditDepartaments.vue';
export default {
  name: 'DashboardDefault',
  components: {
    ModalAddSevidores,
    ModalCreateDepartament,
    EditDepartament,
    Breadcrumb
  },
  setup () {
    const StoreAll = useMainStore();
    const event = useEventStore();
    return {
      Store: StoreAll,
      event
    };
  },
  data () {
    return {
      filters: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        departamento: { value: null, matchMode: FilterMatchMode.STARTS_WITH }

      },
      servidores: [],
      componenteHeight: '93vh',
      dataSetor: [],
      ownerDepartament: [],
      SubDepartamentos: [],
      loading: false,
      setor: 0
    };
  },
  mounted () {
    this.Get_Servidores();

    this.emitter.on('Add_Servidores', () => {
      this.Get_Servidores();
      this.SubscribeInfo();
    });

    this.emitter.on('AddServidor', () => {
      this.Get_Servidores();
      this.$toast.add({ severity: 'success', summary: 'Usuário adicionado', detail: 'Ação realizada com sucesso', group: 'tl', life: 8000 });
    });

    this.emitter.on('create_subdep', (value) => {
      this.GetDepartamentAfter(value);
    });

    this.checkComponentHeight();
    window.addEventListener('resize', this.checkComponentHeight);
  },
  beforeUnmount () {
    window.removeEventListener('resize', this.checkComponentHeight);
  },
  methods: {
    checkComponentHeight () {
      const componente = document.getElementById('SetoresExpand');
      const alturaViewport = window.innerHeight;

      if (componente.clientHeight < 0.93 * alturaViewport) {
        this.componenteHeight = '92.5vh';
      } else {
        this.componenteHeight = componente.clientHeight < 0.93 * alturaViewport + 'vh';
      }
    },
    Get_Servidores () {
      this.loading = true;
      AxiosAPI.get('/api/departments/' + this.$route.params.setor,
        {
          headers: {
            Authorization: 'Bearer ' + this.Store.accessToken
          }
        })
        .then((resp) => {
          this.dataSetor = resp.data.department;
          this.servidores = resp.data.department.users;
          this.SubDepartamentos = resp.data.department.children;
          this.ownerDepartament = resp.data.department;
          this.loading = false;
        });
    },

    GetDepartamentAfter (value) {
      this.loading = true;
      AxiosAPI.get('/api/departments/' + value,
        {
          headers: {
            Authorization: 'Bearer ' + this.Store.accessToken
          }
        })
        .then((resp) => {
          this.dataSetor = resp.data.department;
          this.servidores = resp.data.department.users;
          this.SubDepartamentos = resp.data.department.children;
          this.ownerDepartament = resp.data.department;
          this.loading = false;
        });
    },

    Get_ServidoresPag (value) {
      this.loading = true;
      AxiosAPI.get('/api/departments/' + value,
        {
          headers: {
            Authorization: 'Bearer ' + this.Store.accessToken
          }
        })
        .then((resp) => {
          this.dataSetor = resp.data.department;
          this.servidores = resp.data.department.users;
          this.SubDepartamentos = resp.data.department.children;
          this.ownerDepartament = resp.data.department;
          this.loading = false;
        });
    },

    clearFilter1 () {
      this.initFilters();
    },

    initFilters () {
      this.filters = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
        departamento: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] }
      };
    },

    View_user (event) {
      this.$refs.ModalViewSevidores.openModal(event);
    },

    Add_Servidores () {
      this.Store.ListUsersDepartament = this.servidores;
      this.$refs.ModalAddSevidores.openModalSetor(this.ownerDepartament);
    },

    SubscribeInfo () {
      this.$toast.add({ severity: 'success', summary: 'Usuário cadastrado', detail: 'Cadastramento realizado com sucesso', group: 'tl', life: 8000 });
    },

    Create_departament () {
      this.$refs.ModalCreateDepartament.openModal(this.ownerDepartament);
    },

    EditDepartament (event) {
      this.$refs.EditDepartament.openModal(event);
    },

    removerUser (event) {
      const DepartmanetNow = [];
      const idsCurrent = [];
      this.Store.users.forEach(usersALL => {
        if (usersALL.id === event.id) {
          DepartmanetNow.push(...usersALL.departments);
        }
      });

      const RemoveUser = DepartmanetNow.filter(value => value.id !== this.ownerDepartament.id);
      RemoveUser.forEach(ids => {
        idsCurrent.push(ids.id);
      });

      const formData = new FormData();
      formData.append('_method', 'PUT');
      formData.append('department_id', idsCurrent);
      AxiosAPI.post('api/users/' + event.id, formData, {
        headers: {
          Authorization: 'Bearer ' + this.$pinia.state.value.main.accessToken
        }
      })
        .then((resp) => {
          console.log(resp);
          if (resp.status === 200) {
            this.$toast.add({ severity: 'success', summary: 'Usuário removido do setor', detail: 'Usuário removido do setor com sucesso', group: 'tl', life: 8000 });
            this.Get_Servidores();
          } else {
            this.$toast.add({ severity: 'error', summary: 'Erro ao remover', detail: 'Usuário não foi removido, informar ao suporte', group: 'tl', life: 8000 });
          }
        });
    }

  }
};
</script>
<template>
    <div class="py-4 container-fluid" id="SetoresExpand" :style="{ minHeight: componenteHeight }">
      <Breadcrumb />
    <ModalAddSevidores ref="ModalAddSevidores"></ModalAddSevidores>
    <ModalCreateDepartament ref="ModalCreateDepartament"></ModalCreateDepartament>
    <EditDepartament ref="EditDepartament"></EditDepartament>
      <div class="d-flex mb-4 justify-content-between align-items-center">
        <div>
          <h4 class="font-docs text-docs-black ml-1"><b>{{dataSetor.title}}</b></h4>
          <span class="font-docs text-docs-black ml-1">{{dataSetor.description}}</span>
        </div>
        <div v-if="Store.user.abilities === undefined"></div>
        <div v-else-if="Store.user.abilities.some(skills => skills.id === 2)">
          <button  class="btn mt-3 background-Docs text-white" style="border: 1px solid #F1860B" type="button" @click="Create_departament()">
                            <i class="fa fa-plus mr-2 text-white" aria-hidden="true"></i>  Cadastrar sub-setor
                    </button>
        </div>
        <div v-else></div>
      </div>

      <div v-if="SubDepartamentos.length >= 1" class="row mb-4">
        <span class="font-docs text-docs-black ml-1"><b>Sub-Setores</b></span>
      </div>
      <div v-if="SubDepartamentos.length >= 1" class="container">
        <div class="row">
            <div v-for="setores in SubDepartamentos" :key="setores" class="boxCard-sub col-6 col-sm-3 mt-5 background-pags" :style="'border-right: 6px solid ' + setores.assets">
                <router-link :to='{ name: "Setor", params: { setor: setores.id, setorName: setores.title } }' style="text-decoration: none; color:black" @click="this.Get_ServidoresPag(setores.id)">
                  <div v-if="!Store.mobile"><span class="font-docs-recived-header">{{ setores.title.substring(0, 25) }} {{setores.title.length > 25 ? '...' : ''}}</span></div>
                    <div v-if="Store.mobile"><span class="font-docs-recived-header">{{ setores.title.substring(0, 25) }} {{setores.title.length > 25 ? '...' : ''}}</span></div>
                    <div><span class="font-docs-recived-header">{{ setores.acronym}}</span></div>
                    <div v-for="Lider in setores.users" :key="Lider">
                        <span v-if="Lider.is_leader" class="label-docs" style="color: #6C757D">Responsável: {{ Lider.name.substring(0, 15) }} {{Lider.name.length > 15 ? '...' : ''}}</span>
                    </div>
                    <div class="d-flex mt-3">
                        <img v-for="servidor in setores.users.slice(0, 1)" :key="servidor" :src="this.Store.host + servidor.path_image" style="height: 40px; border-radius: 50%;">
                        <img v-for="servidor in setores.users.slice(1, 2)" :key="servidor" :src="this.Store.host + servidor.path_image" style="height: 40px; border-radius: 50%; position: relative; right: 10px;">
                        <img v-for="servidor in setores.users.slice(2, 3)" :key="servidor" :src="this.Store.host + servidor.path_image" style="height: 40px; border-radius: 50%; position: relative; right: 20px;">
                        <div v-if="setores.users.length > 3" class="d-flex align-items-center justify-content-center" style="width:40px;height: 40px;border:1px solid #3D8BFD; border-radius: 50%;background-color: #E7F1FF; position: relative; right: 30px;">
                            <span class="font-docs" style="color: #3D8BFD;"><b>+ {{ setores.users.length - 3 }}</b></span>
                        </div>
                    </div>
                </router-link>
                <div v-if="Store.user.abilities === undefined"></div>
                <font-awesome-icon v-else-if="Store.user.abilities.some(skills => skills.id === 2)" :icon="['fas', 'pen']" style="position: absolute; right:10px; top: 0px; cursor:pointer" @click="EditDepartament(setores)"/>
                <div v-else></div>
        </div>
      </div>
    </div>

      <div class="row">
        <div class="col-lg-12">
          <div class="row">
            <div class="d-flex justify-content-center">
            <div class="col-12 background-pags mt-2">
              <div v-if="this.Store.role === 1 || this.Store.role === 2 || this.Store.role === 3">
                <div class="d-flex justify-content-between">
                  <span></span>
                        <button  class="btn mt-3 background-Docs text-white" style="border: 1px solid #F1860B" type="button" @click="Add_Servidores()">
                            <i class="fa fa-plus mr-2 background-Docs" aria-hidden="true"></i>  Cadastrar Servidor
                    </button>
                    </div>

                    <span class="font-docs text-bold">Buscar</span>
                    <div class="d-flex justify-content-between mt-2">
                        <span class="p-input-icon-left" style="width: 100% !important;" >
                            <i class="pi pi-search" />
                            <InputText v-model="filters['global'].value" placeholder="Pesquise pelo nome, email ou cargo" style="width: 100% !important;" />
                        </span>
                    </div>
              </div>
              <div class="card">
                  <DataTable :filters="filters" :value="servidores" responsive-layout="scroll" :paginator="true" :rows="10"  filter-display="menu"
                  :global-filterfields="['name','departamento']">
                      <Column field="name" header="Nome" class="text-sm text-wrap font-docs text-bold" headerClass='font-docs-recived-header'>
                        <template #body="slotProps">
                          <img :src="this.Store.host + slotProps.data.path_image"
                                class="mr-2" style="widows: 40px; height: 40px; border-radius: 50%;" />
                            <span> {{slotProps.data.name}} </span>
                        </template>
                      </Column>
                      <Column field="email" header="E-mail" class="text-sm text-wrap font-docs text-bold" headerClass='font-docs-recived-header'>
                      </Column>
                      <Column field="role" header="Cargo" class="text-sm text-wrap font-docs text-bold" headerClass='font-docs-recived-header'>
                      </Column>
                      <Column v-if="servidores.length >= 2 && Store.user.abilities.some(skills => skills.id === 2)" class="text-sm text-wrap font-docs text-bold" headerClass='font-docs-recived-header'>
                        <template #body="slotProps">
                          <span class="font-docs" style="color:red;cursor:pointer" @click="removerUser(slotProps.data)">Remover</span>
                        </template>
                      </Column>
                      <Column v-else>
                      </Column>
                  </DataTable>
            </div>
          </div>
        </div>
    </div>
 </div>
</div>
</div>
</template>
