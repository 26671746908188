<script>
import ArgonButton from '@/components/ArgonButton.vue';
import { mapMutations } from 'vuex';
import { AxiosAPI } from '@/axios';
import { useMainStore } from '../../../stores/index';
import ArgonInput from '@/components/ArgonInput.vue';
const body = document.getElementsByTagName('body')[0];

export default {
  name: 'RegisterExterno',
  components: {
    ArgonButton,
    ArgonInput
  },
  setup () {
    const StoreAll = useMainStore();
    return {
      StoreAll
    };
  },
  data () {
    return {
      timer: 3,
      data: this.$route.params.token,
      companyIMG: '',
      genderSelect: [{ id: 0, name: 'SELECIONE' }, { id: 1, name: 'MASCULINO' }, { id: 2, name: 'FEMININO' }, { id: 3, name: 'OUTROS' }],
      gender: 'SELECIONE',
      formattedCpf: '',
      birth: '',
      fullname: '',
      email: '',
      password: '',
      password_two: '',
      showPassword: false,
      successCreateUser: false
    };
  },
  created () {
    this.$store.state.hideConfigButton = true;
    this.toggleDefaultLayout();
    body.classList.remove('bg-gray-100');
  },
  beforeUnmount () {
    this.$store.state.hideConfigButton = false;
    this.toggleDefaultLayout();
    body.classList.add('bg-gray-100');
  },
  mounted () {
    this.GetImg();
  },
  methods: {
    ...mapMutations(['toggleDefaultLayout']),

    handleOnComplete (event) {
      this.codeToken = event;
    },

    GetImg () {
      AxiosAPI
        .get('api/cityhalls', {
        }).then((response) => {
          console.log(response.data.cityhalls[0].image);
          this.companyIMG = this.StoreAll.host + response.data.cityhalls[0].image;
        });
    },

    formatCpf () {
      const unformattedCpf = this.formattedCpf.replace(/\D/g, '');

      this.formattedCpf = this.formattedCpf.substring(0, 11);

      let formattedCpf = '';
      for (let i = 0; i < unformattedCpf.length; i++) {
        formattedCpf += unformattedCpf[i];
        if (i === 2 || i === 5) {
          formattedCpf += '.';
        } else if (i === 8) {
          formattedCpf += '-';
        }
      }

      this.formattedCpf = formattedCpf;
    },

    Register () {
      const formData = new FormData();

      let Gender = '';
      if (this.gender === 'MASCULINO') {
        Gender = 'm';
      } else if (this.gender === 'FEMININO') {
        Gender = 'f';
      } else {
        Gender = 'x';
      }
      const string = this.data;
      const token = string.split('=')[1];
      const image = document.getElementById('image').files[0];
      formData.append('name', this.fullname);
      formData.append('birth', this.birth);
      formData.append('gender', Gender);
      formData.append('cpf', this.formattedCpf);
      formData.append('email', this.email);
      formData.append('password', this.password);
      formData.append('password_confirmation', this.password_two);
      formData.append('path_image', image);
      formData.append('token', token);
      AxiosAPI
        .post('api/users/create-by-link', formData, {
        }).then((response) => {
          console.log(response);
          if (response.status === 200) {
            this.successCreateUser = true;
            this.timerRedirect();
            setTimeout(() => {
              this.$router.push({ name: 'login' });
            }, 3000);
          }
        });
    },

    timerRedirect () {
      const timer = this.timer;
      setTimeout(() => {
        this.timer = timer - 1;
        this.timerRedirect();
      }, 900);
    }
  }
};
</script>
<template>
    <div>
      <div class="container top-0 position-sticky z-index-sticky">
      </div>
      <main class="mt-0 main-content">
        <section>
          <div class="page-header min-vh-100">
            <div class="container">
              <div class="row">
                <div v-if="!StoreAll.mobile"
                  class="col-foot-login d-lg-flex pe-0 justify-content-center flex-column footer-login"
                >
                  <div
                    class="position-relative h-100 px-7 d-flex flex-column justify-content-center overflow-hidden"
                  >
                    <span class="mask" style="background-color:#000000"></span>
                   <div class="dis-none">
                    <img class="mx-auto text-center display-img" src="../../../assets/img/OBJECTS.png">
                    </div>
                    <div class="Social-details">
                      <img src="../../../assets/img/login_brand.png" class="navbar-brand-img" alt="main_logo" />
                      <h5
                        class="mt-3 text-white text-left font-weight-bolder position-relative font-docs"
                      >
                        2D Smart2doc
                      </h5>
                      <span class="text-white position-relative font-docs fonts-size">
                        Uma nova forma de gerenciar seus documentos.
                      </span>
                      <div class="d-flex btn-background mt-4">
                        <a href="#">
                          <img class="mx-auto text-center" style="object-fit: cover; z-index:1" src="../../../assets/img/Facebook_btn.png">
                        </a>
                        <a href="#">
                          <img class="mx-auto text-center" style="object-fit: cover; z-index:1" src="../../../assets/img/Instagram_btn.png">
                        </a>
                        <a href="#">
                          <img class="mx-auto text-center" style="object-fit: cover; z-index:1" src="../../../assets/img/Network_btn.png">
                        </a>
                        </div>
                    </div>
                  </div>
                </div>

                <div v-if="!StoreAll.mobile" class="mx-auto col-xl-6 col-lg-6 col-lg-6 d-flex flex-column mx-lg-0 position-absolute end-0 login-box">
                <div class="d-flex justify-content-center">
                  <img class="img-low" :src="companyIMG" style="object-fit: contain;"><!-- VARIAVEL PARA RECEBIMENTO POR LINK DE BRASÃO DE PREFEITURA -->
                </div>
                <div class="d-flex justify-content-center">
                  <div class="card card-plain login-inputs-box">
                    <div>
                    <div class="pb-0 card-header text-start No-pad">
                      <h5 class="font-weight-bolder font-docs" style="color: #212529;">Faça seu cadastro a baixo</h5>
                    </div>
                    <div v-if="successCreateUser" class="d-flex align-items-center mb-4" style="width: 100%; height: 46px; background-color: #D1E7DD">
                        <div>
                          <i class="fa fa-check-circle ml-2 mr-2" aria-hidden="true" style="color:#0F5132; font-size: 15px;"></i>
                          <span class="font-docs" style="color: #0F5132"> <b>Cadastro criado com sucesso, você será redirecionado para o login em {{ this.timer }}</b> </span>
                        </div>
                        </div>
                    <div class="card-body No-pad">
                      <div class="mb-3">
                          <label class="label-docs">Nome completo<span style="color: red;">*</span></label>
                          <input type="email" class="my_form" v-model="fullname" placeholder="seuemail@mail.com">
                      </div>
                      <div class="mb-3">
                          <label class="label-docs">E-mail <span style="color: red;">*</span></label>
                          <input type="email" class="my_form" v-model="email" placeholder="seuemail@mail.com">
                      </div>

                      <div class='row'>
                        <div class="col-sm-6 mt-2">
                          <label for="formFile" class="form-label text-sm">Cpf</label>
                          <input class="my_form" v-model="formattedCpf" placeholder="xxx.xxx.xxx-xx" @input="formatCpf()" maxlength="14">
                      </div>

                      <div class="col-sm-6 mt-2">
                          <label for="formFile" class="label-docs">Data de nascimento <span style="color:red">*</span></label>
                          <input class="my_form" id="birth" v-model="birth" type="date" placeholder="dd/mm/aaaa" name="name" size="lg" />
                      </div>

                      <div class="col-sm-6 mt-2">
                        <label for="formFile" class="form-label text-sm">GÊNERO</label>
                        <select id='choices-category' v-model='gender' class='my_form' name='choices-category'>
                            <option v-for='option in genderSelect' :key='option.name' :value='option.name'>
                              {{ option.name }}
                            </option>
                          </select>
                      </div>

                      <div class="col-sm-6 mt-2">
                          <label for="formFile" class="label-docs">Foto de perfil <span style="color:red">*</span></label>
                          <argon-input id="image" type="file" placeholder="IMAGEM" name="name" size="lg" />
                      </div>

                      <div class="col-sm-6 mt-2">
                          <label for="formFile" class="label-docs">Senha <span style="color:red">*</span></label>
                          <div class="input-container">
                            <input class="my_form" :type="showPassword ? 'text' : 'password'" v-model="password" placeholder="*********">
                            <i class="fas fa-eye" v-if="showPassword" @click="showPassword = !showPassword"></i>
                            <i class="fas fa-eye-slash" v-else @click="showPassword = !showPassword"></i>
                          </div>
                      </div>

                      <div class="col-sm-6 mt-2">
                        <label for="formFile" class="label-docs">Repita a senha<span style="color:red">*</span></label>
                        <div class="input-container">
                          <input class="my_form" :type="showPassword ? 'text' : 'password'" v-model="password_two" placeholder="*********">
                          <i class="fas fa-eye" v-if="showPassword" @click="showPassword = !showPassword"></i>
                          <i class="fas fa-eye-slash" v-else @click="showPassword = !showPassword"></i>
                        </div>
                      </div>
                      </div>

                        <div class="text-center">
                          <argon-button
                            class="mt-5 text-white d-flex justify-content-center align-items-center font-docs background-Docs"
                            variant="gradient"
                            color="#F1860B"
                            full-width
                            size="lg"
                            style="font-size:16px !important; font-weight: 400;height: 38px; padding:6px 12px 6px 12px; gap:8px;"
                            @click="Register()"
                            >Cadastrar</argon-button
                          >
                        </div>
                    </div>
                  </div>
                  </div>
                </div>
              </div>

              <div v-if="StoreAll.mobile" class="mx-auto col-xl-6 col-lg-6 col-lg-6 d-flex flex-column mx-lg-0 end-0 login-box">
                <div class="d-flex justify-content-center">
                  <img class="img-low" :src="companyIMG" style="object-fit: contain;"><!-- VARIAVEL PARA RECEBIMENTO POR LINK DE BRASÃO DE PREFEITURA -->
                </div>
                <div class="d-flex justify-content-center">
                  <div class="card card-plain login-inputs-box">
                    <div>
                    <div class="pb-0 card-header text-start No-pad">
                      <h5 class="font-weight-bolder font-docs" style="color: #212529;">Faça seu cadastro a baixo</h5>
                    </div>
                    <div class="card-body No-pad">
                      <div class="mb-3">
                          <label class="label-docs">Nome completo<span style="color: red;">*</span></label>
                          <input type="email" class="my_form" v-model="fullname" placeholder="seuemail@mail.com">
                      </div>
                      <div class="mb-3">
                          <label class="label-docs">E-mail <span style="color: red;">*</span></label>
                          <input type="email" class="my_form" v-model="email" placeholder="seuemail@mail.com">
                      </div>

                      <div class='row'>
                        <div class="col-sm-6 mt-2">
                          <label for="formFile" class="form-label text-sm">Cpf</label>
                          <input class="my_form" v-model="formattedCpf" placeholder="xxx.xxx.xxx-xx" @input="formatCpf()" maxlength="14">
                      </div>

                      <div class="col-sm-6 mt-2">
                          <label for="formFile" class="label-docs">Data de nascimento <span style="color:red">*</span></label>
                          <input class="my_form" id="birth" v-model="birth" type="date" placeholder="dd/mm/aaaa" name="name" size="lg" />
                      </div>

                      <div class="col-sm-6 mt-2">
                        <label for="formFile" class="form-label text-sm">GÊNERO</label>
                        <select id='choices-category' v-model='gender' class='my_form' name='choices-category'>
                            <option v-for='option in genderSelect' :key='option.name' :value='option.name'>
                              {{ option.name }}
                            </option>
                          </select>
                      </div>

                      <div class="col-sm-6 mt-2">
                          <label for="formFile" class="label-docs">Foto de perfil <span style="color:red">*</span></label>
                          <argon-input id="image" type="file" placeholder="IMAGEM" name="name" size="lg" />
                      </div>

                      <div class="col-sm-6 mt-2">
                          <label for="formFile" class="label-docs">Senha <span style="color:red">*</span></label>
                          <div class="input-container">
                            <input class="my_form" :type="showPassword ? 'text' : 'password'" v-model="password" placeholder="*********">
                            <i class="fas fa-eye" v-if="showPassword" @click="showPassword = !showPassword"></i>
                            <i class="fas fa-eye-slash" v-else @click="showPassword = !showPassword"></i>
                          </div>
                      </div>

                      <div class="col-sm-6 mt-2">
                        <label for="formFile" class="label-docs">Repita a senha<span style="color:red">*</span></label>
                        <div class="input-container">
                          <input class="my_form" :type="showPassword ? 'text' : 'password'" v-model="password_two" placeholder="*********">
                          <i class="fas fa-eye" v-if="showPassword" @click="showPassword = !showPassword"></i>
                          <i class="fas fa-eye-slash" v-else @click="showPassword = !showPassword"></i>
                        </div>
                      </div>
                      </div>

                        <div class="text-center">
                          <argon-button
                            class="mt-5 text-white d-flex justify-content-center align-items-center font-docs background-Docs"
                            variant="gradient"
                            color="#F1860B"
                            full-width
                            size="lg"
                            style="font-size:16px !important; font-weight: 400;height: 38px; padding:6px 12px 6px 12px; gap:8px;"
                            @click="Register()"
                            >Cadastrar</argon-button
                          >
                        </div>
                    </div>
                  </div>
                  </div>
                </div>
              </div>
              <div v-if="StoreAll.mobile"
                  class="col-foot-login d-lg-flex pe-0 justify-content-center flex-column footer-login position-relative mt-3"
                >
                  <div
                    class="position-relative h-100 px-7 d-flex flex-column justify-content-center overflow-hidden"
                  >
                    <span class="mask" style="background-color:#000000"></span>
                   <div class="dis-none">
                    <img class="mx-auto text-center display-img" src="../../../assets/img/OBJECTS.png">
                    </div>
                    <div class="Social-details">
                      <img src="../../../assets/img/login_brand.png" class="navbar-brand-img mt-2" alt="main_logo" />
                      <h5
                        class="mt-1 text-white text-left font-weight-bolder position-relative font-docs"
                      >
                        2D Smart2doc
                      </h5>
                      <span class="text-white position-relative font-docs fonts-size">
                        Uma nova forma de gerenciar seus documentos.
                      </span>
                      <div class="d-flex btn-background mt-1">
                        <a href="#">
                          <img class="mx-auto text-center" style="object-fit: cover; z-index:1" src="../../../assets/img/Facebook_btn.png">
                        </a>
                        <a href="#">
                          <img class="mx-auto text-center" style="object-fit: cover; z-index:1" src="../../../assets/img/Instagram_btn.png">
                        </a>
                        <a href="#">
                          <img class="mx-auto text-center" style="object-fit: cover; z-index:1" src="../../../assets/img/Network_btn.png">
                        </a>
                        </div>
                    </div>
                  </div>
                </div>
            </div>
          </div>
          </div>
        </section>
      </main>
      </div>
    </template>
