<script>
import ArgonButton from '@/components/ArgonButton.vue';
import routerpath from '../../../router/routerpath';
export default {
  name: 'notFound',
  components: {
    ArgonButton
  },
  setup () {
    return {
      routerpath
    };
  }
};
</script>
<template>
    <div class="vh-100 w-100 d-flex align-items-center justify-content-center" style="background-color:#363534">
        <div>
        <div class="d-flex justify-content-center align-items-center">
            <img src="../../../assets/img/notfound.png">
        </div>
        <div class="d-flex justify-content-center align-items-center">
            <h5 class="font-docs text-white">Desculpe! Página não encontrada.</h5>
        </div>
        <div class="d-flex justify-content-center align-items-center">
            <span class="font-docs text-white">Retorne a página principal para prosseguir.</span>
        </div>
        <div class="d-flex justify-content-center align-items-center">
            <router-link :to='{ name: "Home"}' exact>
                <argon-button
                    class="mt-4 text-black d-flex font-docs justify-content-center align-items-center"
                    variant="gradient"
                    color="#F8F9FA"
                    size="lg"
                    style="background-color:#F8F9FA; font-size:16px !important; font-weight: 400; width:416px; height: 38px; padding:6px 12px 6px 12px; gap:8px;"
                    >Ir para Início
                </argon-button>
            </router-link>
        </div>
    </div>
</div>
</template>
