<script>
import ArgonButton from '@/components/ArgonButton.vue';
import { mapMutations } from 'vuex';
import { AxiosAPI } from '@/axios';
import { useMainStore } from '../../../stores/index';
const body = document.getElementsByTagName('body')[0];

export default {
  name: 'LoginUser',
  components: {
    ArgonButton
  },
  setup () {
    const StoreAll = useMainStore();
    return {
      StoreAll
    };
  },
  data () {
    return {
      email: '',
      incorrectAuth: false,
      codeSend: false,
      loadingCheckEmail: false,
      CheckEmailError: false,
      successSendToken: false,
      CheckEmailLess: false,
      tokenSuccess: false,
      TokenWrong: false,
      tokenVerify: false,
      checkVerifyToken: false,
      showPassword: false,
      wonrogPassword: false,
      checkPasswords: false,
      successChangePassword: false,
      timer: 3,
      newPassword: '',
      newPasswordConfirm: '',
      codeToken: '',
      companyIMG: ''
    };
  },
  created () {
    this.$store.state.hideConfigButton = true;
    this.toggleDefaultLayout();
    body.classList.remove('bg-gray-100');
  },
  beforeUnmount () {
    this.$store.state.hideConfigButton = false;
    this.toggleDefaultLayout();
    body.classList.add('bg-gray-100');
  },
  mounted () {
    this.GetImg();
  },
  methods: {
    ...mapMutations(['toggleDefaultLayout']),

    handleOnComplete (event) {
      this.codeToken = event;
    },

    GetImg () {
      AxiosAPI
        .get('api/cityhalls', {
        }).then((response) => {
          console.log(response.data.cityhalls[0].image);
          this.companyIMG = this.StoreAll.host + response.data.cityhalls[0].image;
        });
    },

    checkEmail () {
      this.CheckEmailLess = false;
      if (this.email !== '') {
        this.codeSend = true;
      } else {
        this.CheckEmailLess = true;
      }
    },

    async sendToken () {
      this.CheckEmailError = false;
      this.loadingCheckEmail = true;
      this.CheckEmailLess = false;
      await AxiosAPI
        .post('api/auth/forgot_password',
          {
            email: this.email
          }, {
            headers: {
              'Content-Type': 'application/json'
            }
          }).then((response) => {
          console.log(response);
          this.loadingCheckEmail = false;
          this.successSendToken = true;
          this.codeSend = true;
        })
        .catch((error) => {
          if (error.response.status === 500) {
            this.loadingCheckEmail = false;
            this.CheckEmailError = true;
          }
        });
    },

    async verifyToken () {
      this.checkVerifyToken = true;
      this.TokenWrong = false;
      const tokenCode = this.codeToken.toUpperCase();
      await AxiosAPI
        .post('api/auth/verify_reset_token',
          {
            email: this.email,
            token: tokenCode
          }, {
            headers: {
              'Content-Type': 'application/json'
            }
          }).then(() => {
          this.checkVerifyToken = false;
          this.tokenSuccess = true;
          this.tokenVerify = false;
        })
        .catch(() => {
          this.checkVerifyToken = false;
          this.TokenWrong = true;
        });
    },

    async changePassword () {
      this.checkPasswords = true;
      this.wonrogPassword = false;
      this.successChangePassword = false;
      if (this.newPassword === this.newPasswordConfirm) {
        const tokenCode = this.codeToken.toUpperCase();
        await AxiosAPI
          .post('api/auth/reset_password',
            {
              email: this.email,
              token: tokenCode,
              password: this.newPassword,
              password_confirmation: this.newPasswordConfirm
            }, {
              headers: {
                'Content-Type': 'application/json'
              }
            }).then((response) => {
            if (response.status === 200) {
              this.checkPasswords = false;
              this.successChangePassword = true;
              this.timerRedirect();
              setTimeout(() => {
                this.timer = 3;
                this.$router.push({ name: 'login' });
              }, 3000);
            }
            console.log(response);
          });
      }
      if (this.newPassword !== this.newPasswordConfirm) {
        this.checkPasswords = false;
        this.wonrogPassword = true;
      }
    },

    timerRedirect () {
      const timer = this.timer;
      setTimeout(() => {
        this.timer = timer - 1;
        this.timerRedirect();
      }, 900);
    }
  }
};
</script>
<template>
    <div>
      <div class="container top-0 position-sticky z-index-sticky">
      </div>
      <main class="mt-0 main-content">
        <section>
          <div class="page-header min-vh-100">
            <div class="container">
              <div class="row">
                <div
                  class="col-foot-login d-lg-flex pe-0 justify-content-center flex-column footer-login"
                >
                  <div
                    class="position-relative h-100 px-7 d-flex flex-column justify-content-center overflow-hidden"
                  >
                    <span class="mask" style="background-color:#000000"></span>
                   <div class="dis-none">
                    <img class="mx-auto text-center display-img" src="../../../assets/img/OBJECTS.png">
                    </div>
                    <div class="Social-details">
                      <img src="../../../assets/img/login_brand.png" class="navbar-brand-img" alt="main_logo" />
                      <h5
                        class="mt-3 text-white text-left font-weight-bolder position-relative font-docs"
                      >
                        2D Smart2doc
                      </h5>
                      <span class="text-white position-relative font-docs fonts-size">
                        Uma nova forma de gerenciar seus documentos.
                      </span>
                      <div class="d-flex btn-background mt-4">
                        <a href="#">
                          <img class="mx-auto text-center" style="object-fit: cover; z-index:1" src="../../../assets/img/Facebook_btn.png">
                        </a>
                        <a href="#">
                          <img class="mx-auto text-center" style="object-fit: cover; z-index:1" src="../../../assets/img/Instagram_btn.png">
                        </a>
                        <a href="#">
                          <img class="mx-auto text-center" style="object-fit: cover; z-index:1" src="../../../assets/img/Network_btn.png">
                        </a>
                        </div>
                    </div>
                  </div>
                </div>
                <div v-if="!codeSend && !tokenSuccess" class="mx-auto col-xl-6 col-lg-6 col-lg-6 d-flex flex-column mx-lg-0 position-absolute end-0 login-box">
                <div class="d-flex justify-content-center">
                  <img class="img-low" :src="companyIMG" style="object-fit: contain;"><!-- VARIAVEL PARA RECEBIMENTO POR LINK DE BRASÃO DE PREFEITURA -->
                </div>
                <div class="d-flex justify-content-center">
                  <div class="card card-plain login-inputs-box">
                    <div>
                    <div class="pb-0 card-header text-start No-pad mt-5">
                      <h5 class="font-weight-bolder font-docs" style="color: #212529;">Esqueci minha senha</h5>
                      <span class="font-docs" style="color: #6C757D;">Para sua segurança, enviaremos um código para validar sua redefinição de senha.</span>
                    </div>
                    <div class="card-body No-pad">
                      <form role="form" @submit.prevent="Login">
                        <div v-if="loadingCheckEmail" class="d-flex align-items-center mb-2" style="width: 100%; height: 46px; background-color: rgb(186 202 219)">
                          <i class='fa fa-circle-o-notch fa-spin fa-1x ml-1 mr-2' style="color:rgb(30 92 159)"></i>
                          <span class="" style="color: rgb(30 92 159)"> <b>Verificando e-mail</b> </span>
                        </div>
                        <div v-if="CheckEmailError" class="d-flex justify-content-between align-items-center mb-2" style="width: 100%; height: 46px; background-color: rgb(219 209 186)">
                          <div>
                            <i class="fa fa-exclamation-triangle ml-2 mr-2" style="color: rgb(105 103 18); font-size: 13px;" aria-hidden="true"></i>
                            <span class="" style="color: rgb(105 103 18); padding-top: 1px;"> <b>E-mail informado não existe</b> </span>
                          </div>
                          <div class="d-flex justify-content-center align-items-center" style="width: 56px; height: 56px">
                            <font-awesome-icon :icon="['fas', 'x']" style="color:rgb(125 120 127); cursor: pointer" @click="CheckEmailError = false" />
                          </div>
                        </div>
                        <div v-if="CheckEmailLess" class="d-flex justify-content-between align-items-center mb-2" style="width: 100%; height: 46px; background-color: rgb(219 209 186)">
                          <div>
                            <i class="fa fa-exclamation-triangle ml-2 mr-2" style="color: rgb(105 103 18); font-size: 13px;" aria-hidden="true"></i>
                            <span class="" style="color: rgb(105 103 18); padding-top: 1px;"> <b>Informe o e-mail para o código que possui</b> </span>
                          </div>
                          <div class="d-flex justify-content-center align-items-center" style="width: 56px; height: 56px">
                            <font-awesome-icon :icon="['fas', 'x']" style="color:rgb(125 120 127); cursor: pointer" @click="CheckEmailLess = false" />
                          </div>
                        </div>
                          <div class="mb-3">
                            <label class="label-docs">E-mail <span style="color: red;">*</span></label>
                            <input type="email" class="my_form" v-model="email" placeholder="seuemail@mail.com">
                          </div>
                        <div class="text-center">
                          <argon-button
                            class="mt-5 text-white d-flex justify-content-center align-items-center font-docs background-Docs"
                            variant="gradient"
                            color="#F1860B"
                            full-width
                            size="lg"
                            style="font-size:16px !important; font-weight: 400;height: 38px; padding:6px 12px 6px 12px; gap:8px;"
                            @click="sendToken()"
                            >Enviar</argon-button
                          >
                        </div>
                        <div class="text-center">
                          <argon-button
                            class="mt-3 d-flex justify-content-center align-items-center font-docs Color-Docs"
                            variant="gradient"
                            color="#F1860B"
                            full-width
                            size="lg"
                            style="background-color:#FFFFFF !important; font-size:16px !important; font-weight: 400;height: 38px; padding:6px 12px 6px 12px; gap:8px; border: 1px solid #F1860B"
                            @click="checkEmail()"
                            >Já tenho código</argon-button
                          >
                        </div>
                        <div class="text-center mt-4 font-docs">
                          <router-link :to='{ name: "login"}' exact>
                            <a href="#" class="Color-Docs">Ir para Acessar</a>
                          </router-link>
                        </div>
                      </form>
                    </div>
                  </div>
                  </div>
                </div>
              </div>
                <div v-if="codeSend && !tokenSuccess" class="mx-auto col-xl-6 col-lg-6 col-lg-6 d-flex flex-column mx-lg-0 position-absolute end-0 login-box">
                <div class="d-flex justify-content-center">
                  <img class="img-low" src="../../../assets/img/brasoes/PF_NATAL.png" style="object-fit: cover;"> <!-- VARIAVEL PARA RECEBIMENTO POR LINK DE BRASÃO DE PREFEITURA -->
                </div>
                <div class="d-flex justify-content-center">
                  <div class="card card-plain login-inputs-box">
                    <div>
                    <div class="pb-0 card-header text-start No-pad mt-5">
                      <div v-if="successSendToken" class="d-flex justify-content-between align-items-center mb-4" style="width: 100%; height: 46px; background-color: #D1E7DD">
                        <div>
                          <i class="fa fa-check-circle ml-2 mr-2" aria-hidden="true" style="color:#0F5132; font-size: 15px;"></i>
                          <span class="font-docs" style="color: #0F5132"> <b>Código enviado para [{{email}}]</b> </span>
                        </div>
                          <div class="d-flex justify-content-center align-items-center" style="width: 56px; height: 56px">
                            <font-awesome-icon :icon="['fas', 'x']" style="color:rgb(125 120 127); cursor: pointer" @click="successSendToken = false" />
                          </div>
                        </div>
                        <div v-if="checkVerifyToken" class="d-flex align-items-center mb-4" style="width: 100%; height: 46px; background-color: #D1E7DD">
                          <i class='fa fa-circle-o-notch fa-spin fa-1x ml-1 mr-2' style="color:rgb(30 92 159)"></i>
                          <span class="" style="color: rgb(30 92 159)"> <b>Verificando token</b> </span>
                        </div>
                        <div v-if="TokenWrong" class="d-flex justify-content-between align-items-center mb-4" style="width: 100%; height: 46px; background-color: #D1E7DD">
                          <div>
                            <i class="fa fa-exclamation-triangle ml-2 mr-2" style="color: rgb(105 103 18); font-size: 13px;" aria-hidden="true"></i>
                            <span class="" style="color: rgb(105 103 18); padding-top: 1px;"> <b>Token incorreto ou vencido</b> </span>
                          </div>
                          <div class="d-flex justify-content-center align-items-center" style="width: 56px; height: 56px">
                              <font-awesome-icon :icon="['fas', 'x']" style="color:rgb(125 120 127); cursor: pointer" @click="TokenWrong = false" />
                          </div>
                        </div>
                      <h5 class="font-weight-bolder font-docs" style="color: #212529;">Insira o código</h5>
                      <span class="font-docs" style="color: #6C757D;">Enviado para o e-mail informado.</span>
                    </div>
                    <div class="card-body No-pad">
                      <div class="d-flex justify-content-center">
                          <CodeInput
                            ref="otpInput"
                            input-classes="otp-input text-uppercase"
                            inputmode="text"
                            separator=" "
                            input-type="letter-numeric"
                            :num-inputs="4"
                            @on-complete="handleOnComplete"
                          />
                        </div>
                      <form role="form" @submit.prevent="Login">
                        <div class="text-center">
                          <argon-button
                            class="mt-5 text-white d-flex justify-content-center align-items-center font-docs background-Docs"
                            variant="gradient"
                            color="#F1860B"
                            full-width
                            size="lg"
                            style="font-size:16px !important; font-weight: 400;height: 38px; padding:6px 12px 6px 12px; gap:8px;"
                            @click="verifyToken()"
                            >Confirmar</argon-button
                          >
                        </div>
                        <div class="text-center">
                          <argon-button
                            class="mt-3 d-flex justify-content-center align-items-center font-docs Color-Docs"
                            variant="gradient"
                            color="#F1860B"
                            full-width
                            size="lg"
                            style="background-color:#FFFFFF !important; font-size:16px !important; font-weight: 400;height: 38px; padding:6px 12px 6px 12px; gap:8px; border: 1px solid #F1860B"
                            @click="codeSend = false; successSendToken = false; CheckEmailLess = false;"
                            >Enviar novo código</argon-button
                          >
                        </div>
                        <div class="text-center mt-4 font-docs">
                          <router-link :to='{ name: "login"}' exact>
                            <a href="#" class="Color-Docs">Ir para Acessar</a>
                          </router-link>
                        </div>
                      </form>
                    </div>
                  </div>
                  </div>
                </div>
              </div>
              <div v-if="tokenSuccess" class="mx-auto col-xl-6 col-lg-6 col-lg-6 d-flex flex-column mx-lg-0 position-absolute end-0 login-box">
                <div class="d-flex justify-content-center">
                  <img class="img-low" src="../../../assets/img/brasoes/PF_NATAL.png" style="object-fit: cover;"> <!-- VARIAVEL PARA RECEBIMENTO POR LINK DE BRASÃO DE PREFEITURA -->
                </div>
                <div class="d-flex justify-content-center">
                  <div class="card card-plain login-inputs-box">
                    <div>
                    <div class="pb-0 card-header text-start No-pad mt-5">
                      <div v-if="checkPasswords" class="d-flex align-items-center mb-4" style="width: 100%; height: 46px; background-color: #D1E7DD">
                        <i class='fa fa-circle-o-notch fa-spin fa-1x ml-1 mr-2' style="color:rgb(30 92 159)"></i>
                        <span class="" style="color: rgb(30 92 159)"> <b>Verificando senhas</b> </span>
                      </div>
                      <div v-if="wonrogPassword" class="d-flex justify-content-between align-items-center mb-4" style="width: 100%; height: 46px; background-color: #D1E7DD">
                        <div>
                          <i class="fa fa-exclamation-triangle ml-2 mr-2" style="color: rgb(105 103 18); font-size: 13px;" aria-hidden="true"></i>
                          <span class="" style="color: rgb(105 103 18); padding-top: 1px;"> <b>Senhas não são iguais</b> </span>
                        </div>
                        <div class="d-flex justify-content-center align-items-center" style="width: 56px; height: 56px">
                            <font-awesome-icon :icon="['fas', 'x']" style="color:rgb(125 120 127); cursor: pointer" @click="CheckEmailError = false" />
                        </div>
                      </div>
                      <div v-if="successChangePassword" class="d-flex align-items-center mb-4" style="width: 100%; height: 46px; background-color: #D1E7DD">
                        <div>
                          <i class="fa fa-check-circle ml-2 mr-2" aria-hidden="true" style="color:#0F5132; font-size: 15px;"></i>
                          <span class="font-docs" style="color: #0F5132"> <b>Senha alterada com sucesso, você será redirecionado para o login em {{ this.timer }}</b> </span>
                        </div>
                        </div>
                      <h5 class="font-weight-bolder font-docs" style="color: #212529;">Defina sua nova senha</h5>
                    </div>
                    <div class="card-body No-pad">
                      <form role="form" @submit.prevent="Login">
                        <div class="mb-3">
                          <label class="label-docs">Nova senha <span style="color: red;">*</span></label>
                          <div>
                            <div class="input-container">
                              <input :type="showPassword ? 'text' : 'password'" class="my_form" :class="{ 'password-field': !showPassword }" v-model="newPassword" placeholder="********">
                              <i class="fas fa-eye" v-if="showPassword" @click="showPassword = !showPassword"></i>
                              <i class="fas fa-eye-slash" v-else @click="showPassword = !showPassword"></i>
                            </div>
                          </div>
                          <small class="ml-1 font-docs" style="color:#6C757D">Mínimo 8 dígitos.</small>
                        </div>
                        <div class="mb-3">
                          <label class="label-docs">Confirmar senha <span style="color: red;">*</span></label>
                          <div>
                            <div class="input-container">
                              <input :type="showPassword ? 'text' : 'password'" class="my_form" :class="{ 'password-field': !showPassword }" v-model="newPasswordConfirm" placeholder="********">
                              <i class="fas fa-eye" v-if="showPassword" @click="showPassword = !showPassword"></i>
                              <i class="fas fa-eye-slash" v-else @click="showPassword = !showPassword"></i>
                            </div>
                          </div>
                        </div>
                        <div class="text-center">
                          <argon-button
                            class="mt-5 text-white d-flex justify-content-center align-items-center font-docs background-Docs"
                            variant="gradient"
                            color="#F1860B"
                            full-width
                            size="lg"
                            style="font-size:16px !important; font-weight: 400;height: 38px; padding:6px 12px 6px 12px; gap:8px;"
                            @click="changePassword()"
                            >Salvar</argon-button
                          >
                        </div>
                        <div class="text-center mt-4 font-docs">
                          <router-link :to='{ name: "login"}' exact>
                            <a href="#" class="Color-Docs">Ir para Acessar</a>
                          </router-link>
                        </div>
                      </form>
                    </div>
                  </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          </div>
        </section>
      </main>
      </div>
    </template>
