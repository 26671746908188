<!-- eslint-disable vue/no-useless-template-attributes -->
<template>
    <div>
    <ModalViewSevidores ref="ModalViewSevidores"></ModalViewSevidores>
    </div>
    <div>
        <ModalBigger ref="viewDocument">
            <template #header style="display: block !important;">
                <div class="d-flex justify-content-between text-dark text-bold" style="width: 100%;">
                    <div class="pt-2">
                        <div>
                            <span>{{ data.title }}</span>
                        </div>
                    </div>
                    <div>
                        <button type="button" class="close" @click="$refs.viewDocument.closeModal()">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                </div>
            </template>

            <template #body>

                <div class="mt-3">
                    <span class="text-dark text-bold"> Descrição </span>
                </div>

                <div class="mt-4">
                    {{ data.description }}
                </div>

                <div class="mt-4">
                    <span class="text-dark text-bold"> Servidores </span>
                </div>

                <div class="mt-3">
                    <div class="table-responsive" style="border: solid 1px #e1cfcf; border: none !important;">
                  <DataTable :value="data.users" responsive-layout="scroll">
                      <Column class="text-sm font-weight-bold text-wrap text-uppercase">
                        <template #body="data">
                            <img :src="'https://smart2docs-dev.s3.amazonaws.com/' + data.data.path_image" style="widows: 40px; height: 40px; border-radius: 1rem"/>
                            {{ data.data.name }}
                          </template>
                    </Column>
                      <Column v-if="this.$pinia.state.value.main.role === 3 || this.$pinia.state.value.main.role === 1" class="text-sm font-weight-bold text-wrap text-uppercase TT justify-content-center text-center">
                        <template #body="slotProps">
                            <a style="cursor: pointer;font-size: 0.9rem;color: #5e5e5e;" @click="View_user(slotProps.data)" >Visualizar</a>
                          </template>
                        </Column>
                  </DataTable>

              </div>
                </div>
            </template>

            <template #footer>
                <div class="d-flex justify-content-end">
                    <button type="button" class="btn btn-primary text-dark mr-3" style="background-color: #edeef1;" @click="$refs.viewDocument.closeModal()">
                        Voltar
                    </button>
                </div>
            </template>
        </ModalBigger>

    </div>
</template>

<script>
/* import { AxiosAPI } from "@/axios"; */
import ModalBigger from './ModalBigger.vue';
import ModalViewSevidores from './Modal_View_Sevidores.vue';
export default {
  components: {
    ModalBigger,
    ModalViewSevidores
  },

  data () {
    return {
      data: []
    };
  },
  methods: {
    openModal (event) {
      this.$refs.viewDocument.openModal();
      this.data = event;
    },

    View_user (event) {
      this.$refs.ModalViewSevidores.openModal(event);
    }

  }
};

</script>

<style scoped>
.remove_header{
    padding: 0 !important;
    border: none !important;
    border-width:0 !important;
}
.close {
    font-size: 2.3rem !important;
}</style>
