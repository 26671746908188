<!-- eslint-disable vue/no-useless-template-attributes -->
<template>
    <div>
      <div>
        <TreeSelectTable ref="TreeSelectTable"></TreeSelectTable>
      </div>
        <ModalBig ref="viewDocument" style="z-index: 999 !important;">
            <template #header>
                <div class="d-flex justify-content-between text-dark text-bold" style="width: 100%;">
                    <div class="pt-2">
                        <div>
                            <img :src="'https://smart2docs-dev.s3.amazonaws.com/' + data.path_image"
                                style="widows: 40px; height: 40px; border-radius: 1rem" />
                            <span class="ml-3" style="color: black;"> {{ data.name }} </span>
                        </div>
                    </div>
                    <div>
                        <button type="button" class="close" @click="$refs.viewDocument.closeModal()">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                </div>
            </template>

            <template #body>
                <div v-if="!edit">
                    <div class=" mt-3">
                        <label for="" class="text-sm" style="color: black;">NOME COMPLETO</label>
                        <div class="p-inputgroup flex-1">
                            <span class="p-inputgroup-addon">
                                <i class="fa fa-user" aria-hidden="true"></i>
                            </span>
                            <input class="my_form_user icon-input text-bold" type="text" v-model="name" placeholder="Nome"
                                disabled>
                        </div>
                    </div>

                    <div class="mt-2">
                        <label for="formFile" class="form-label text-sm">DATA DE NASCIMENTO</label>
                        <input class="my_form text-bold" type="date" v-model="data.birth" disabled>
                    </div>

                    <div class="mt-2">
                        <label for="formFile" class="form-label text-sm">MATRICULA</label>
                        <input class="my_form text-bold" v-model="data.registration" disabled>
                    </div>

                    <div class="mt-2">
                        <label for="formFile" class="form-label text-sm">CARGO</label>
                        <input class="my_form text-bold" v-model="data.role" disabled>
                    </div>

                    <div class="mt-2">
                        <label for="formFile" class="form-label text-sm">CPF</label>
                        <input class="my_form text-bold" v-model="data.cpf" disabled>
                    </div>

                    <div class="mt-2">
                        <label for="formFile" class="form-label text-sm">EMAIL</label>
                        <input class="my_form text-bold" type="email" v-model="data.email" disabled>
                    </div>

                    <div class="d-flex mt-3" style="align-items: baseline;">
                        <label for="" class="text-sm" style="color: black;">Gênero: </label>
                        <div class="d-flex">
                            <Tag v-if="data.gender === 'm'" class="d-flex ml-2"
                                style='border: 1px solid rgb(7 127 251);background-color: rgb(9 125 227 / 10%);color: rgb(2 145 249);'>
                                <div class='flex align-items-center gap-2'>
                                    <font-awesome-icon class="mr-1" icon="fa-solid fa-mars" />
                                    <span class='text-base text-uppercase'>MASCULINO</span>
                                </div>
                            </Tag>

                            <Tag v-if="data.gender === 'f'" class="d-flex ml-2"
                                style='border: 1px solid rgb(236 7 251);background-color: rgb(204 9 227 / 10%);color: rgb(202 2 249);'>
                                <div class='flex align-items-center gap-2'>
                                    <font-awesome-icon class="mr-1" icon="fa-solid fa-venus" />
                                    <span class='text-base text-uppercase'>FEMININO</span>
                                </div>
                            </Tag>

                            <Tag v-if="data.gender === 'x'" class="d-flex ml-2"
                                style='border: 1px solid rgb(191 7 251);color: rgb(0 0 0);background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(255,0,0,0.5522584033613445) 0%, rgba(244,38,10,0.5830707282913166) 3%, rgba(217,125,32,0.5830707282913166) 10%, rgba(151,190,64,0.5942752100840336) 20%, rgba(53,194,117,0.5382528011204482) 30%, rgba(53,207,169,0.4906337535014006) 40%, rgba(71,117,207,0.49343487394957986) 50%, rgba(63,61,208,0.3897934173669467) 60%, rgba(141,0,255,0.3449754901960784) 70%, rgba(201,0,255,0.33657212885154064) 80%, rgba(255,0,134,0.2861519607843137) 90%, rgba(255,0,0,0.27494747899159666) 100%);'>
                                <div class='flex align-items-center gap-2'>
                                    <font-awesome-icon class="mr-1" icon="fa-solid fa-venus-mars" />
                                    <span class='text-base text-uppercase'>OUTROS</span>
                                </div>
                            </Tag>
                        </div>
                    </div>

                    <div class="d-flex mt-3" style="align-items: baseline;">
                        <label for="" class="text-sm" style="color: black;">Perimissão(ões): </label>
                        <div v-for='abilitys in data.abilities' :key='abilitys.id' class="d-flex">
                            <Tag class="d-flex ml-2"
                                style='border: 1px solid rgb(219 184 11);background-color: rgb(167 163 6 / 10%);color: rgb(99 88 5);'>
                                <div class='flex align-items-center gap-2'>
                                    <font-awesome-icon class="mr-1" icon="fa-solid fa-unlock-keyhole" />
                                    <span class='text-base text-uppercase'>{{ abilitys.title }}</span>
                                </div>
                            </Tag>
                        </div>
                    </div>

                    <div class="d-flex mt-3" style="align-items: baseline;">
                        <label for="" class="text-sm" style="color: black;">Departamento(s): </label>
                        <div v-for='departaments in data.departments' :key='departaments.id' class="d-flex">
                            <Tag class="d-flex ml-2"
                                style='border: 1px solid rgb(6 70 167);background-color: rgb(6 48 167 / 10%);color: rgb(6 54 167);'>
                                <div class='flex align-items-center gap-2'>
                                    <font-awesome-icon class="mr-1" icon="fa fa-building" />
                                    <span class='text-base text-uppercase'>{{ departaments.title }}</span>
                                </div>
                            </Tag>
                        </div>
                    </div>
                </div>

                <div v-if="edit">
                    <div class=" mt-3">
                        <label for="" class="text-sm" style="color: black;">NOME COMPLETO</label>
                        <div class="p-inputgroup flex-1">
                            <span class="p-inputgroup-addon">
                                <i class="fa fa-user" aria-hidden="true"></i>
                            </span>
                            <input class="my_form_user icon-input text-bold" type="text" v-model="name" placeholder="Nome">
                        </div>
                    </div>

                    <div class="mt-2">
                        <label for="formFile" class="form-label text-sm">DATA DE NASCIMENTO</label>
                        <input class="my_form text-bold" type="date" v-model="birth">
                    </div>

                    <div class="mt-2">
                        <label for="formFile" class="form-label text-sm">GÊNERO</label>
                        <select id='choices-category' v-model='gender' class='my_form' name='choices-category'>
                            <option v-for='option in genderSelect' :key='option.name' :value='option.name'>
                            {{ option.name }}
                            </option>
                        </select>
                    </div>

                    <div class="mt-2">
                        <label for="formFile" class="form-label text-sm">MATRICULA</label>
                        <input class="my_form text-bold" v-model="matricula">
                    </div>

                    <div class="mt-2">
                        <label for="formFile" class="form-label text-sm">CARGO</label>
                        <input class="my_form text-bold" v-model="cargo">
                    </div>

                    <div class="mt-2">
                        <label for="formFile" class="form-label text-sm">CPF</label>
                        <input class="my_form text-bold" v-model="formattedCpf" @input="formatCpf()" maxlength="14">
                    </div>

                    <div class="mt-2">
                        <label for="formFile" class="form-label text-sm">EMAIL</label>
                        <input class="my_form text-bold" type="email" v-model="email">
                    </div>

                    <div class="mt-3">
                        <label for="formFile" class="form-label text-sm" style="color: black;">Trocar imagem</label>
                        <argon-input id="image" type="file" placeholder="IMAGEM" name="name" size="lg" />
                    </div>

                    <div>
                        <label for="formFile" class="form-label text-sm" style="color: black;">PERMISSÕES</label>
                        <MultiSelectPrime id="choices-category" v-model="abilities" display="chip"
                            :options="this.Store.abilities" option-label="title" class="form-control w-full md:w-20rem"
                            style="height: calc(3.5rem + 2px) !important;" />
                    </div>

                    <div>
                        <label for="formFile" class="form-label text-sm" style="color: black;">DEPARTAMENTO</label>
                        <MultiSelectPrime id="choices-category" v-model="Departamentos" display="chip"
                            :options="this.Store.Departamentos" option-label="title" class="form-control w-full md:w-20rem"
                            style="height: calc(3.5rem + 2px) !important;" />
                    </div>

                    <div>
                      <label for="formFile" class="form-label text-sm">SELECIONAR DEPARTAMENTOS E SUB DEPARTAMENTOS: (Caso já exista) </label>
                      <button class="ml-2 mt-2 btn btn-success" @click="OpenTreeSelectTable"> + </button>
                    </div>

                    <div class="d-flex">
                    <div v-for='departamendosADD in NewDep' :key='departamendosADD.id'  class="d-flex justify-content-center">
                        <Tag class="d-flex ml-2" style='border: 1px solid rgb(6 70 167);background-color: rgb(6 48 167 / 10%);color: rgb(6 54 167);'>
                            <div class='flex align-items-center gap-2'>
                                <font-awesome-icon class="mr-1" icon="fa fa-building" />
                                <span class='text-base text-uppercase'>{{ departamendosADD.title}}</span>
                            </div>
                        </Tag>
                    </div>
                </div>
                </div>
            </template>

            <template #footer>
                <div v-if="!edit" class="d-flex justify-content-between mt-5">
                    <div class="mt-2"></div>
                    <div>
                        <button type="button" class="btn text-white" style="background-color: #0776d6;" @click="EditOn">
                            Editar
                        </button>
                    </div>
                </div>
                <div v-if="edit" class="d-flex justify-content-between mt-5">
                    <div class="mt-2">
                        <a href="#" class="text-bold" style="color: #af2515;">Desativar servidor</a>
                    </div>

                    <div>
                        <button type="button" class="btn btn-primary text-dark mr-3" style="background-color: #edeef1;"
                            @click="$refs.viewDocument.closeModal()">
                            Voltar
                        </button>
                        <button type="button" class="btn text-white" style="background-color: #0776d6;" @click="SendEdit">
                            Salvar alterações
                        </button>
                    </div>
                </div>
            </template>
        </ModalBig>

    </div>
</template>

<script>
import { AxiosAPI } from '@/axios';
import ModalBig from './ModalBig.vue';
import { useMainStore } from '../../../stores/index.js';
import ArgonInput from '@/components/ArgonInput.vue';
import TreeSelectTable from './TreeSelectTable.vue';

export default {
  components: {
    ModalBig,
    ArgonInput,
    TreeSelectTable
  },
  setup () {
    const StoreAll = useMainStore();
    StoreAll.Token();
    return {
      Store: StoreAll
    };
  },
  data () {
    return {
      data: [],
      name: '',
      birth: '',
      matricula: '',
      cargo: '',
      email: '',
      Departamentos: [],
      NewDep: [],
      abilities: [],
      edit: false,
      formattedCpf: '',
      userId: 0,
      gender: '',
      genderSelect: [{ id: 1, name: 'MASCULINO' }, { id: 2, name: 'FEMININO' }, { id: 3, name: 'OUTROS' }]
    };
  },

  watch: {
    'Store.DepartamentosAdd': function (newValue, oldPath) {
      if (newValue === true) {
        this.Departamentos = newValue;
      }
    }
  },
  methods: {
    openModal (event) {
      this.name = '';
      this.birth = '';
      this.gender = '';
      this.matricula = '';
      this.cargo = '';
      this.permissions = '';
      this.cpf = '';
      this.Departamentos = [];

      if (event.gender === 'm') {
        this.gender = 'MASCULINO';
      } else if (event.gender === 'f') {
        this.gender = 'FEMININO';
      } else {
        this.gender = 'OUTROS';
      }
      this.$refs.viewDocument.openModal();
      this.data = event;
      this.edit = false;
      this.name = event.name;
      this.userId = event.id;
      this.birth = event.birth;
      this.matricula = event.registration;
      this.cargo = event.role;
      this.email = event.email;
      this.formattedCpf = event.cpf;

      event.departments.forEach(departs => {
        this.Store.Departamentos.forEach(MyDep => {
          if (MyDep.id === departs.id) {
            this.Departamentos.push(MyDep);
          }
        });
      });

      event.abilities.forEach(abilities => {
        this.Store.abilities.forEach(Myabilities => {
          if (Myabilities.id === abilities.id) {
            this.abilities.push(Myabilities);
          }
        });
      });
      this.Store.DepartamentosAdd = this.Departamentos;
      this.NewDep = this.Departamentos;
    },

    OpenTreeSelectTable () {
      this.$refs.TreeSelectTable.openModal();
    },

    formatCpf () {
      const unformattedCpf = this.formattedCpf.replace(/\D/g, '');

      this.formattedCpf = this.formattedCpf.substring(0, 11);

      let formattedCpf = '';
      for (let i = 0; i < unformattedCpf.length; i++) {
        formattedCpf += unformattedCpf[i];
        if (i === 2 || i === 5) {
          formattedCpf += '.';
        } else if (i === 8) {
          formattedCpf += '-';
        }
      }

      this.formattedCpf = formattedCpf;
    },

    SendEdit () {
      let Gender = '';
      if (this.gender === 'MASCULINO') {
        Gender = 'm';
      } else if (this.gender === 'FEMININO') {
        Gender = 'f';
      } else {
        Gender = 'x';
      }

      const image = document.getElementById('image').files[0];

      const Departaments = [];
      this.Departamentos.forEach(DepartId => {
        Departaments.push(DepartId.id);
      });
      const DepartamentsJson = JSON.stringify(Departaments);

      const abilitys = [];
      this.abilities.forEach(abilitysId => {
        abilitys.push(abilitysId.id);
      });
      const abilitysJson = JSON.stringify(abilitys);

      const formData = new FormData();

      formData.append('_method', 'PUT');
      formData.append('name', this.name);
      formData.append('birth', this.birth);
      formData.append('gender', Gender);
      formData.append('registration', this.matricula);
      formData.append('role', this.cargo);
      formData.append('ability_id', abilitysJson);
      formData.append('cpf', this.formattedCpf);
      formData.append('email', this.email);
      if (document.getElementById('image').files[0] !== undefined) { formData.append('path_image', image); };
      formData.append('department_id', DepartamentsJson);

      AxiosAPI.post('/api/users/' + this.userId, formData, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.$pinia.state.value.main.accessToken
        }
      })
        .then(() => {
          this.emitter.emit('Add_Servidores', 1);
          this.name = '';
          this.birth = '';
          this.gender = '';
          this.matricula = '';
          this.cargo = '';
          this.permissions = '';
          this.cpf = '';
          this.Departamentos = [];
        });

      this.$refs.viewDocument.closeModal();
    },

    EditOn () {
      this.edit = true;
    }

  }
};

</script>

<style>
.my_form_user {
    min-height: unset;
    border: 1px solid rgba(0, 0, 0, 0.38);
    padding: 0.75rem 0.75rem;
    font-size: 0.875rem;
    height: calc(2.875rem + 2px);
    display: block;
    width: 100%;
    padding: 0.5rem 0.75rem;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.4rem;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    transition: box-shadow 0.15s ease, border-color 0.15s ease;
}

.close {
    font-size: 2.3rem !important;
}</style>
