<script>
import { useMainStore, useEventStore } from '../../../stores/index';
import DataEdit from './DataEdit.vue';
import AttachmentEdit from './AttachmentEdit.vue';
import UserSendEdit from './UserSendEdit.vue';
import PreviewSendEdit from './PreviewSendEdit.vue';
import SignersEdit from './SignersEdit.vue';
export default {
  name: 'DashboardDefault',
  components: { DataEdit, AttachmentEdit, UserSendEdit, SignersEdit, PreviewSendEdit },
  setup () {
    const StoreAll = useMainStore();
    const Events = useEventStore();
    StoreAll.Token();
    return {
      Store: StoreAll,
      Events
    };
  },
  data () {
    return {
      componenteHeight: '93vh'
    };
  },
  mounted () {
    this.checkComponentHeight();
    window.addEventListener('resize', this.checkComponentHeight);
  },
  beforeUnmount () {
    window.removeEventListener('resize', this.checkComponentHeight);
  },
  watch: {
  },
  methods: {
    checkComponentHeight () {
      const componente = document.getElementById('Edit');
      const alturaViewport = window.innerHeight;

      if (componente.clientHeight < 0.93 * alturaViewport) {
        this.componenteHeight = '88vh';
      } else {
        this.componenteHeight = componente.clientHeight < 0.93 * alturaViewport + 'vh'; // Adaptar ao tamanho adicional
      }
    }
  }
};
</script>
<template>
  <div class='py-4 container-fluid'>
    <div class='row'>
      <div class='col-lg-12'>
        <div class='row'>
          <div class='d-flex justify-content-center'>
            <div class='col-12' id="Edit" :style="{ minHeight: componenteHeight }">
                <DataEdit v-if="this.Store.progressEdit === 1" />
                <AttachmentEdit v-if="this.Store.progressEdit === 2" />
                <UserSendEdit v-if="this.Store.progressEdit === 3"  />
                <SignersEdit v-if="this.Store.progressEdit === 4"  />
                <PreviewSendEdit v-if="this.Store.progressEdit === 5"  />
            </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>
