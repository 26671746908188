<script>
import { AxiosAPI } from '@/axios';
import { useMainStore } from '../../../stores/index';
import ModalComponent from './ModalComponent.vue';
export default {
  name: 'ModalTask',
  components: { ModalComponent },
  setup () {
    const StoreAll = useMainStore();
    return {
      Store: StoreAll
    };
  },
  data () {
    return {
      id: 0,
      TaskName: '',
      description: '',
      usersAll: [],
      users: [],
      ModelUser: [],
      edit: false,
      isDropdownOpen: false
    };
  },

  watch: {
    ModelUser: function (value) {
      this.users = [];
      value.forEach(user => {
        this.usersAll.forEach(userAll => {
          if (user === userAll.id) {
            this.users.push(userAll);
          }
        });
      });

      AxiosAPI.put(`/api/tasks/${this.id}`, {
        users: this.users.map(user => user.id)
      }, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.Store.accessToken}`
        }
      }).then(() => {
        this.$emit('taskDescription');
      });
    }
  },

  methods: {
    openCreateTask (value) {
      const dataUser = [];
      const ModelUser = [];
      this.id = value.id;
      this.TaskName = value.title;
      this.description = value.description;

      value.users.forEach(users => {
        dataUser.push(users);
        ModelUser.push(users.id);
      });

      const SlotUsers = [];
      this.Store.users.forEach(users => {
        users.value = users.id;
        users.label = users.name;
        SlotUsers.push(users);
      });

      this.usersAll = SlotUsers;
      this.users = dataUser;
      this.ModelUser = ModelUser;

      this.$refs.viewDocument.openModal();
    },

    descriptionUpdate () {
      AxiosAPI.put(`/api/tasks/${this.id}`, {
        description: this.description
      }, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.Store.accessToken}`
        }
      }).then(() => {
        this.$emit('taskDescription');
      });
    },

    RemoveTask () {
      AxiosAPI.delete(`/api/tasks/${this.id}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.Store.accessToken}`
          }
        }).then(() => {
        this.$emit('taskDescription');
        this.$refs.viewDocument.closeModal();
      });
    }
  }
};
</script>
<style scoped>
.dropdown-content p {
  margin: 0;
  padding: 8px;
  cursor: pointer;
}
.dropdown-content p:hover {
  background-color: #f0f0f0;
}
</style>
<template>
    <div>
        <ModalComponent ref="viewDocument">
            <template #header>
              <div class="d-flex justify-content-between align-items-center">
                  <div>
                      <span class="ml mr title font-docs"><b>{{ TaskName }}</b></span>
                  </div>
                  <div>

                      <button
                      id="close"
                      type="button"
                      class="close"
                      @click="$refs.viewDocument.closeModal();"
                      >
                          <span aria-hidden="true">&times;</span>
                      </button>

                  </div>
                </div>
            </template>
            <template #body>
              <div class="d-flex justify-content-between align-items-center">
                <div class="ml-3">
                  <div>
                    <span class="font-docs">Membros</span>
                  </div>
                  <div class="d-flex">
                    <img v-for="user in users.slice(0, 2)"
                      :key="user.id"
                      :src="this.Store.host + user.path_image"
                      style="height: 40px; border-radius: 50%;">
                    <div v-if="users.length > 2"
                      class="d-flex align-items-center justify-content-center"
                      style="width:40px; height: 40px; border:1px solid #3D8BFD; border-radius: 50%; background-color: #E7F1FF;">
                      <span class="font-docs" style="color: #3D8BFD;"><b>+ {{ users.length - 2 }}</b></span>
                    </div>
                      <div class="d-flex justify-content-center align-items-center background-Docs"
                      style="width: 40px; height: 40px; border-radius: 50%; position: relative; cursor:pointer"
                      @click="isDropdownOpen = !isDropdownOpen">
                        <i class="fa fa-plus" aria-hidden="true"></i>
                      </div>
                      <div
                        v-if="isDropdownOpen"
                        class="dropdown-content"
                        style="position: absolute; top: 145px; left: 35px; width:250px; background: white; border: 1px solid #ddd; padding: 10px; border-radius: 4px; box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15); z-index: 1000;"
                      >
                      <treeselects v-model="ModelUser" :disable-branch-nodes="true" :flat="true" :multiple="true"
                      :options="usersAll" placeholder="Busque por nome ou nome do departamento" @select="updatedUsers" @deselect="updatedUsers"/>
                      </div>
                  </div>
                  </div>

                  <div>
                    <button class="btn btn-danger font-docs" @click="RemoveTask()"><b>Excluir tarefa</b></button>
                  </div>
                </div>

                <div>
                  <div class="d-flex justify-content-between align-items-center mt-4">
                    <div>
                      <i class="fa fa-bars mr-2" aria-hidden="true"></i>
                      <span class="font-docs">Descrição</span>
                    </div>
                    <div>
                      <button class="btn btn-secondary font-docs" @click="edit = !edit"><b>Adicionar / Editar</b></button>
                    </div>
                  </div>
                  <div v-if="!edit" class="mt-2">
                    <span v-if="!description" class="font-docs">Nenhuma descrição</span>
                    <span v-if="description" class="font-docs">{{ description }}</span>
                  </div>
                  <div v-if="edit" class="mt-2">
                    <textarea class="form-control" rows="3" placeholder="Descrição" v-model="description"></textarea>
                    <button class="btn btn-primary font-docs background-Docs mr-2 mt-1" @click="descriptionUpdate(); edit = !edit"><b>Salvar</b></button>
                    <button class="btn btn-secondary font-docs mt-1" @click="edit = !edit"><b>Cancelar</b></button>
                  </div>
                </div>
            </template>
            <template #footer>
            </template>
        </ModalComponent>
    </div>
</template>
<style>
</style>
