import Cookie from 'js-cookie';
import { AxiosAPI } from '@/axios';
import routerpath from '../router/routerpath';
import router from '../router';
export default {

  auth (to, from, next) {
    AxiosAPI.get('/'
    ).then(() => {
      const token = Cookie.get('User');

      if (!token) {
        next('/' + routerpath + '/login');
        alert('Você foi desconectado, faça login novamente');
      } else {
        AxiosAPI.get('/api/documents/sent', {
          headers: {
            Authorization: 'Bearer ' + token
          }
        }
        ).then(() => {})
          .catch((error) => {
            if (error.response.status === 401) {
              Cookie.remove('User');
              next();
            }
          });

        next();
      }
    })
      .catch((error) => {
        if (error.response.status === 500) {
          router.push({ name: 'NotFoundCompany' });
        }
      });
  }
};
