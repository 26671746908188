<script>
import { useMainStore } from '../../stores/index';
export default {
  name: 'pathName',
  components: { },
  setup () {
    const StoreAll = useMainStore();
    StoreAll.Token();
    return {
      Store: StoreAll
    };
  },
  data () {
    return {
      routesNow: [],
      host: ''
    };
  },
  created () {
    this.pathName();
  },
  methods: {
    formatedRoutes (value) {
      const result = value.join(' / ');
      return result;
    },

    generatePath (index) {
      const currentPath = this.$route.path;
      const newPath = currentPath.split('/').slice(0, index + 1).join('/');
      return newPath.startsWith('/') ? newPath : `/${newPath}`;
    },

    pathName () {
      const paths = [];
      const path = this.$route.path;
      const startpath = path.indexOf('/');
      const endpath = path.indexOf('/', startpath + 1);
      const urls = path.substring(0, startpath + 1) + path.substring(endpath + 1);
      const segments = urls.split('/');

      const url = window.location.href;
      const startIndex = url.indexOf('https://') + 8;
      const pathIndex = url.indexOf('/', startIndex);
      const result = url.substring(startIndex, pathIndex);

      this.host = result;
      segments.forEach(element => {
        const decodedString = decodeURIComponent(element);
        paths.push(decodedString);
      });
      this.routesNow = paths;
      this.Store.routesNow = paths;
    }
  }
};
</script>
<style>
.not-clickable {
  pointer-events: none;
  color: #6C757D;
}
.underText{
  text-decoration: underline;
}
</style>
<template>
  <div class="d-flex align-items-center mb-3">
    <router-link :to='{ name: "Home" }' class="Color-Docs" style="text-decoration: underline;">
      <img src="../../assets/img/houseBradc.png" class="mr-1">
      <span class="font-docs">Home</span>
    </router-link>

    <div>
      <router-link
      v-for="(route, index) in routesNow"
      :to="generatePath(index)"
      :key="index"
      class="font-docs"
      :class="{ 'not-clickable': index === routesNow.length - 1, 'Color-Docs': index !== routesNow.length - 1 }"
    >
      <span v-if="index !== 0" style="">&nbsp;/&nbsp;</span><span :class="{ 'underText': index !== routesNow.length - 1}">{{ route }}</span>
    </router-link>
  </div>
  </div>
</template>
