<script>
import { AxiosAPI } from '@/axios';
import { useMainStore } from '../../stores/index';
import moment from 'moment';
import Breadcrumb from '../../components/documents/patchName.vue';
export default {
  name: 'RecivedDocs',
  components: { Breadcrumb },
  setup () {
    const Store = useMainStore();
    Store.Token();
    return {
      Store
    };
  },
  data () {
    return {
      componenteHeight: '93vh'
    };
  },
  watch: {
  },
  mounted () {
    this.checkComponentHeight();
    window.addEventListener('resize', this.checkComponentHeight);
  },
  beforeUnmount () {
    window.removeEventListener('resize', this.checkComponentHeight);
  },
  methods: {
    checkComponentHeight () {
      const componente = document.getElementById('Suporte');
      const alturaViewport = window.innerHeight;

      if (componente.clientHeight < 0.93 * alturaViewport) {
        this.componenteHeight = '92.5vh';
      } else {
        this.componenteHeight = componente.clientHeight < 0.93 * alturaViewport + 'vh';
      }
    },
    formatDate (value) {
      moment.locale('pt-br');
      return moment(value).format('DD/MMMM/YYYY');
    }

  }
};
</script>
<template>
    <div class='py-4 container-fluid' id="Suporte" :style="{ minHeight: componenteHeight }">
      <Breadcrumb />
      <h4 style="color:black">Suporte</h4>
      <div class="background-pags">
      <div class="row d-flex justify-content-center pt-4">
        <div class="d-flex justify-content-center align-items-center">
            <h4 class="font-docs">Aguarde, essa funcionalidade ainda não está disponível...</h4>
        </div>
        <div class="d-flex justify-content-center align-items-center">
            <router-link  :to='{ name: "Home"}'>
                <button class="btn mt-3 font-docs Color-Docs" style="border: 1px solid #F1860B" >Início</button>
            </router-link>
        </div>
      </div>

    </div>
  </div>
  </template>
