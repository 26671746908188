<script>
import { AxiosAPI } from '@/axios';
import ModalBigger from './ModalBigger.vue';
import { useMainStore } from '../../../stores/index';
export default {
  components: {
    ModalBigger
  },
  setup () {
    const StoreAll = useMainStore();
    StoreAll.Token();
    return {
      Store: StoreAll
    };
  },
  data () {
    return {
      name_departament: '',
      departamentOwner: [],
      departamentOwnerId: 0,
      SubDepCreat: false,
      description_departament: '',
      acronym: '',
      phone: '',
      assets: '',
      branch: '',
      userLiaderSelect: [],
      progress: 0,
      loading: false
    };
  },
  watch: {
    userLiaderSelect: function (newValue) {
      console.log(this.userLiaderSelect, newValue);
    }
  },
  computed: {
    isValidName () {
      return this.name_departament.length >= 1;
    },
    isValidDescript () {
      return this.description_departament.length >= 1;
    },
    isValidSigla () {
      return this.acronym.length >= 1;
    },
    isValidColor () {
      return this.assets === '#ffffff' || this.assets !== '';
    },
    isValidResp () {
      return this.userLiaderSelect.id !== undefined;
    }
  },
  methods: {
    openModal (value) {
      this.loading = false;
      let userLiader = [];
      userLiader = this.Store.users;
      userLiader.forEach(element => {
        element.label = element.name;
        element.value = element.id;
      });
      this.userLiader = userLiader;

      this.SubDepCreat = false;
      this.departamentOwner = [];
      this.departamentOwnerId = 0;
      if (value !== undefined) {
        this.SubDepCreat = true;
        this.departamentOwner = value;
        this.departamentOwnerId = value.id;
      }
      this.$refs.viewDocument.openModal();
    },

    updateValue (value) {
      let userSelect = [];
      userSelect = value;
      this.userLiaderSelect = userSelect;
    },

    send_departamento () {
      AxiosAPI.post('/api/departments/', {
        title: document.getElementById('name').value,
        description: document.getElementById('description').value,
        leader_id: this.userLiaderSelect.id,
        acronym: document.getElementById('description').value,
        phone: document.getElementById('TELEFONE').value,
        branch: document.getElementById('RAMAL').value,
        assets: document.getElementById('COR').value
      }, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.Store.accessToken
        }
      })
        .then((resp) => {
          this.emitter.emit('create_departament', 1);
          console.log(resp);
        });

      this.$refs.viewDocument.closeModal();
    },

    async sendDepartamentoOwner () {
      this.loading = true;
      const interval = setInterval(() => {
        if (this.progress < 90) {
          this.progress += 10;
        }
      }, 1000);
      this.$refs.hiddenButtonss.click();
      await AxiosAPI.post('/api/departments/', {
        department_id: this.departamentOwnerId,
        title: document.getElementById('name').value,
        leader_id: this.userLiaderSelect.id,
        description: document.getElementById('description').value,
        acronym: document.getElementById('SIGLA').value,
        phone: document.getElementById('TELEFONE').value,
        branch: document.getElementById('RAMAL').value,
        assets: document.getElementById('COR').value
      }, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.Store.accessToken
        }
      })
        .then((resp) => {
          console.log(resp);
          this.progress = 100;
          clearInterval(interval);
          setTimeout(() => {
            document.getElementById('name').value = '';
            this.userLiaderSelect = [];
            document.getElementById('description').value = '';
            document.getElementById('SIGLA').value = '';
            document.getElementById('TELEFONE').value = '';
            document.getElementById('RAMAL').value = '';
            document.getElementById('COR').value = '';
            this.$refs.hiddenButtonCloses.click();
            this.emitter.emit('create_subdep', this.departamentOwner.id);
          }, 2000);
        });
    }

  }
};

</script>

<style>.swal2-container {
    z-index: 10001 !important;
}

.close {
    font-size: 2.3rem !important;
}

.color-display {
  width: 100px;
  height: 100px;
  border: 1px solid #000;
}
</style>

<template>
    <div>
        <ModalBigger ref="viewDocument" :class="{'noneClass': loading}">
            <template #header>
                <div class="d-flex justify-content-between text-dark text-bold" style="width: 100%;">
                    <div class="pt-2">
                        <div>
                            <h4 class="font-docs text-black text-bold">Cadastrar Sub-setor</h4>
                        </div>
                    </div>
                    <div>
                        <button type="button" class="close" @click="$refs.viewDocument.closeModal()">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                </div>
            </template>

            <template #body>
                <span class="font-docs">Setor acima: <span class="text-bold">{{ departamentOwner.title }}</span></span>
                <div class="mt-3">
                    <label for="formFile" class="label-docs">Nome <span style="color:red">*</span></label>
                    <input id="name" v-model="name_departament" type="text" placeholder="Nome do setor" name="name" :class="{'my_form': true, 'invalid-input': !isValidName}"/>
                </div>

                <div class="mt-2">
                    <label for="formFile" class="label-docs">Descrição do setor <span style="color:red">*</span></label>
                    <input id="description" v-model="description_departament" type="text" placeholder="Descrição do setor" name="name" :class="{'my_form': true, 'invalid-input': !isValidDescript}"/>
                </div>

                <div class="mt-2">
                    <label for="formFile" class="label-docs">Secretário responsável <span style="color:red">*</span></label>
                    <treeselects :disable-branch-nodes="true" :flat="true" :multiple="false"
                    :options="userLiader" @select="updateValue" placeholder="Busque pelo nome do servidor" style="height: calc(2.25rem + 2px)" :class="{'my_form pt-0 pl-0 pr-0': true, 'invalid-input': !isValidResp}"/>
                </div>

                <div class='row'>
                  <div class='col-sm-6 mt-1'>
                    <div class="mt-2">
                        <label for="formFile" class="label-docs">Sigla <span style="color:red">*</span></label>
                        <input id="SIGLA" v-model="acronym" type="text" placeholder="Sigla do setor (abc)" name="name" :class="{'my_form': true, 'invalid-input': !isValidSigla}"/>
                    </div>
                  </div>

                <div class='col-sm-6 mt-1'>
                  <div class="mt-2">
                    <label for="formFile" class="label-docs">Cor do setor <span style="color:red">*</span></label>
                    <div class="d-flex align-items-center" :class="{'my_form': true, 'invalid-input': !isValidColor}">
                      <input v-model="assets" type="color" id="COR" name="COR" value="#ffffff"/>
                      <span class="ml-3">{{ assets }}</span>
                    </div>
                  </div>
                </div>

                <div class='col-sm-6 mt-1'>
                  <div class="mt-2">
                      <label for="formFile" class="label-docs">Ramal <span style="color:red">*</span></label>
                      <input id="RAMAL" v-model="branch" type="text" placeholder="Ramal do setor (xxxx)" name="name" class="my_form"/>
                  </div>
                </div>

                <div class='col-sm-6 mt-1'>
                  <div class="mt-2">
                      <label for="formFile" class="label-docs">Telefone <span style="color:red">*</span></label>
                      <input id="TELEFONE" v-model="phone" type="text" placeholder="Telefone do setor (xxxxxxxxx)" name="name" class="my_form"/>
                  </div>
              </div>
              </div>

            </template>

            <template #footer>
                <div class="d-flex justify-content-end">
                    <button type="button" class="btn Color-Docs mr-3" style="border: 1px solid #F1860B" @click="$refs.viewDocument.closeModal()">
                        Voltar
                    </button>
                    <button v-if="this.userLiaderSelect.id === undefined  || assets == '' || acronym == '' || description_departament == '' || name_departament == ''" type="button" class="btn text-white background-Docs" disabled>
                        Cadastrar
                    </button>
                    <button v-else type="button" class="btn text-white background-Docs" @click="sendDepartamentoOwner();">
                        Cadastrar
                    </button>
                </div>
            </template>
        </ModalBigger>

        <button type="button" ref="hiddenButtonss" class="btn btn-primary d-none" data-toggle="modal" data-target="#Loadings"></button>
        <div class="modal fade" id="Loadings" tabindex="-1" role="dialog" aria-labelledby="Loadings" aria-hidden="true">
                      <div class="modal-dialog modal-dialog-centered" role="document">
                        <div class="modal-content" style="background-color:  rgba(0,0,0,0) !important; border: 0px">
                          <button type="button" ref="hiddenButtonCloses" class="close d-none" data-dismiss="modal" aria-label="Close">
                          <span aria-hidden="true">&times;</span>
                        </button>
                          <div class="modal-body">
                            <span class="font-docs text-white d-flex justify-content-center">Criando sub-Setor, Por favor, aguarde...</span>
                            <div class="progress" style="height: 30px;">
                              <div class="progress-bar" role="progressbar" :style="'width:' + this.progress + '%'" style="height: 30px;
                              animation: progress-bar-stripes 1s linear infinite !important;
                              background-image: linear-gradient(62deg, hsla(0, 0%, 100%, .15) 25%, transparent 0, transparent 50%, hsla(0, 0%, 100%, .15) 0, hsla(0, 0%, 100%, .15) 75%, transparent 0, transparent) !important;
                              background-size: 0.5rem 1rem;
                              transition: width .6s ease;
                              background-color: #F1860B;" :aria-valuenow="this.progress" aria-valuemin="0" aria-valuemax="100">{{this.progress}}%</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

    </div>
</template>
