<script>
import routerpath from '../../router/routerpath';
import { mapState, mapMutations, mapActions } from 'vuex';
import Cookie from 'js-cookie';
import ModalAddCity from '../documents/Modals/Modal_Add_city.vue';
import { useMainStore } from '../../stores/index';
export default {
  name: 'NavBar',
  components: { ModalAddCity },
  setup () {
    const StoreAll = useMainStore();
    StoreAll.Token();
    return {
      Store: StoreAll
    };
  },
  data () {
    return {
      showMenu: false,
      showMenuu: false
    };
  },
  computed: {
    ...mapState(['isRTL', 'isNavFixed', 'darkMode']),
    currentRouteName () {
      return this.$route.name;
    },
    currentDirectory () {
      const dir = this.$route.path.split(routerpath + '/')[1];
      return dir.charAt(0).toUpperCase() + dir.slice(1);
    },
    limitedNotifyMessages () {
      return this.Store.notifyMessages.slice(0, 5);
    }
  },

  methods: {
    ...mapMutations(['navbarMinimize', 'toggleConfigurator']),
    ...mapActions(['toggleSidebarColor']),

    logout () {
      Cookie.remove('User');
      Cookie.remove('Session_ID');
      location.reload();
    },

    registerHall () {
      this.$refs.ModalAddCity.openModal();
    },

    MenuOpen () {
      this.Store.Menu = true;
    },

    truncateText (text, length) {
      if (text.length > length) {
        return text.substring(0, length) + '...';
      }
      return text;
    },
    teste () {
      console.log('teste');
    }
  }
};
</script>
<style>
.dropdown .dropdown-menu.dropdown-menu-end:before{
  right: 39px!important;
}

@media (min-width: 626px) {
  .MenuClose {
    display: none !important;
  }
}
@media (max-width: 625px) {
  .responsiveClose{
    display: none !important;
  }
}
</style>
<template>
  <div>
  <ModalAddCity ref="ModalAddCity" />
  <nav
    id="navbarBlur"
    class="nav-background"
    :class="`${
      !isNavFixed
        ? 'navbar navbar-main navbar-expand-lg '
        : `navbar navbar-main navbar-expand-lg position-sticky ${
            darkMode ? 'bg-default' : 'bg-white'
          } left-auto top-2 z-index-sticky`
    } ${isRTL ? 'top-0 position-sticky z-index-sticky' : ''}`"
    v-bind="$attrs"
    data-scroll="true"
  >
    <div class="px-2 py-1 container-fluid">

      <div
        id="navbar"
        class="mt-2 collapse navbar-collapse mt-sm-0 me-md-0"
      >
      <router-link class="m-0 navbar-brand" to="/">

        <div v-if="this.Store.isPinned && !this.Store.mobile" class="d-flex justify-content-center">
          <img src="../../assets/img/Logo_2docs_extend.png" class="navbar-brand-img" alt="main_logo" />
        </div>

         <div v-if="!this.Store.isPinned && !this.Store.mobile" class="d-flex justify-content-center">
          <img src="../../assets/img/Logo_2docs_extend.png" class="navbar-brand-img" alt="main_logo" />
        </div>

        <div v-if="this.Store.isPinned && this.Store.mobile" class="d-flex justify-content-center">
          <img src="../../assets/img/2D.png" class="navbar-brand-img h-100" alt="main_logo" style="width: 40px;" />
        </div>

        <div v-if="!this.Store.isPinned && this.Store.mobile" class="d-flex justify-content-center">
          <img src="../../assets/img/2D.png" class="navbar-brand-img h-100" alt="main_logo" style="width: 40px;" />
        </div>

        </router-link>
        <div
          class="pe-md-3 d-flex align-items-center"
          :class="isRTL ? 'me-md-auto' : 'ms-md-auto'"
        >
        </div>

        <ul class="navbar-nav justify-content-end nav-background">
          <li
            class="nav-item dropdown d-flex align-items-center nav-background"
            :class="isRTL ? 'ps-2' : 'pe-3'"
          >
        <div class="mr-4">
          <div v-if="this.Store.role !== 1"></div>
          <button v-else type="button" class="mb-0 btn mr-4 text-white" style="background-color: #1733d3;" @click="registerHall()">
            <i class="fa fa-plus-circle mr-2" aria-hidden="true"></i>
            Cadastrar prefeitura
          </button>
        </div>

            <a
              id="dropdownMenuButton"
              href="#"
              :class="`p-0 nav-link ${
                isNavFixed && !darkMode ? ' opacity-8 text-dark' : 'text-white'
              } ${showMenu ? 'show' : ''}`"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              @click="showMenu = !showMenu"
            >
            <font-awesome-icon  v-if="(this.Store.notifyMessages.length >= 1)" :icon="['fas', 'bell']"  class="cursor-pointer mr-1"  style="color: yellow;"/>
            <font-awesome-icon  v-else :icon="['fas', 'bell']"  class="cursor-pointer mr-1" />
            </a>
            <ul
              class="px-2 py-2 dropdown-menu dropdown-menu-end me-sm-n3" style="margin-top: 0.75rem !important; right: -3px !important; top: 2.25rem !important;width: 260px;"
              :class="showMenu ? 'show' : ''"
              aria-labelledby="dropdownMenuButton"
            >

            <div v-if="this.Store.notifyMessages.length >= 1">
              <span class="font-docs ml-2" style="font-size: 20px">Notificações</span>
              <li v-for="notify in limitedNotifyMessages" :key="notify" class="mb-1">
                <router-link v-if="notify.tosigner !== undefined"
              :to='{ name: "Assinaturas"}' exact>
                <a class="dropdown-item border-radius-md" href="javascript:;">
                  <div class="py-1 d-flex">
                    <div class="d-flex justify-content-center">
                      <img :src="this.Store.host + notify.author.user.path_image"
                        style="widows: 40px; height: 40px; border-radius: 50%;" />
                        <div class="d-grid ml-2">
                          <span class="font-docs ml-2">{{ truncateText(notify.protocol, 20) }}</span>
                          <span class="font-docs ml-2">{{ truncateText(notify.subject, 20) }}</span>
                          <span v-if="notify.tosigner !== undefined" class="font-docs ml-2" style="color:rgb(153, 116, 4)"> Assinatura necessária </span>
                          <span v-if="notify.tosignerAttach !== undefined" class="font-docs ml-2" style="color:rgb(153, 116, 4)"> Assinatura anexo(os) </span>
                        </div>
                    </div>
                  </div>
                </a>
               </router-link>
               <router-link v-if="notify.tosigner === undefined && notify.tosignerAttach !== undefined"
              :to='{ name: "Assinaturas"}' exact>
                <a class="dropdown-item border-radius-md" href="javascript:;">
                  <div class="py-1 d-flex">
                    <div class="d-flex justify-content-center">
                      <img :src="this.Store.host + notify.author.user.path_image"
                        style="widows: 40px; height: 40px; border-radius: 50%;" />
                        <div class="d-grid ml-2">
                          <span class="font-docs ml-2">{{ truncateText(notify.protocol, 20) }}</span>
                          <span class="font-docs ml-2">{{ truncateText(notify.subject, 20) }}</span>
                          <span v-if="notify.tosignerAttach !== undefined" class="font-docs ml-2" style="color:rgb(153, 116, 4)"> Assinatura anexo(os) </span>
                        </div>
                    </div>
                  </div>
                </a>
               </router-link>
               <router-link v-if="notify.tosigner === undefined && notify.tosignerAttach === undefined"
              :to='{ name: "Recebidos"}' exact>
                <a class="dropdown-item border-radius-md" href="javascript:;">
                  <div class="py-1 d-flex">
                    <div class="d-flex justify-content-center">
                      <img :src="this.Store.host + notify.author.user.path_image"
                        style="widows: 40px; height: 40px; border-radius: 50%;" />
                        <div class="d-grid ml-2">
                          <span class="font-docs ml-2">{{ truncateText(notify.protocol, 20) }}</span>
                          <span class="font-docs ml-2">{{ truncateText(notify.subject, 20) }}</span>
                        </div>
                    </div>
                  </div>
                </a>
               </router-link>
              </li>
              <div v-if="this.Store.notifyMessages.length >= 6" class="d-flex justify-content-center">
                <router-link
              :to='{ name: "Recebidos"}' exact>
                <span class="font-docs ml-2 Color-Docs" style="font-size: 17px">Ver mais +</span>
                </router-link>
              </div>
            </div>

              <li v-else class="mb-1">
                <a class="dropdown-item border-radius-md" href="javascript:;">
                  <div class="py-1 d-flex">
                    <div class="my-auto">
                      <img
                        src="../../assets/img/check.png"
                        class="avatar avatar-sm me-3"
                        alt="user image"
                      />
                    </div>
                    <div class="d-flex flex-column justify-content-center">
                      <h6 class="mb-1 text-sm font-weight-normal">
                        <span class="font-weight-bold">Nada por aqui ainda!</span>
                      </h6>
                    </div>
                  </div>
                </a>
              </li>
            </ul>

          </li>
          <img src="../../assets/img/line.png" class="mr-3"/>
          <router-link :to="{ name: 'profile' }">
          <div
              :class="`p-0 nav-link ${
                isNavFixed && !darkMode ? ' opacity-8 text-dark' : 'text-white'
              } ${showMenuu ? 'show' : ''}`"
            >
            <img :src="this.Store.user.profile_image" style="widows: 40px; height: 40px; border-radius: 2rem"/>
            <span class="ml-3 mr-3 font-docs" style="font-size: 16px"> <b>{{ this.Store.user.name }}</b></span>
            </div>
          </router-link>
            <div :class="`p-0 nav-link ${
                isNavFixed && !darkMode ? ' opacity-8 text-dark' : 'text-white'
              } ${showMenuu ? 'show' : ''}`"
              data-bs-toggle="dropdown"
              aria-expanded="false">

                <img src="../../assets/img/line.png" class="mr-3"/>
                <img src="../../assets/img/out.png" class="responsiveClose" style="cursor:pointer" @click="logout()"/>
                <!-- <a></a> -->
            </div>
                <i class="fa fa-bars text-white MenuClose mt-3" style="cursor:pointer" aria-hidden="true" @click="!this.Store.isPinned ? this.Store.isPinned = true : this.Store.isPinned = false"></i>
        </ul>
      </div>
    </div>
  </nav>
</div>
</template>
