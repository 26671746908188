<script>
import { useMainStore, useEventStore } from '../../../stores/index';
export default {
  name: 'NewChat',
  setup () {
    const StoreAll = useMainStore();
    const event = useEventStore();
    return {
      Store: StoreAll,
      event
    };
  },
  data () {
    return {
      chatnowDataUser: [],
      usersChat: []
    };
  },
  computed: {
    groupedNames () {
      const sortedPeople = [...this.Store.users]
        .filter(person => person.name !== this.Store.user.name)
        .sort((a, b) => a.name.localeCompare(b.name));

      return sortedPeople.reduce((acc, person) => {
        const firstLetter = person.name.charAt(0).toUpperCase();
        if (!acc[firstLetter]) {
          acc[firstLetter] = [];
        }
        acc[firstLetter].push(person);
        return acc;
      }, {});
    }
  },
  mounted () {
  },
  methods: {
    StartChatWith (value) {
      this.$emit('start-chat', value);
    }
  }
};
</script>
<template>
    <div class="chat-scroll">
      <div class="d-flex justify-content-start align-items-center chat-select">
            <div>
                <span class="font-docs ml-2" style="font-size:18px">Selecione com quem deseja falar</span>
            </div>
            <div>
            </div>
            </div>
        <div v-for="(users, letter) in groupedNames" :key="letter" style="padding: 0rem 0.5rem;">
            <h5 class="text-black">{{ letter }}</h5>
            <div v-for="data in users" :key="data.id" class="d-flex align-items-center mb-3" style="cursor:pointer" @click="StartChatWith(data)">
                <img :src="Store.host + data.path_image" class="mr-2" style="widows: 40px; height: 40px; border-radius: 50%;">
                <span class="font-docs" style="font-size:18px">{{ data.name }}</span>
            </div>
        </div>
    </div>
</template>
