<template>
    <div>
        <ModalBigger ref="viewDocument">
            <template #header>
                <strong v-if="edit_avatar == true" style="color: black"> <span>Atualize sua foto </span> </strong>
                <strong v-if="edit_name == true" style="color: black"> <span>Atualize seu nome completo </span> </strong>
                <strong v-if="edit_email == true" style="color: black"> <span>Atualize seu email </span> </strong>
                <strong v-if="edit_apelido == true" style="color: black"> <span>Atualize seu apelido. (Nome na tv) </span>
                </strong>
                <strong v-if="edit_password == true" style="color: black"> <span>Atualize sua senha </span> </strong>

                <div class="flex-start">
                    <button type="button" class="close" @click="$refs.viewDocument.closeModal(); Close_modal_event()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            </template>

            <template #body>

                <div v-if="edit_avatar == true" class="mt-3">
                    <label for="formFile" class="form-label">Selecione a imagem</label>
                    <input id="formFile" class="form-control" type="file" />
                </div>

                <div v-if="edit_name == true" class="mt-3">
                    <label for="formFile" class="form-label">Informe seu nome completo correto.</label>
                    <argon-input id="name" type="text" placeholder="Nome completo" name="name" size="lg" />
                </div>

                <div v-if="edit_apelido == true" class="mt-3">
                    <label for="formFile" class="form-label">Informe seu apelido correto.</label>
                    <argon-input id="Apelido" type="text" placeholder="Apelido" name="Apelido" size="lg" />
                </div>

                <div v-if="edit_email == true" class="mt-3">
                    <label for="formFile" class="form-label">Informe seu email correto.</label>
                    <argon-input id="email" type="email" placeholder="email" name="email" size="lg" />
                </div>

                <div v-if="edit_password == true" class="mt-3">
                    <label for="formFile" class="form-label">Informe sua nova senha.</label>
                    <argon-input id="password_label" type="password" placeholder="Nome completo" name="password_label"
                        size="lg" />

                    <label for="formFile" class="form-label">Repita sua nova senha.</label>
                    <argon-input id="password_label2" type="password" placeholder="Nome completo" name="password_label2"
                        size="lg" />
                </div>

                <div v-if="edit_password == false" class="mt-3 mb-3">
                    <div class="mb-3">
                        <label for="formFile" class="form-label">Informe sua senha (obrigatório)</label>
                        <argon-input id="password" v-model="password" type="password" placeholder="Senha" name="password"
                            size="lg" />

                        <div class="text-center">
                            <h6><strong>IMPORTANTE: AO ALTERAR SEU <span class="text-danger">EMAIL E NOME COMPLETO</span>, CASO EXISTAM DOCUMENTOS PEDENTES DE ASSINATURA, NÃO PODERÃO SER MAIS ASSINADOS.</strong><br /><br /></h6>
                        </div>
                        <div v-if="incorrectAuth">
                            <label><strong class="color6">Senha incorreta!</strong><br /><br /></label>
                        </div>
                    </div>
                </div>

                <div v-if="edit_password == true" class="mt-3 mb-3">
                    <div class="mb-3">
                        <label for="formFile" class="form-label">Informe sua senha antiga. (obrigatório)</label>
                        <argon-input id="password" v-model="password" type="password" placeholder="Senha" name="password"
                            size="lg" />

                        <div class="text-center">
                            <h6><strong>IMPORTANTE: AO ALTERAR SEU <span class="text-danger">EMAIL E NOME COMPLETO</span>, CASO EXISTAM DOCUMENTOS PEDENTES DE ASSINATURA, NÃO PODERÃO SER MAIS ASSINADOS.</strong><br /><br /></h6>
                        </div>
                        <div v-if="incorrectAuth">
                            <label><strong class="color6">Senha incorreta!</strong><br /><br /></label>
                        </div>
                    </div>
                </div>

            </template>

            <template #footer>

                <div v-if="edit_avatar == true" class="d-flex justify-content-center">
                    <button type="button" class="btn btn-primary bg-danger mr-3" @click="$refs.viewDocument.closeModal()">
                        Fechar
                    </button>
                    <button type="button" class="btn btn-success" @click="edit_image();">
                        Enviar imagen!
                    </button>
                </div>

                <div v-if="edit_name == true" class="d-flex justify-content-center">
                    <button type="button" class="btn btn-primary bg-danger mr-3" @click="$refs.viewDocument.closeModal()">
                        Fechar
                    </button>
                    <button type="button" class="btn btn-success" @click="edit_name_user();">
                        Alterar!
                    </button>
                </div>

                <div v-if="edit_email == true" class="d-flex justify-content-center">
                    <button type="button" class="btn btn-primary bg-danger mr-3" @click="$refs.viewDocument.closeModal()">
                        Fechar
                    </button>
                    <button type="button" class="btn btn-success" @click="edit_email_user();">
                        Alterar!
                    </button>
                </div>

                <div v-if="edit_apelido == true" class="d-flex justify-content-center">
                    <button type="button" class="btn btn-primary bg-danger mr-3" @click="$refs.viewDocument.closeModal()">
                        Fechar
                    </button>
                    <button type="button" class="btn btn-success" @click="edit_apelido_user();">
                        Alterar!
                    </button>
                </div>

                <div v-if="edit_password == true" class="d-flex justify-content-center">
                    <button type="button" class="btn btn-primary bg-danger mr-3" @click="$refs.viewDocument.closeModal()">
                        Fechar
                    </button>
                    <button type="button" class="btn btn-success" @click="edit_password_user();">
                        Alterar!
                    </button>
                </div>
            </template>
        </ModalBigger>
    </div>
</template>

<script>
import ModalBigger from './ModalBigger.vue';
import { AxiosAPI } from '@/axios';
import ArgonInput from '@/components/ArgonInput.vue';
import ArgonSwitch from '@/components/ArgonSwitch.vue';
import ArgonButton from '@/components/ArgonButton.vue';

export default {
  components: {
    ModalBigger,
    ArgonInput,
    ArgonSwitch,
    ArgonButton
  },

  data () {
    return {
      incorrectAuth: false,
      edit_avatar: false,
      edit_name: false,
      edit_email: false,
      edit_apelido: false,
      edit_password: false
    };
  },

  methods: {
    openModal_avatar () {
      this.incorrectAuth = false,
      this.edit_avatar = false,
      this.edit_name = false,
      this.edit_email = false,
      this.edit_apelido = false,
      this.edit_password = false;

      this.$refs.viewDocument.openModal();
      this.edit_avatar = true;
    },
    openModal_name () {
      this.incorrectAuth = false,
      this.edit_avatar = false,
      this.edit_name = false,
      this.edit_email = false,
      this.edit_apelido = false,
      this.edit_password = false;

      this.$refs.viewDocument.openModal();
      this.edit_name = true;
    },
    openModal_email () {
      this.incorrectAuth = false,
      this.edit_avatar = false,
      this.edit_name = false,
      this.edit_email = false,
      this.edit_apelido = false,
      this.edit_password = false;

      this.$refs.viewDocument.openModal();
      this.edit_email = true;
    },
    openModal_apelido () {
      this.incorrectAuth = false,
      this.edit_avatar = false,
      this.edit_name = false,
      this.edit_email = false,
      this.edit_apelido = false,
      this.edit_password = false;

      this.$refs.viewDocument.openModal();
      this.edit_apelido = true;
    },
    openModal_password () {
      this.incorrectAuth = false,
      this.edit_avatar = false,
      this.edit_name = false,
      this.edit_email = false,
      this.edit_apelido = false,
      this.edit_password = false;

      this.$refs.viewDocument.openModal();
      this.edit_password = true;
    },

    Close_modal_event () {
      this.incorrectAuth = false,
      this.edit_avatar = false,
      this.edit_name = false,
      this.edit_email = false,
      this.edit_apelido = false,
      this.edit_password = false;
    },

    edit_image () {
      if (document.getElementById('formFile').files[0] === undefined) {
        console.log('Não tem arquivo');
      } else if (document.getElementById('password').value == '') {
        console.log(document.getElementById('password').value);
        console.log('Não tem senha');
      } else {
        const file = document.getElementById('formFile').files[0];
        const password = document.getElementById('password').value;
        const formData = new FormData();
        formData.append('_method', 'PUT');
        formData.append('path_image', file);
        formData.append('old_password', password);

        AxiosAPI.post('api/users/' + this.$pinia.state.value.main.user.id, formData, {
          headers: {
            Authorization: 'Bearer ' + this.$pinia.state.value.main.accessToken
          }
        })
          .then((resp) => {
            if (resp.status === 200) {
              this.showSuccess_edit();
              this.emitter.emit('edit', 1);
              this.$refs.viewDocument.closeModal();
              this.Close_modal_event();
            }
          })
          .catch(() => {
            this.incorrectAuth = true;
          });

        /* AxiosAPI.post("/auth/profile", formData, {
                    headers: {
                        Authorization: `Bearer ` + this.$pinia.state.value.main.accessToken,
                    },
                })
                    .then((resp) => {
                        if (resp.status === 200) {
                            this.showSuccess_edit();
                            this.emitter.emit("edit", 1);
                            this.$refs.viewDocument.closeModal();
                            this.Close_modal_event();
                        }
                    })
                    .catch(() => {
                        this.incorrectAuth = true;
                    }) */
      }
    },

    edit_name_user () {
      if (document.getElementById('name').value == '') {
        console.log('Não tem name');
      } else if (document.getElementById('password').value == '') {
        console.log(document.getElementById('password').value);
        console.log('Não tem senha');
      } else {
        const name = document.getElementById('name').value;
        const password = document.getElementById('password').value;
        const formData = new FormData();
        formData.append('_method', 'PUT');
        formData.append('name', name);
        formData.append('old_password', password);

        AxiosAPI.post('/auth/profile', formData, {
          headers: {
            Authorization: 'Bearer ' + this.$pinia.state.value.main.accessToken
          }
        })
          .then((resp) => {
            if (resp.status === 200) {
              AxiosAPI.patch('/auth/update-clicksing/' + this.$pinia.state.value.main.user.id, {}, {
                headers: {
                  Authorization: 'Bearer ' + this.$pinia.state.value.main.accessToken
                }
              })
                .then((resp) => {
                  if (resp.status === 200) {
                    this.showSuccess_edit();
                    this.emitter.emit('edit', 1);
                    this.$refs.viewDocument.closeModal();
                  }
                });
            }
          })
          .catch(() => {
            this.incorrectAuth = true;
          });
      }
    },

    edit_email_user () {
      if (document.getElementById('email').value == '') {
        console.log('Não tem arquivo');
      } else if (document.getElementById('password').value == '') {
        console.log(document.getElementById('password').value);
        console.log('Não tem senha');
      } else {
        const email = document.getElementById('email').value;
        const password = document.getElementById('password').value;
        const formData = new FormData();
        formData.append('_method', 'PUT');
        formData.append('email', email);
        formData.append('old_password', password);

        AxiosAPI.post('/auth/profile', formData, {
          headers: {
            Authorization: 'Bearer ' + this.$pinia.state.value.main.accessToken
          }
        })
          .then((resp) => {
            if (resp.status === 200) {
              AxiosAPI.patch('/auth/update-clicksing/' + this.$pinia.state.value.main.user.id, {}, {
                headers: {
                  Authorization: 'Bearer ' + this.$pinia.state.value.main.accessToken
                }
              })
                .then((resp) => {
                  if (resp.status === 200) {
                    this.showSuccess_edit();
                    this.emitter.emit('edit', 1);
                    this.$refs.viewDocument.closeModal();
                  }
                });
            }
          })
          .catch(() => {
            this.incorrectAuth = true;
          });
      }
    },

    edit_apelido_user () {
      if (document.getElementById('Apelido').value == '') {
        console.log('Não tem arquivo');
      } else if (document.getElementById('password').value == '') {
        console.log(document.getElementById('password').value);
        console.log('Não tem senha');
      } else {
        const Apelido = document.getElementById('Apelido').value;
        const password = document.getElementById('password').value;
        const formData = new FormData();
        formData.append('_method', 'PUT');
        formData.append('nickname', Apelido);
        formData.append('old_password', password);

        AxiosAPI.post('/auth/profile', formData, {
          headers: {
            Authorization: 'Bearer ' + this.$pinia.state.value.main.accessToken
          }
        })
          .then((resp) => {
            if (resp.status === 200) {
              this.showSuccess_edit();
              this.emitter.emit('edit', 1);
              this.$refs.viewDocument.closeModal();
            }
          })
          .catch(() => {
            this.incorrectAuth = true;
          });
      }
    },

    edit_password_user () {
      if (document.getElementById('password_label').value == '') {
        console.log('Não tem arquivo');
      } else if (document.getElementById('password_label2').value == '') {
        console.log(document.getElementById('password').value);
        console.log('Não tem senha');
      } else if (document.getElementById('password').value == '') {
        console.log(document.getElementById('password').value);
        console.log('Não tem senha');
      } else {
        const password_new = document.getElementById('password_label').value;
        const password_new_confirm = document.getElementById('password_label2').value;
        const password = document.getElementById('password').value;
        const formData = new FormData();
        formData.append('_method', 'PUT');
        formData.append('new_password_confirmation', password_new_confirm);
        formData.append('new_password', password_new);
        formData.append('old_password', password);

        AxiosAPI.post('/auth/profile', formData, {
          headers: {
            Authorization: 'Bearer ' + this.$pinia.state.value.main.accessToken
          }
        })
          .then((resp) => {
            if (resp.status === 200) {
              this.showSuccess_edit();
              this.emitter.emit('edit', 1);
              this.$refs.viewDocument.closeModal();
            }
          })
          .catch(() => {
            this.incorrectAuth = true;
          });
      }
    },
    showSuccess_edit () {
      this.$toast.add({ severity: 'success', summary: 'Edição realizada com sucesso', detail: 'A edição foi um sucesso!', life: 3000 });
    },

    showErro_edit () {
      this.$toast.add({ severity: 'error', summary: 'Edição não realizada', detail: 'Contate o administrador para verificar o error', life: 3000 });
    }

  }
};

</script>

<style>
.vereadores-container {
    display: flex;
    flex-wrap: wrap;
}

.vereadores-item {
    width: calc(33.33% - 10px);
    /* Define a largura de cada item */
    margin-right: 10px;
    /* Define a margem entre os itens */
    margin-bottom: 10px;
    /* Define a margem inferior dos itens */
}
</style>
