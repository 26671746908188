<script>
import { AxiosAPI } from '@/axios';
import ModalBigger from './ModalBigger.vue';
import { useMainStore, useEventStore } from '../../../stores/index';
import Loading from './Loading.vue';
export default {
  components: {
    ModalBigger,
    Loading
  },
  setup () {
    const StoreAll = useMainStore();
    const event = useEventStore();
    StoreAll.Token();
    return {
      Store: StoreAll,
      event
    };
  },
  data () {
    return {
      edit: false,
      idHall: null,
      name: '',
      formattedCnpj: '',
      prefectureName: '',
      prefectureViceNme: '',
      phone: '',
      domain: '',
      adressNumber: '',
      email: '',
      adress: '',
      adressState: '',
      cityName: '',
      cityNumber: '',
      neighborhood: '',
      complement: ''
    };
  },

  methods: {
    openModal () {
      this.edit = false;
      this.$refs.viewDocument.openModal();
      this.name = '';
      this.formattedCnpj = '';
      this.prefectureName = '';
      this.prefectureViceNme = '';
      this.phone = '';
      this.domain = '';
      this.adressNumber = '';
      this.email = '';
      this.adress = '';
      this.adressState = '';
      this.cityName = '';
      this.cityNumber = '';
      this.neighborhood = '';
      this.complement = '';
    },

    openModalEdit (value) {
      this.edit = true;
      this.$refs.viewDocument.openModal();
      this.idHall = value.id;
      this.name = value.title;
      this.formattedCnpj = value.cnpj;
      this.prefectureName = value.mayor_name;
      this.prefectureViceNme = value.vice_mayor_name;
      this.phone = value.phone;
      this.domain = value.domain;
      this.adressNumber = value.address[0].number;
      this.email = value.email;
      this.adress = value.address[0].street;
      this.adressState = value.address[0].uf;
      this.cityName = value.address[0].city;
      this.cityNumber = value.address[0].cep;
      this.neighborhood = value.address[0].neighborhood;
      this.complement = value.address[0].complement;
    },

    async editHall () {
      this.$refs.Loading.openModal();
      const formData = new FormData();
      formData.append('_method', 'PUT');
      formData.append('title', this.name);
      formData.append('cnpj', this.formattedCnpj);
      formData.append('mayor_name', this.prefectureName);
      formData.append('vice_mayor_name', this.prefectureViceNme);
      formData.append('phone', this.phone);
      formData.append('email', this.email);
      formData.append('domain', this.domain);
      formData.append('cep', this.adressNumber);
      formData.append('street', this.adress);
      formData.append('uf', this.adressState);
      formData.append('city', this.cityName);
      formData.append('neighborhood', this.neighborhood);
      formData.append('number', this.cityNumber);
      formData.append('complement', this.complement);

      try {
        await AxiosAPI.post('/api/cityhalls/' + this.idHall, formData, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + this.Store.accessToken
          }
        })
          .then((resp) => {
            if (resp.status === 200) {
              this.name = '';
              this.formattedCnpj = '';
              this.prefectureName = '';
              this.prefectureViceNme = '';
              this.phone = '';
              this.domain = '';
              this.adressNumber = '';
              this.email = '';
              this.adress = '';
              this.adressState = '';
              this.cityName = '';
              this.cityNumber = '';
              this.neighborhood = '';
              this.complement = '';

              this.event.EditHall = true;
              this.$refs.Loading.closeModal();
              this.$refs.viewDocument.closeModal();
            }
          });
      } catch (error) {
        this.$toast.add({ severity: 'error', summary: 'Erro ao editar', detail: 'A prefeitura não foi editada, motivo: ' + error.response.data.message, group: 'tl', life: 8000 });
        this.$refs.Loading.closeModal();
      }
    },

    async CreatedHall () {
      this.$refs.Loading.openModal();
      const formData = new FormData();

      formData.append('title', this.name);
      formData.append('cnpj', this.formattedCnpj);
      formData.append('mayor_name', this.prefectureName);
      formData.append('vice_mayor_name', this.prefectureViceNme);
      formData.append('phone', this.phone);
      formData.append('email', this.email);
      formData.append('domain', this.domain);
      formData.append('cep', this.adressNumber);
      formData.append('street', this.adress);
      formData.append('uf', this.adressState);
      formData.append('city', this.cityName);
      formData.append('neighborhood', this.neighborhood);
      formData.append('number', this.cityNumber);
      formData.append('complement', this.complement);

      try {
        await AxiosAPI.post('/api/cityhalls/', formData, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + this.Store.accessToken
          }
        })
          .then((resp) => {
            if (resp.status === 200) {
              this.name = '';
              this.formattedCnpj = '';
              this.prefectureName = '';
              this.prefectureViceNme = '';
              this.phone = '';
              this.domain = '';
              this.adressNumber = '';
              this.email = '';
              this.adress = '';
              this.adressState = '';
              this.cityName = '';
              this.cityNumber = '';
              this.neighborhood = '';
              this.complement = '';

              this.event.CreatHall = true;
              this.$refs.Loading.closeModal();
              this.$refs.viewDocument.closeModal();
            }
          });
      } catch (error) {
        this.$toast.add({ severity: 'error', summary: 'Erro ao criar', detail: 'A prefeitura não foi criada, motivo: ' + error.response.data.message, group: 'tl', life: 8000 });
        this.$refs.Loading.closeModal();
      }
    },

    formatPhone () {
      const unphone = this.phone.replace(/\D/g, '');

      this.phone = this.phone.substring(0, 15);

      let phone = this.phone;

      if (unphone.length > 10) {
        phone = '(' + unphone.substring(0, 2) + ') ' + unphone.substring(2, 7) + '-' + unphone.substring(7, 11);
      } else if (unphone.length < 10 && unphone.length > 2) {
        phone = '(' + unphone.substring(0, 2) + ') ' + unphone.substring(2, 6);
        if (unphone.length > 6) {
          phone += '-' + unphone.substring(6, 11);
        }
      } else if (unphone.length < 2) {
        phone = '' + unphone.substring(0, 2) + '' + unphone.substring(2, 6);
      }

      this.phone = phone;
    },

    formatCep () {
      const unCep = this.adressNumber.replace(/\D/g, '');

      this.adressNumber = this.adressNumber.substring(0, 8);

      let adressNumber = this.adressNumber;
      if (unCep.length > 5) {
        adressNumber = '' + unCep.substring(0, 5) + '-' + unCep.substring(5, 9);
      }

      this.adressNumber = adressNumber;
    },

    formatCpj () {
      const unformattedCnpj = this.formattedCnpj.replace(/\D/g, '');

      this.formattedCnpj = this.formattedCnpj.substring(0, 14);

      let formattedCnpj = this.formattedCnpj;

      if (unformattedCnpj.length >= 0 && unformattedCnpj.length <= 2) {
        formattedCnpj = this.formattedCnpj;
      } else if (unformattedCnpj.length > 2 && unformattedCnpj.length <= 5) {
        formattedCnpj = unformattedCnpj.substring(0, 2) + '.' + unformattedCnpj.substring(2, 5);
      } else if (unformattedCnpj.length > 5 && unformattedCnpj.length <= 8) {
        formattedCnpj = unformattedCnpj.substring(0, 2) + '.' + unformattedCnpj.substring(2, 5) + '.' + unformattedCnpj.substring(5, 8);
      } else if (unformattedCnpj.length > 8 && unformattedCnpj.length <= 12) {
        formattedCnpj = unformattedCnpj.substring(0, 2) + '.' + unformattedCnpj.substring(2, 5) + '.' + unformattedCnpj.substring(5, 8) + '/' + unformattedCnpj.substring(8, 12);
      } else {
        formattedCnpj = unformattedCnpj.substring(0, 2) + '.' + unformattedCnpj.substring(2, 5) + '.' + unformattedCnpj.substring(5, 8) + '/' + unformattedCnpj.substring(8, 12) + '-' + unformattedCnpj.substring(12);
      }

      this.formattedCnpj = formattedCnpj;
    }
  }
};

</script>

<style>
.close {
    font-size: 2.3rem !important;
}

</style>
<!-- eslint-disable vue/no-useless-template-attributes -->
<template>
  <Loading ref='Loading'></Loading>
    <div>
        <ModalBigger ref="viewDocument">
            <template #header style="display: block !important;">
                <div class="d-flex justify-content-between text-dark text-bold" style="width: 100%;">
                    <div class="pt-2">
                        <div>
                            <span v-if="!edit">Cadastrar prefeitura</span>
                            <span v-if="edit">Edição de prefeitura</span>
                        </div>
                    </div>
                    <div>
                        <button type="button" class="close" @click="$refs.viewDocument.closeModal()">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                </div>
            </template>

            <template #body>
              <div v-if="edit">
                <div class="mt-3">
                    <label for="formFile" class="form-label text-sm">NOME DA PREFEITURA</label>
                    <input class="my_form" v-model="name" placeholder="Nome completo">
                </div>

                <div class="mt-2">
                    <label for="formFile" class="form-label text-sm">INFORME O CNPJ</label>
                    <input class="my_form" v-model="formattedCnpj" placeholder="xx.xxx.xxx/xxxx-xx" @input="formatCpj()" maxlength="18">
                </div>

                <div class="mt-3">
                    <label for="formFile" class="form-label text-sm">NOME DO PREFEITO</label>
                    <input class="my_form" v-model="prefectureName" placeholder="Nome do prefeito">
                </div>

                <div class="mt-3">
                    <label for="formFile" class="form-label text-sm">NOME DO VICE PREFEITO</label>
                    <input class="my_form" v-model="prefectureViceNme" placeholder="Nome do vice prefeito">
                </div>

                <div class="mt-3">
                    <label for="formFile" class="form-label text-sm">INFORME O TELEFONE</label>
                    <input class="my_form" v-model="phone" placeholder="(xx) xxxxx-xxxx" @input="formatPhone()" maxlength="15">
                </div>

                <div class="mt-2">
                    <label for="formFile" class="form-label text-sm">INFORME O EMAIL</label>
                    <input class="my_form" type="email" v-model="email" placeholder="PREFEITURA@hotmail.com">
                </div>

                <div class="mt-3">
                    <label for="formFile" class="form-label text-sm">INFORME O DOMINIO</label>
                    <input class="my_form" v-model="domain" placeholder="prefeitura.docs.net.br">
                </div>

                <div class="mt-3">
                    <label for="formFile" class="form-label text-sm">INFORME O CEP</label>
                    <input class="my_form" v-model="adressNumber" placeholder="xxxxx-xxx" @input="formatCep()" maxlength="9">
                </div>

                <div class="mt-3">
                    <label for="formFile" class="form-label text-sm">INFORME O ENDEREÇO</label>
                    <input class="my_form" v-model="adress" placeholder="Rua...">
                </div>

                <div class="mt-3">
                    <label for="formFile" class="form-label text-sm">INFORME O UF</label>
                    <input class="my_form" v-model="adressState" placeholder="**">
                </div>

                <div class="mt-3">
                    <label for="formFile" class="form-label text-sm">INFORME O CIDADE</label>
                    <input class="my_form" v-model="cityName" placeholder="*****">
                </div>

                <div class="mt-3">
                    <label for="formFile" class="form-label text-sm">INFORME O BAIRRO</label>
                    <input class="my_form" v-model="neighborhood" placeholder="*****">
                </div>

                <div class="mt-3">
                    <label for="formFile" class="form-label text-sm">INFORME O NÚMERO</label>
                    <input class="my_form" v-model="cityNumber" placeholder="*****">
                </div>

                <div class="mt-3">
                    <label for="formFile" class="form-label text-sm">COMPLEMENTO (SE HOUVER)</label>
                    <input class="my_form" v-model="complement" placeholder="*****">
                </div>
              </div>

              <div v-if="!edit">
                <div class="mt-3">
                    <label for="formFile" class="form-label text-sm">NOME DA PREFEITURA</label>
                    <input class="my_form" v-model="name" placeholder="Nome completo">
                </div>

                <div class="mt-2">
                    <label for="formFile" class="form-label text-sm">INFORME O CNPJ</label>
                    <input class="my_form" v-model="formattedCnpj" placeholder="xx.xxx.xxx/xxxx-xx" @input="formatCpj()" maxlength="18">
                </div>

                <div class="mt-3">
                    <label for="formFile" class="form-label text-sm">NOME DO PREFEITO</label>
                    <input class="my_form" v-model="prefectureName" placeholder="Nome do prefeito">
                </div>

                <div class="mt-3">
                    <label for="formFile" class="form-label text-sm">NOME DO VICE PREFEITO</label>
                    <input class="my_form" v-model="prefectureViceNme" placeholder="Nome do vice prefeito">
                </div>

                <div class="mt-3">
                    <label for="formFile" class="form-label text-sm">INFORME O TELEFONE</label>
                    <input class="my_form" v-model="phone" placeholder="(xx) xxxxx-xxxx" @input="formatPhone()" maxlength="15">
                </div>

                <div class="mt-2">
                    <label for="formFile" class="form-label text-sm">INFORME O EMAIL</label>
                    <input class="my_form" type="email" v-model="email" placeholder="PREFEITURA@hotmail.com">
                </div>

                <div class="mt-3">
                    <label for="formFile" class="form-label text-sm">INFORME O DOMINIO</label>
                    <input class="my_form" v-model="domain" placeholder="prefeitura.docs.net.br">
                </div>

                <div class="mt-3">
                    <label for="formFile" class="form-label text-sm">INFORME O CEP</label>
                    <input class="my_form" v-model="adressNumber" placeholder="xxxxx-xxx" @input="formatCep()" maxlength="9">
                </div>

                <div class="mt-3">
                    <label for="formFile" class="form-label text-sm">INFORME O ENDEREÇO</label>
                    <input class="my_form" v-model="adress" placeholder="Rua...">
                </div>

                <div class="mt-3">
                    <label for="formFile" class="form-label text-sm">INFORME O UF</label>
                    <input class="my_form" v-model="adressState" placeholder="**">
                </div>

                <div class="mt-3">
                    <label for="formFile" class="form-label text-sm">INFORME O CIDADE</label>
                    <input class="my_form" v-model="cityName" placeholder="*****">
                </div>

                <div class="mt-3">
                    <label for="formFile" class="form-label text-sm">INFORME O BAIRRO</label>
                    <input class="my_form" v-model="neighborhood" placeholder="*****">
                </div>

                <div class="mt-3">
                    <label for="formFile" class="form-label text-sm">INFORME O NÚMERO</label>
                    <input class="my_form" v-model="cityNumber" placeholder="*****">
                </div>

                <div class="mt-3">
                    <label for="formFile" class="form-label text-sm">COMPLEMENTO (SE HOUVER)</label>
                    <input class="my_form" v-model="complement" placeholder="*****">
                </div>
              </div>
            </template>

            <template #footer>
                <div class="d-flex justify-content-end">
                    <button type="button" class="btn btn-primary text-dark mr-3" style="background-color: #edeef1;" @click="$refs.viewDocument.closeModal()">
                        Voltar
                    </button>
                    <button v-if="edit" type="button" class="btn text-white" style="background-color: #0776d6;"  @click="editHall();">
                        Confirmar edição
                    </button>
                    <button v-if="!edit" type="button" class="btn text-white" style="background-color: #0776d6;"  @click="CreatedHall();">
                        Confirmar cadastro
                    </button>
                </div>
            </template>
        </ModalBigger>

    </div>
</template>
