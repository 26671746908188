import { createApp } from 'vue';
import { createPinia } from 'pinia';
import App from './App.vue';
import ProgressBar from 'primevue/progressbar';
import FileUpload from 'primevue/fileupload';
import store from './store';
import router from './router';
import VueTilt from 'vue-tilt.js';
import VueSweetalert2 from 'vue-sweetalert2';
import ArgonDashboard from './argon-dashboard';
import mitt from 'mitt';
import PrimeVue from 'primevue/config';
import Image from 'primevue/image';
import OtpInput from 'vue3-otp-input';
import Tag from 'primevue/tag';
import DataTable from 'primevue/datatable';
import Paginator from 'primevue/paginator';
import Dropdown from 'primevue/dropdown';
import Button from 'primevue/button';
import Column from 'primevue/column';
import ColumnGroup from 'primevue/columngroup';
import Row from 'primevue/row';
import InputText from 'primevue/inputtext';
import ToastService from 'primevue/toastservice';
import Toast from 'primevue/toast';
import MultiSelect from 'primevue/multiselect';
import TreeTable from 'primevue/treetable';
import TreeSelect from 'primevue/treeselect';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import ProgressSpinner from 'primevue/progressspinner';
import Chart from 'primevue/chart';
import Treeselects from '@zanmato/vue3-treeselect';
import InputSwitch from 'primevue/inputswitch';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import Timeline from 'primevue/timeline';
import '@zanmato/vue3-treeselect/dist/vue3-treeselect.min.css';
import 'primeicons/primeicons.css';
import 'primevue/resources/primevue.min.css';
import 'primevue/resources/themes/mdc-light-indigo/theme.css';
import './assets/css/nucleo-icons.css';
import './assets/css/nucleo-svg.css';
import Tooltip from 'primevue/tooltip';
import Echo from 'laravel-echo';
import Pusher from 'pusher-js';

window.Pusher = Pusher;

window.Echo = new Echo({
  broadcaster: 'reverb',
  key: 'umrettrtncsvyjt6i88n',
  wsHost: 'backend.smart2doc.com.br',
  wssHost: 'backend.smart2doc.com.br',
  authEndpoint: 'https://backend.smart2doc.com.br/broadcasting/auth/',
  wssPort: 6001,
  wsPort: 6001,
  encrypted: true,
  forceTLS: true,
  disableStats: true,
  enabledTransports: ['ws', 'wss']
});

library.add(fas);
const appInstance = createApp(App);
const pinia = createPinia();
appInstance.use(pinia);
appInstance.use(PrimeVue);
appInstance.use(ToastService);
appInstance.directive('tooltip', Tooltip);
appInstance.component('treeselects', Treeselects);
appInstance.component('Timeline', Timeline);
appInstance.component('Dropdown', Dropdown);
appInstance.component('Accordion', Accordion);
appInstance.component('AccordionTab', AccordionTab);
appInstance.component('InputSwitch', InputSwitch);
appInstance.component('spinnerprime', ProgressSpinner);
appInstance.component('ProgressBar', ProgressBar);
appInstance.component('font-awesome-icon', FontAwesomeIcon);
appInstance.component('PrimeChart', Chart);
appInstance.component('Tag', Tag);
appInstance.component('FileUpload', FileUpload);
appInstance.component('CodeInput', OtpInput);
appInstance.component('DataTable', DataTable);
appInstance.component('Paginator', Paginator);
appInstance.component('MultiSelectPrime', MultiSelect);
appInstance.component('Column', Column);
appInstance.component('ColumnGroup', ColumnGroup);
appInstance.component('InputText', InputText);
appInstance.component('Row', Row);
appInstance.component('Buttonprime', Button);
appInstance.component('Toast', Toast);
appInstance.component('TreeTable', TreeTable);
appInstance.component('TreeSelect', TreeSelect);
appInstance.component('ImagePrime', Image);
const emitter = mitt();
appInstance.config.globalProperties.emitter = emitter;
appInstance.use(store);
appInstance.use(router);
appInstance.use(VueTilt);
appInstance.use(VueSweetalert2);
appInstance.use(ArgonDashboard);
appInstance.mount('#app');
