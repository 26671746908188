<script>
import { AxiosAPI } from '@/axios';
import { useMainStore, useEventStore } from '../../stores/index';
import { FilterMatchMode, FilterOperator } from 'primevue/api';
import ModalAddSevidores from '../../components/documents/Modals/Modal_Add_Sevidores.vue';
import ModalViewSevidores from '../../components/documents/Modals/Modal_View_Sevidores.vue';
import Breadcrumb from '../../components/documents/patchName.vue';
export default {
  name: 'DashboardDefault',
  components: {
    ModalAddSevidores,
    ModalViewSevidores,
    Breadcrumb
  },
  setup () {
    const StoreAll = useMainStore();
    const event = useEventStore();
    return {
      Store: StoreAll,
      event
    };
  },
  data () {
    return {
      filters: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
        departamento: { value: null, matchMode: FilterMatchMode.STARTS_WITH }

      },
      servidores: [],
      componenteHeight: '93vh'
    };
  },
  mounted () {
    this.Get_Servidores();

    this.emitter.on('Add_Servidores', () => {
      this.Get_Servidores();
      this.SubscribeInfo();
    });
    this.checkComponentHeight();
    window.addEventListener('resize', this.checkComponentHeight);
  },
  beforeUnmount () {
    window.removeEventListener('resize', this.checkComponentHeight);
  },
  methods: {
    checkComponentHeight () {
      const componente = document.getElementById('Servidores');
      const alturaViewport = window.innerHeight;

      if (componente.clientHeight < 0.93 * alturaViewport) {
        this.componenteHeight = '92.5vh';
      } else {
        this.componenteHeight = componente.clientHeight < 0.93 * alturaViewport + 'vh';
      }
    },

    Get_Servidores () {
      AxiosAPI.get('/api/users/',
        {
          headers: {
            Authorization: 'Bearer ' + this.Store.accessToken
          }
        })
        .then((resp) => {
          this.servidores = resp.data.users;
        });
    },

    Departament (value) {
      const Dep = [];
      for (let i = 0; i < value.length; i++) {
        Dep.push(value[i].title);
      }
      return Dep.join(', ');
    },

    clearFilter1 () {
      this.initFilters();
    },

    initFilters () {
      this.filters = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
        departamento: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] }
      };
    },

    View_user (event) {
      this.$refs.ModalViewSevidores.openModal(event);
    },

    Add_Servidores () {
      this.$refs.ModalAddSevidores.openModalSerividor(true);
    },

    SubscribeInfo () {
      this.$toast.add({ severity: 'success', summary: 'Usuário cadastrado', detail: 'Cadastramento realizado com sucesso', group: 'tl', life: 8000 });
    }

  }
};
</script>
  <style>
  .p-paginator{
    display: flex;
    justify-content: center;
  }
  </style>
<template>
    <div class="py-4 container-fluid" id="Servidores" :style="{ minHeight: componenteHeight }">
      <ModalAddSevidores ref="ModalAddSevidores"></ModalAddSevidores>
      <ModalViewSevidores ref="ModalViewSevidores"></ModalViewSevidores>
      <Breadcrumb />
      <div class="row">
        <div class="col-lg-12">
          <div class="row">

            <div class="d-flex justify-content-center">
            <div class="col-12">
              <div v-if="this.Store.role === 1 || this.Store.role === 2 || this.Store.role === 3">
                    <div class="d-flex justify-content-between">
                      <h4 class="font-docs text-docs-black ml-1"><b>Servidores</b></h4>
                    <button  class="btn mt-3 text-white background-Docs" style="border: 1px solid #F1860B" type="button" @click="Add_Servidores()">
                            <i class="fa fa-plus mr-2" aria-hidden="true"></i>  Cadastrar Servidor
                    </button>
                    </div>
                  </div>
                  <div class="background-pags">
                  <div class="py-2 px-2">
                    <span class="font-docs text-bold text-black">Buscar</span>
                    <div class="d-flex justify-content-between mt-2 mb-3">
                        <span class="p-input-icon-left" style="width: 100% !important;" >
                            <i class="pi pi-search" />
                            <InputText v-model="filters['global'].value" placeholder="Pesquise pelo nome ou departamento" style="width: 100% !important;" />
                        </span>
                    </div>
                  </div>
                  <DataTable :filters="filters" :value="servidores" responsive-layout="scroll" :paginator="true" :rows="10"  filter-display="menu"
                  :global-filterfields="['name','departamento']">
                  <Column field="name" header="Nome" class="text-sm text-wrap font-docs text-bold" headerClass='font-docs-recived-header'>
                        <template #body="slotProps">
                          <img :src="this.Store.host + slotProps.data.path_image"
                                class="mr-2" style="widows: 40px; height: 40px; border-radius: 50%;" />
                            <span> {{slotProps.data.name}} </span>
                        </template>
                      </Column>
                      <Column field="email" header="E-mail" class="text-sm text-wrap font-docs text-bold" headerClass='font-docs-recived-header'>
                      </Column>
                      <Column field="role" header="Cargo" class="text-sm text-wrap font-docs text-bold" headerClass='font-docs-recived-header'>
                      </Column>
                  </DataTable>
                </div>
          </div>
        </div>
    </div>
 </div>
</div>
</div>
</template>
