<script>
import { AxiosAPI } from '@/axios';
import ModalBigger from './ModalBigger.vue';
import { useMainStore } from '../../../stores/index';
export default {
  components: {
    ModalBigger
  },
  setup () {
    const StoreAll = useMainStore();
    StoreAll.Token();
    return {
      Store: StoreAll
    };
  },
  data () {
    return {
      name_departament: '',
      departamentId: 0,
      description_departament: '',
      acronym: '',
      phone: '',
      assets: '',
      branch: '',
      userLiader: [],
      userleaderNow: []
    };
  },
  watch: {
    userLiaderSelect: function (newValue) {
      console.log(this.userLiaderSelect, newValue);
    }
  },
  methods: {
    openModal (value) {
      let userLiader = [];
      const userleaderNow = [];
      let userleaderNowFull = [];
      const users = [];
      userLiader = this.Store.users;
      userLiader.forEach(element => {
        value.users.forEach(userDepartment => {
          if (userDepartment.id === element.id) {
            element.label = element.name;
            element.value = element.id;
            users.push(element);
          }
        });
      });
      this.userLiader = users;

      value.users.forEach(userleader => {
        if (userleader.is_leader === true) {
          userleaderNow.push(userleader.id);
          userleaderNowFull = userleader;
        }
      });
      this.userLiaderSelect = userleaderNowFull;
      this.userleaderNow = userleaderNow;
      this.name_departament = '';
      this.departamentId = '';
      this.description_departament = '';
      this.acronym = '';
      this.phone = '';
      this.assets = '';
      this.branch = '';

      this.name_departament = value.title;
      this.departamentId = value.id;
      this.description_departament = value.description;
      this.acronym = value.acronym;
      this.phone = value.phone;
      this.assets = value.assets;
      this.branch = value.branch;
      this.$refs.viewDocument.openModal();
    },

    updateValue (value) {
      let userSelect = [];
      userSelect = value;
      this.userLiaderSelect = userSelect;
    },

    send_departamento () {
      AxiosAPI.put('/api/departments/' + this.departamentId, {
        title: this.name_departament,
        description: this.description_departament,
        acronym: this.acronym,
        leader_id: this.userLiaderSelect.id,
        phone: this.phone,
        assets: this.assets,
        branch: this.branch
      }, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.$pinia.state.value.main.accessToken
        }
      })
        .then(() => {
          this.emitter.emit('create_departament', 1);
          this.name_departament = '';
          this.departamentId = '';
          this.description_departament = '';
          this.acronym = '';
          this.phone = '';
          this.assets = '';
        });

      this.$refs.viewDocument.closeModal();
    }

  }
};

</script>

<style>.swal2-container {
    z-index: 10001 !important;
}

.close {
    font-size: 2.3rem !important;
}</style>

<template>
    <div>
        <ModalBigger ref="viewDocument">
            <template #header>
                <div class="d-flex justify-content-between text-dark text-bold" style="width: 100%;">
                    <div class="pt-2">
                        <div>
                          <h4 class="font-docs text-black text-bold">Editar setor</h4>
                        </div>
                    </div>
                    <div>
                        <button type="button" class="close" @click="$refs.viewDocument.closeModal()">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                </div>
            </template>

            <template #body>
              <div class="mt-3">
                    <label for="formFile" class="label-docs">Nome<span style="color:red">*</span></label>
                    <input id="name" v-model="name_departament" type="text" placeholder="Nome do setor" name="name" class="my_form" />
                </div>

                <div class="mt-2">
                    <label for="formFile" class="label-docs">Descrição do setor<span style="color:red">*</span></label>
                    <input id="description" v-model="description_departament" type="text" placeholder="Descrição do setor" name="name" class="my_form" />
                </div>

                <div class="mt-2">
                    <label for="formFile" class="label-docs">Secretário responsável <span style="color:red">*</span></label>
                    <treeselects :disable-branch-nodes="true" :flat="true" :multiple="false"
                    :options="userLiader" @select="updateValue" v-model="userleaderNow" placeholder="Busque pelo nome do servidor"/>
                </div>

                <div class='row'>
                  <div class='col-sm-6 mt-1'>
                    <div class="mt-2">
                        <label for="formFile" class="label-docs">Sigla <span style="color:red">*</span></label>
                        <input id="SIGLA" v-model="acronym" type="text" placeholder="Sigla do setor (abc)" name="name" class="my_form" />
                    </div>
                  </div>

                <div class='col-sm-6 mt-1'>
                  <div class="mt-2">
                    <label for="formFile" class="label-docs">Cor do setor <span style="color:red">*</span></label>
                    <div class="d-flex align-items-center my_form">
                      <input v-model="assets" type="color" id="COR" name="COR" value="#ffffff"/>
                      <span class="ml-3">{{ assets }}</span>
                    </div>
                  </div>
                </div>

                <div class='col-sm-6 mt-1'>
                  <div class="mt-2">
                      <label for="formFile" class="label-docs">Ramal</label>
                      <input id="RAMAL" v-model="branch" type="text" class="my_form" placeholder="Ramal do setor (xxxx)" name="name" />
                  </div>
                </div>

                <div class='col-sm-6 mt-1'>
                  <div class="mt-2">
                      <label for="formFile" class="label-docs">Telefone</label>
                      <input id="TELEFONE" v-model="phone" type="text" class="my_form" placeholder="Telefone do setor (xxxxxxxxx)" />
                  </div>
              </div>
              </div>

            </template>

            <template #footer>
              <div class="d-flex justify-content-end">
                    <button type="button" class="btn Color-Docs mr-3" style="border: 1px solid #F1860B" @click="$refs.viewDocument.closeModal()">
                        Voltar
                    </button>
                    <button v-if="assets == '' || acronym == '' || description_departament == '' || name_departament == ''" type="button" class="btn text-white background-Docs" disabled>
                        Editar
                    </button>
                    <button v-else type="button" class="btn text-white background-Docs" @click="send_departamento();">
                      Editar
                    </button>
                </div>
            </template>
        </ModalBigger>

    </div>
</template>
