import { createRouter, createWebHistory } from 'vue-router';
import routerpath from './routerpath';
import Guard from '../Services/service';
import GuardRegister from '../Services/registerExterno';
import Home from '../views/dashboard/Home_Page.vue';
import Servidores from '../views/Gerenciamento/Servidores.vue';
import SetorExpand from '../components/documents/SetoresExpand.vue';
import Login from '../views/auth/signin/Login.vue';
import profile from '../components/Profile/User_Profile.vue';
import Departamentos from '../views/Gerenciamento/Departamentos.vue';
import Setores from '../views/Gerenciamento/SetoresAndSub.vue';
import Contact from '../views/Gerenciamento/Contact.vue';
import MainDoc from '../components/documents/CreateDocument/DocumentMain.vue';
import MainDocResp from '../components/documents/ResponseDocument/DocumentMainResp.vue';
import EditDocument from '../components/documents/EditDocument/EditMain.vue';
import DocumentsRecived from '../components/documents/DocumentsRecived.vue';
import Rascunhos from '../components/documents/DocumentRascunho.vue';
import Suporte from '../components/documents/DocumentHelper.vue';
import DocumentsFavorite from '../components/documents/DocumentsFavorite.vue';
import DocumentsAll from '../components/documents/DocumentAll.vue';
import DocumentsSend from '../components/documents/DocumentsSend.vue';
import DocumentView from '../components/documents/DocumentView.vue';
import DocumentSigner from '../components/documents/DocumentSigners.vue';
import Recovery from '../views/auth/signin/Recovery.vue';
import NotFound from '../views/auth/signin/NotFound.vue';
import NotFoundCompany from '../views/auth/signin/NotFoundCompany.vue';
import TokenInvalid from '../views/auth/signin/TokenInvalid.vue';
import CadastroExterno from '../views/auth/SingUp/RegisterExterno.vue';

const sysyemNow = routerpath;
const routes = [
  {
    path: '/',
    name: 'start',
    redirect: '/' + sysyemNow + '/Home'
  },
  {
    path: '/' + sysyemNow + '/',
    name: 'login',
    redirect: '/' + sysyemNow + '/Home'
  },
  {
    path: '/' + sysyemNow + '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/404',
    name: 'NotFound',
    component: NotFound
  },
  {
    path: '/404',
    name: 'NotFoundCompany',
    component: NotFoundCompany
  },
  {
    path: '/' + sysyemNow + '/401',
    name: 'TokenInvalid',
    component: TokenInvalid
  },
  {
    path: '/' + sysyemNow + '/recovery',
    name: 'Esqueci minha senha',
    component: Recovery
  },
  {
    path: '/' + sysyemNow + '/:catchAll(.*)',
    redirect: '/404'
  },
  {
    path: '/' + sysyemNow + '/profile',
    name: 'profile',
    component: profile,
    beforeEnter: Guard.auth
  },
  {
    path: '/' + sysyemNow + '/Home',
    name: 'Home',
    component: Home,
    beforeEnter: Guard.auth
  },
  {
    path: '/' + sysyemNow + '/Servidores',
    name: 'Servidores',
    component: Servidores,
    beforeEnter: Guard.auth
  },
  {
    path: '/' + sysyemNow + '/Departamentos',
    name: 'Departamentos',
    component: Departamentos,
    beforeEnter: Guard.auth
  },
  {
    path: '/' + sysyemNow + '/Setores',
    name: 'Setores',
    component: Setores,
    beforeEnter: Guard.auth
  },
  {
    path: '/' + sysyemNow + '/Setores/:setorName',
    name: 'Setor',
    component: SetorExpand,
    beforeEnter: Guard.auth
  },
  {
    path: '/' + sysyemNow + '/Contact',
    name: 'Contact',
    component: Contact,
    beforeEnter: Guard.auth
  },
  {
    path: '/' + sysyemNow + '/CriarDocument/:model?',
    name: 'Criar de documento',
    component: MainDoc,
    beforeEnter: Guard.auth
  },
  {
    path: '/' + sysyemNow + '/Rascunhos',
    name: 'Rascunhos',
    component: Rascunhos,
    beforeEnter: Guard.auth
  },
  {
    path: '/' + sysyemNow + '/Suporte',
    name: 'Suporte',
    component: Suporte,
    beforeEnter: Guard.auth
  },
  {
    path: '/' + sysyemNow + '/Recebidos',
    name: 'Recebidos',
    component: DocumentsRecived,
    beforeEnter: Guard.auth
  },
  {
    path: '/' + sysyemNow + '/Recebidos/:id',
    name: 'Recebido',
    component: DocumentView,
    beforeEnter: Guard.auth
  },
  {
    path: '/' + sysyemNow + '/Recebidos/:id/Despachar',
    name: 'DespacharRecebido',
    component: MainDocResp,
    beforeEnter: Guard.auth
  },
  {
    path: '/' + sysyemNow + '/Todos',
    name: 'Todos os documentos',
    component: DocumentsAll,
    beforeEnter: Guard.auth
  },
  {
    path: '/' + sysyemNow + '/Todos/:id',
    name: 'Todos os documentos view',
    component: DocumentView,
    beforeEnter: Guard.auth
  },
  {
    path: '/' + sysyemNow + '/Todos/:id/Despachar',
    name: 'Despachar todos os documentos',
    component: MainDocResp,
    beforeEnter: Guard.auth
  },
  {
    path: '/' + sysyemNow + '/Enviados/:id/Despachar',
    name: 'DespacharEnviados',
    component: MainDocResp,
    beforeEnter: Guard.auth
  },
  {
    path: '/' + sysyemNow + '/Favoritos/:id/Despachar',
    name: 'DespacharFavoritos',
    component: MainDocResp,
    beforeEnter: Guard.auth
  },
  {
    path: '/' + sysyemNow + '/Assinaturas/:id/Despachar',
    name: 'DespacharAssinaturas',
    component: MainDocResp,
    beforeEnter: Guard.auth
  },
  {
    path: '/' + sysyemNow + '/Recebidos/Editar/:id',
    name: 'EditarRecebidos',
    component: EditDocument,
    beforeEnter: Guard.auth
  },
  {
    path: '/' + sysyemNow + '/Enviados/Editar/:id',
    name: 'EditarEnviados',
    component: EditDocument,
    beforeEnter: Guard.auth
  },
  {
    path: '/' + sysyemNow + '/Favoritos/Editar/:id',
    name: 'EditarFavoritos',
    component: EditDocument,
    beforeEnter: Guard.auth
  },
  {
    path: '/' + sysyemNow + '/Assinaturas/Editar/:id',
    name: 'EditarAssinaturas',
    component: EditDocument,
    beforeEnter: Guard.auth
  },
  {
    path: '/' + sysyemNow + '/Assinaturas',
    name: 'Assinaturas',
    component: DocumentSigner,
    beforeEnter: Guard.auth
  },
  {
    path: '/' + sysyemNow + '/Assinaturas/:id',
    name: 'Assinaturasview',
    component: DocumentView,
    beforeEnter: Guard.auth
  },
  {
    path: '/' + sysyemNow + '/Favoritos',
    name: 'Favoritos',
    component: DocumentsFavorite,
    beforeEnter: Guard.auth
  },
  {
    path: '/' + sysyemNow + '/Favoritos/:id',
    name: 'FavoritoView',
    component: DocumentView,
    beforeEnter: Guard.auth
  },
  {
    path: '/' + sysyemNow + '/Enviados',
    name: 'Enviados',
    component: DocumentsSend,
    beforeEnter: Guard.auth
  },
  {
    path: '/' + sysyemNow + '/Enviados/:id',
    name: 'EnviadosView',
    component: DocumentView,
    beforeEnter: Guard.auth
  },
  {
    path: '/' + sysyemNow + '/cadastro_servidor/:token',
    name: 'CadastroExterno',
    component: CadastroExterno,
    beforeEnter: (to, from, next) => {
      localStorage.removeItem('canAccessCadastroExterno');
      GuardRegister.auth(to, (authorized) => {
        if (authorized) {
          localStorage.setItem('canAccessCadastroExterno', 'true');
          next();
        } else {
          next({ name: 'TokenInvalid' });
        }
      });
    }
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  linkActiveClass: 'active'
});

export default router;
